import { Flex, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import useScrollToTop from "hooks/useScrollToTop";
import { ChangeAlert, PageHeader } from "journeys/components/";
import StepsDefault from "pages/BankfastUserMigration/StepIds";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import { useFormContext } from "react-hook-form";
import { ComponentState, CUSTOMER_EMAIL, IScreenProps } from "services/data/types/bankfastUserMigration/";
import { useDidUpdate } from "utils/customHooks";

export const ChangeEmail = ({ flowId, setCurrentState, currentState }: IScreenProps) => {
  useScrollToTop();
  const { title, introA, introB, thisIsMyEmailAddressLink, boxText, boxTextB } =
    strings.bankfastUserMigration.changeEmail;
  const { getValues, setValue } = useFormContext();
  const navigateToStep = useNavigateToStep(flowId);

  useDidUpdate(() => {
    if (currentState === ComponentState.PRESUBMIT) {
      setCurrentState?.(ComponentState.INITIAL);
      setValue(CUSTOMER_EMAIL, "");
      navigateToStep(StepsDefault.EMAIL);
    }
  }, [currentState]);

  return (
    <Flex gap="6" direction="column">
      <PageHeader title={title}>
        <Text fontWeight="medium">
          <Text as="span">{introA}</Text>
          <Text as="span" fontWeight="bold">
            {getValues(CUSTOMER_EMAIL)}
          </Text>
          <Text as="span">{introB}</Text>
        </Text>
      </PageHeader>

      <ChangeAlert boxText={boxText} boxTextB={boxTextB} buttonLabel={thisIsMyEmailAddressLink} />
    </Flex>
  );
};

export default ChangeEmail;
