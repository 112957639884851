import { Alert, Box, Heading, InfoIcon, Loader, Text } from "@shawbrook/sds-component-library";
import { FC, ReactElement } from "react";
import rreplace from "react-string-replace";

import { ArrowLeft } from "assets/images";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import IconLink from "components/IconLink/IconLink";
import { ProfileInfo, ProfileInfoAddress } from "components/ProfileInfo/ProfileInfo";
import FEATURE_FLAGS from "config/featureFlags";
import { links } from "config/navLinks";
import useCustomer from "hooks/useCustomer";
import useScrollToTop from "hooks/useScrollToTop";
import lodash from "lodash";
import { CustomerType, ICustomer, TempBusinessType } from "services/data/types";
import { useMainContext } from "state/mainState";
import { formatToDisplayDate, getPhoneFormat } from "utils";
import convertToTitleCase from "utils/convertToTitleCase";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import styles from "./MyProfile.module.scss";
import { inlineStyles } from "./MyProfile.styles";
import { ProfileSection } from "./ProfileSection";

interface IProfilePageWrapper {
  title: string;
  children: ReactElement | ReactElement[];
}
const ProfilePageWrapper: FC<IProfilePageWrapper> = ({ children, title }) => (
  <div className={styles.myProfile__wrapper}>
    <Box as="header" css={inlineStyles.header}>
      <IconLink to="/" icon={<ArrowLeft />} text={strings.general.back} />
      <Heading color="white">{title}</Heading>
    </Box>
    <section className={styles.page__content}>
      <section>{children}</section>
      <aside className="my-profile__helpCard">
        <AskAQuestion />
      </aside>
    </section>
  </div>
);

const ShowProfileLoading: FC = () => {
  const {
    myProfile: {
      personal: { title }
    },
    general: { loading }
  } = strings;
  return (
    <ProfilePageWrapper title={title}>
      <ProfileSection>
        <Loader showRing size="medium" spacing="medium" text={loading} textColor="primary" />
      </ProfileSection>
    </ProfilePageWrapper>
  );
};

const PersonalProfile: FC<{ data: ICustomer }> = ({ data }) => {
  const {
    myProfile: {
      personal: {
        title,
        aboutYou,
        dob,
        contactDetails,
        mobileNumber,
        secondaryNumber,
        email,
        addressDetails,
        homeAddress
      }
    },
    general: { nameLabel }
  } = strings;

  const secondaryPhoneNumber = getPhoneFormat(data.contactDetails?.secondaryPhoneNumber);

  const changeInfoAlert = rreplace(
    formatTextWithPhoneHours(strings.changeInfoPersonalDescription),
    "{{SEND_MESSAGE}}",
    (_, i) => (
      <a href={links.inbox.link} key={`sendUsMessage${i}`}>
        {strings.sendMessage}
      </a>
    )
  );

  return (
    <ProfilePageWrapper title={title}>
      <ProfileSection title={aboutYou}>
        <ProfileInfo
          title={nameLabel}
          data={`${convertToTitleCase(data.name?.title!)} ${data.name?.givenNames!.join(" ")} ${data.name?.familyName}`}
        />
        <ProfileInfo lastBlock title={dob} data={formatToDisplayDate(data.personalDetails?.dateOfBirth!)} />
      </ProfileSection>
      <ProfileSection title={contactDetails}>
        <ProfileInfo title={mobileNumber} data={getPhoneFormat(data.contactDetails?.mobilePhoneNumber!)} />
        {secondaryPhoneNumber && <ProfileInfo title={secondaryNumber} data={secondaryPhoneNumber} />}
        <ProfileInfo lastBlock title={email} data={data.contactDetails?.email!} />
      </ProfileSection>
      <ProfileSection title={addressDetails} lastSection>
        <ProfileInfoAddress lastBlock title={homeAddress} address={data?.addresses?.homeAddress} />
      </ProfileSection>
      <Alert icon={<InfoIcon />} css={{ padding: "$2 $2-5", "& a": { color: "inherit", textDecoration: "underline" } }}>
        <Text font="silka" css={{ whiteSpace: "break-spaces" }}>
          {changeInfoAlert}
        </Text>
      </Alert>
    </ProfilePageWrapper>
  );
};

const BusinessProfile: FC<{ data: ICustomer }> = ({ data }) => {
  const {
    myProfile: {
      business: {
        title,
        aboutBusiness,
        companyName,
        businessName,
        contactDetails,
        phoneNumber,
        email,
        addressDetails,
        registeredAddress,
        tradingAddress,
        signatories,
        signatory
      }
    }
  } = strings;

  const secondaryPhoneNumber = getPhoneFormat(data.contactDetails?.secondaryPhoneNumber);

  const changeInfoAlert = rreplace(
    formatTextWithPhoneHours(strings.changeInfoBusinessDescription),
    "{{SEND_MESSAGE}}",
    (_, i) => (
      <a href={links.inbox.link} key={`sendUsMessage${i}`}>
        {strings.sendMessage}
      </a>
    )
  );

  return (
    <ProfilePageWrapper title={title}>
      <ProfileSection title={aboutBusiness}>
        {data.corporateDetails?.businessType! === TempBusinessType.LIMITED ? (
          <ProfileInfo title={companyName} data={data.corporateDetails?.companyName!} />
        ) : (
          <ProfileInfo title={businessName} data={data.corporateDetails?.businessName!} />
        )}
      </ProfileSection>
      <ProfileSection title={contactDetails}>
        {secondaryPhoneNumber && <ProfileInfo title={phoneNumber} data={secondaryPhoneNumber} />}
        <ProfileInfo lastBlock title={email} data={data.contactDetails?.email!} />
      </ProfileSection>
      <ProfileSection title={addressDetails}>
        {lodash.isEqual(data?.addresses?.registeredAddress, data?.addresses?.tradingAddress) ? (
          <ProfileInfoAddress lastBlock title={registeredAddress} address={data?.addresses?.registeredAddress} />
        ) : (
          <>
            <ProfileInfoAddress title={registeredAddress} address={data?.addresses?.registeredAddress} />
            <ProfileInfoAddress lastBlock title={tradingAddress} address={data?.addresses?.tradingAddress} />
          </>
        )}
      </ProfileSection>
      <ProfileSection title={signatories} lastSection>
        {data.corporateDetails?.signatories?.map((x, i, arr) => (
          <ProfileInfo lastBlock={i === arr.length - 1} title={signatory} data={x} />
        ))}
      </ProfileSection>
      <Alert icon={<InfoIcon />} css={{ padding: "$2 $2-5", "& a": { color: "inherit", textDecoration: "underline" } }}>
        <Text font="silka" css={{ whiteSpace: "break-spaces" }}>
          {changeInfoAlert}
        </Text>
      </Alert>
    </ProfilePageWrapper>
  );
};

export const MyProfile: FC = () => {
  const context = useMainContext();
  const { customerData: data, isCustomerError: isError, isCustomerLoading: isLoading, customerError } = useCustomer();
  useScrollToTop();

  if (isError) {
    return (
      <ErrorRequestView context={context} requestErrors={[customerError]} errorModal={<ErrorModal open allPages />} />
    );
  }

  if (isLoading) return <ShowProfileLoading />;

  if (data) {
    if (FEATURE_FLAGS.allowBusinessBanking) {
      if (data.type === CustomerType.PERSONAL) return <PersonalProfile data={data} />;
      if (data.type === CustomerType.BUSINESS) return <BusinessProfile data={data} />;
    } else {
      return <PersonalProfile data={data} />;
    }
  }
  return <ShowProfileLoading />;
};
export default MyProfile;
