import { ChatIcon, Flex, Text } from "@shawbrook/sds-component-library";
import { Link } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { GreyCard } from "components/GreyCard";
import { links } from "config/navLinks";
import { IAskAQuestion } from "./interfaces";

export const AskAQuestion = ({ text = strings.general.ask, link = links.inbox.link }: IAskAQuestion) => (
  <GreyCard title={strings.help}>
    <Link to={link}>
      <Flex align="center" gap={1}>
        <ChatIcon width="24" height="24" />
        <Text as="ins" font="silka" color="secondary">
          {text}
        </Text>
      </Flex>
    </Link>
  </GreyCard>
);

export default AskAQuestion;
