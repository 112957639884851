import { Box, Checkbox, Flex, Heading, RadioGroup, RadioItem, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { useFormContext } from "react-hook-form";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";

import useScrollToTop from "hooks/useScrollToTop";
import useSendGDPRAndA11y from "hooks/useSendGDPRAndA11y/index";
import { PageHeader } from "journeys/components";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import {
  ACCESSIBILITY,
  ACCESSIBILITY_DETAILS,
  ACCESSIBILITY_SUPPORT_NEEDED,
  IScreenProps,
  MARKETING_PREFERENCES
} from "services/data/types/bankfastUserMigration/";
import { CheckedState } from "types/types";
import styles from "./GDPRAndA11y.module.scss";

export const GDPRAndA11y = ({ flowId, setPostResult, setCurrentState, currentState }: IScreenProps) => {
  useScrollToTop();
  const {
    title,
    gdprDesc,
    gdprDesc2,
    gdprOptions,
    title2,
    a11yDesc,
    a11yNeedsQuestion,
    a11yNeedsOptions,
    a11yExtraQuestion,
    a11yExtraOptions,
    extraNeeds
  } = strings.bankfastUserMigration.GDPRAndA11y;
  const {
    watch,
    trigger,
    getFieldState,
    setValue,
    formState: { errors }
  } = useFormContext();
  const accessibilityNeeded = watch(ACCESSIBILITY_SUPPORT_NEEDED, undefined);

  const handleSupportNeededChange = (e: any) => {
    setValue(ACCESSIBILITY_SUPPORT_NEEDED, e);
  };

  const gdprCheckboxHandler = (optionValue: string) => (checked: CheckedState) => {
    if (typeof checked === "boolean") {
      setValue(`${MARKETING_PREFERENCES}.${optionValue}`, checked);
    }
  };

  const a11yCheckboxHandler = (key: string) => (checked: CheckedState) => {
    if (typeof checked === "boolean") {
      setValue(`${ACCESSIBILITY_DETAILS}.${key}`, checked);
      trigger();
    }
  };

  useSendGDPRAndA11y({
    setPostResult,
    flowId,
    currentState,
    setCurrentState
  });

  if (getBlockServerErrorStatus(errors)) {
    return (
      <>
        <PageHeader title={title} />
        <ApiAlerts errors={errors} />
      </>
    );
  }

  return (
    <Flex gap="10" direction="column">
      <Box as="fieldset">
        <Space gap="6">
          <Heading>{title}</Heading>
        </Space>
        <Space gap="6">
          <Text fontWeight="medium">{gdprDesc}</Text> <Text fontWeight="medium">{gdprDesc2}</Text>
        </Space>
        <Flex direction="column" gap="8">
          {Object.entries(gdprOptions).map(([key, { label }]) => (
            <Checkbox
              key={key}
              name="marketingOptions"
              label={label}
              aria-label={label}
              onCheckedChange={gdprCheckboxHandler(key)}
            />
          ))}
        </Flex>
      </Box>

      <Box as="fieldset">
        <Space gap="6">
          <Heading level="1" as="h2">
            {title2}
          </Heading>
        </Space>
        <Space gap="6">
          <Text fontWeight="medium">{a11yDesc}</Text>
        </Space>
        <RadioGroup
          labelText={{
            main: a11yNeedsQuestion
          }}
          onValueChange={handleSupportNeededChange}
          name={ACCESSIBILITY_SUPPORT_NEEDED}
          ariaLabel="choose accessiblity option"
          orientation="vertical">
          {getFieldState(ACCESSIBILITY_SUPPORT_NEEDED).error !== undefined && !accessibilityNeeded && (
            <Text color="red">{getFieldState(ACCESSIBILITY_SUPPORT_NEEDED).error?.message}</Text>
          )}
          {a11yNeedsOptions.map(optionValue => (
            <RadioItem
              key={optionValue.toLowerCase()}
              value={optionValue.toLowerCase()}
              label={optionValue}
              id={optionValue}
              aria-label={optionValue}
              invalid={getFieldState(ACCESSIBILITY_SUPPORT_NEEDED).error !== undefined && !accessibilityNeeded}
            />
          ))}
        </RadioGroup>
      </Box>

      {accessibilityNeeded === "yes" && (
        <>
          <Box id="a11y-needs" as="fieldset">
            <Space gap="5">
              <Heading level="5" as="h3">
                {a11yExtraQuestion}
              </Heading>
              {getFieldState(ACCESSIBILITY).error?.message && (
                <Text color="error">{getFieldState(ACCESSIBILITY).error?.message}</Text>
              )}
            </Space>

            <Flex className={styles.a11yOptions} direction="column" gap="8">
              {Object.entries(a11yExtraOptions).map(([optionValue, { label, key }]) => (
                <Checkbox
                  key={key}
                  value={optionValue}
                  label={label}
                  aria-label={label}
                  name={label}
                  id={key}
                  onCheckedChange={a11yCheckboxHandler(key)}
                  invalid={getFieldState(ACCESSIBILITY).error?.message !== undefined}
                />
              ))}
            </Flex>
          </Box>
          <Text fontWeight="medium">{extraNeeds}</Text>
        </>
      )}
    </Flex>
  );
};

export default GDPRAndA11y;
