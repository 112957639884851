import { Flex } from "@shawbrook/sds-component-library";
import { ShowMoreButton } from "components/ShowMoreButton/ShowMoreButton";
import { useState } from "react";

import { links } from "config/navLinks";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { ProductCardItem } from "../ProductCardItem";
import { ProductGroupContainer } from "../ProductGroupContainer";
import { AvailableAccountsCardProps } from "./types";

export const LIMIT = 3;

export const AvailableAccountsCard = ({ title, tags, accounts, defaultShowAll }: AvailableAccountsCardProps) => {
  const [showAll, setShowAll] = useState(defaultShowAll);
  const limit = showAll ? undefined : LIMIT;
  const remainingAfterLimit = (accounts?.length || 0) - LIMIT;

  const visibleAccounts = accounts?.slice(0, limit);
  const { setReinvestmentAccount, accountId } = useMaturityInstructionContext();
  const onSelectDestination = links.selectReinvestmentType.link.replace(":id", accountId);

  return (
    <ProductGroupContainer title={title} tags={tags}>
      <Flex gap={6} direction="column">
        {visibleAccounts?.map(account => {
          const { products } = account;
          const key = products.map(({ id }) => id).join("");
          return (
            <ProductCardItem
              key={key}
              account={account}
              onSelectClick={() => setReinvestmentAccount(account)}
              onSelectDestination={onSelectDestination}
            />
          );
        })}
        {remainingAfterLimit > 0 && !defaultShowAll && (
          <ShowMoreButton
            remaining={remainingAfterLimit}
            onClick={() => setShowAll(prev => !prev)}
            showLess={showAll}
          />
        )}
      </Flex>
    </ProductGroupContainer>
  );
};
