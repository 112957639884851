import { Card, Flex, Loader, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { GreyCard } from "components/GreyCard";
import { AccountTag } from "components/MaturityInstruction/AccountTag";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { Link } from "react-router-dom";
import { INTEREST_FREQUENCY } from "types/types";
import { formatBalance, formatToDisplayDate, getAvailableBalance } from "utils";
import { AsideProps } from "./Aside.interface";

export const Aside = ({
  maturingAccountId,
  showMaturingAccount,
  showWithdrawFullBalance,
  showReinvestmentAccount
}: AsideProps) => {
  const { loading, maturingAccountData, reinvestmentAccount } = useMaturityInstructionContext();

  const {
    balance,
    endOfTerm,
    interestFrequency: interestFrequencyHeading,
    interestRate: interestRateHeading,
    maturingAccount,
    withdrawFullBalance,
    youCanAlso,
    selectedAccount
  } = strings.reinvestment.aside;
  const { title, issueNumber, interestRate, interestFrequency } = maturingAccountData?.product || {};
  const { maturityDate } = maturingAccountData?.maturity || {};

  const interestFrequencyText = interestFrequency ? INTEREST_FREQUENCY[interestFrequency] : "";

  const showAccounts = (showMaturingAccount && maturingAccountId) || showReinvestmentAccount;
  const maturingAccountBalance = getAvailableBalance(maturingAccountData);

  return (
    <Flex gap={4} direction="column">
      {showAccounts && (
        <Card as="aside" variant="white" width="full" radius="none">
          <Flex gap={4} direction="column">
            {showMaturingAccount && (
              <Card variant="outlined" width="auto">
                {loading ? (
                  <Loader />
                ) : (
                  <Flex direction="column" align="start" gap={2}>
                    <Text as="span">{maturingAccount}</Text>
                    <AccountTag accountName={title} accountIssueNumber={issueNumber} />

                    <Text>
                      {balance}:{" "}
                      <Text as="span" fontWeight="bold">
                        {formatBalance(maturingAccountBalance)}
                      </Text>
                    </Text>

                    {showMaturingAccount === "detailed" && (
                      <>
                        <Flex gap={4}>
                          <Flex direction="column">
                            <Text color="grey">{interestRateHeading} </Text>
                            <Text as="span" fontWeight="bold">
                              {interestRate}%
                            </Text>
                          </Flex>
                          <Flex direction="column">
                            <Text color="grey">{interestFrequencyHeading} </Text>
                            <Text as="span" fontWeight="bold">
                              {interestFrequencyText}
                            </Text>
                          </Flex>
                        </Flex>
                        {maturityDate && (
                          <Flex direction="column">
                            <Text color="grey">{endOfTerm} </Text>

                            <Text as="span" fontWeight="bold">
                              {formatToDisplayDate(maturityDate)}
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                  </Flex>
                )}
              </Card>
            )}

            {reinvestmentAccount && showReinvestmentAccount && (
              <Card variant="outlined-pink" width="full">
                {loading ? (
                  <Loader />
                ) : (
                  <Flex direction="column" align="start" gap={2}>
                    <Text as="span" fontWeight="bold">
                      {selectedAccount}
                    </Text>
                    <AccountTag
                      accountName={reinvestmentAccount.title}
                      accountIssueNumber={reinvestmentAccount.issueNumber}
                    />

                    {/* TODO - implement edit button later in the journey */}
                    {/* <Link to="/" className="underline">
                    <Text color="secondary">Edit</Text>
                  </Link> */}
                  </Flex>
                )}
              </Card>
            )}
          </Flex>
        </Card>
      )}

      {showWithdrawFullBalance && (
        <GreyCard title={youCanAlso}>
          {/* todo - update link when withdraw full balance journey is implemented - https://shawbrook.atlassian.net/browse/SDD-11874 */}
          <Link to="/" className="underline">
            <Text color="secondary">{withdrawFullBalance}</Text>
          </Link>
        </GreyCard>
      )}
      <AskAQuestion />
    </Flex>
  );
};
