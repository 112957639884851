import { Flex, Grid, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { formatToDisplayBalance } from "utils";
import { CardConnectorArrow } from "../CardConnectorArrow/CardConnectorArrow";
import styles from "../MaturityInstructionAccountGrid/MaturityInstructionAccountGrid.module.scss";
import { ReviewAccountCard } from "../ReviewAccountCard";

export interface AccountGridPartialProps {
  amountToWithdraw: number;
}

const {
  amountToReinvest: amountToReinvestLabel,
  rateLabel,
  maturing,
  selected,
  amountToWithdraw: amountToWithdrawLabel
} = strings.reviewSubmission;

export const AccountGridPartial = ({ amountToWithdraw }: AccountGridPartialProps) => {
  const { maturingAccountData, reinvestmentProduct, amountToReinvest } = useMaturityInstructionContext();

  const {
    title: maturingProductTitle,
    issueNumber: maturingProductIssueNumber,
    interestRate: maturingProductInterestRate,
    interestFrequency: maturingProductInterestFrequency
  } = maturingAccountData?.product || {};

  const {
    title: newProductTitle,
    issueNumber: newProductIssueNumber,
    interestRate: newProductInterestRate
  } = reinvestmentProduct || {};

  return (
    <>
      <Grid columns={3} gap={2} className={styles.reviewAccountCardGrid}>
        <ReviewAccountCard
          variant="outlined"
          accountIssueNumber={maturingProductIssueNumber}
          accountName={maturingProductTitle}
          title={`${maturingProductInterestRate}% ${rateLabel}`}
          subTitle={`${maturingProductInterestRate}% ${maturingProductInterestFrequency?.toLowerCase()}`}
          label={maturing}
        />
        <Flex align="center" justify="center">
          <CardConnectorArrow />
        </Flex>
        <ReviewAccountCard
          variant="outlined-pink"
          accountIssueNumber={newProductIssueNumber}
          accountName={newProductTitle}
          title={typeof newProductInterestRate === "number" ? `${newProductInterestRate}% ${rateLabel} ` : undefined}
          labelWeight="bold"
          label={selected}
        />
      </Grid>
      <Space />
      <Grid columns={3} gap={2} className={styles.reviewAccountCardGrid}>
        <ReviewAccountCard
          variant="outlined-pink"
          title={formatToDisplayBalance(amountToReinvest)}
          label={amountToReinvestLabel}
          labelWeight="bold"
        />
        <Flex align="center" justify="center">
          <CardConnectorArrow />
        </Flex>
        <ReviewAccountCard
          variant="outlined-pink"
          title={formatToDisplayBalance(amountToWithdraw)}
          label={amountToWithdrawLabel}
          labelWeight="bold"
        />
      </Grid>
    </>
  );
};
