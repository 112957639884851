import { Text } from "@shawbrook/sds-component-library";

import styles from "./GreyCard.module.scss";
import { GreyCardProps } from "./types";

export const GreyCard = ({ title, children }: GreyCardProps) => (
  <div>
    {title && (
      <Text className={styles.greyCard__header} fontWeight="bold" font="inter">
        {title}
      </Text>
    )}
    <div className={styles.greyCard__body}>{children}</div>
  </div>
);

export default GreyCard;
