import { Button, Card, Flex, Heading, List, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import LoadingSection from "components/LoadingSection/LoadingSection";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useScrollToTop from "hooks/useScrollToTop";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./LinkedAccountIssue.module.scss";

export const LinkedAccountIssue = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.id || "";
  const { accountDetailData, accountResolved, isAccountDetailLoading } = useAccount({ accountId });

  const {
    title,
    noVerifiedLinkedAcc: { heading: unverifiedHeading, content: unverifiedContent },
    noLinkedAcc: { heading: missingHeading, content: missingContent, sendUsAMessage, messageExpectedContent }
  } = strings.closeAccount;
  const { sendMessage } = strings.general;

  const closeAccountLink = links.closeAccount.link.replace(":id", accountId);
  const accountLink = links.account.link.replace(":id", accountId);

  const isLinkedAccount = accountDetailData?.linkedAccount && accountDetailData?.linkedAccount.accountNumber;
  const isLinkedAccountVerified = accountDetailData?.linkedAccount?.verified;

  useEffect(() => {
    if (accountResolved && (!accountDetailData || (isLinkedAccount && isLinkedAccountVerified))) {
      navigate(closeAccountLink);
    }
  }, [accountDetailData, accountResolved, closeAccountLink, isLinkedAccount, isLinkedAccountVerified, navigate]);

  const handleSendMessage = () => {
    navigate(links.inbox.link);
  };

  if (accountResolved && !accountDetailData) {
    return null;
  }

  const { heading, content } = isLinkedAccount
    ? { heading: unverifiedHeading, content: unverifiedContent }
    : { heading: missingHeading, content: missingContent };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={accountLink} />
      <section className={styles.page__content}>
        <section>
          <Card width="full" variant="white" className={styles.page__content__card}>
            {isAccountDetailLoading && <LoadingSection />}
            {!isAccountDetailLoading && (
              <>
                <Space>
                  <Heading level="2" as="h2">
                    {heading.replace("{{BANK_NAME}}", accountDetailData?.linkedAccount?.bankName || "")}
                  </Heading>
                </Space>
                <Space gap={isLinkedAccount ? "10" : "5"}>
                  <Text>{content}</Text>
                </Space>
                {!isLinkedAccount && (
                  <Space gap="10">
                    <Text fontWeight="bold">{sendUsAMessage}</Text>
                    <List>
                      {messageExpectedContent.map(el => (
                        <List.Item key={el}>{el}</List.Item>
                      ))}
                    </List>
                  </Space>
                )}
                <Flex justify="end">
                  <Button type="button" size="large" onClick={handleSendMessage}>
                    {sendMessage}
                  </Button>
                </Flex>
              </>
            )}
          </Card>
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
