import { InfiniteData } from "@tanstack/react-query";
import { API_LIMITS } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { IPaymentsResponse, TransactionPaymentMethod, TransactionType, TransferStatus } from "services/data/types";
import { formatToDisplayDate } from "utils";
import { FormattedTransaction } from "utils/formatTransfers/interfaces";

const formatTransfers = (
  targetAccountId: string,
  transfers?: InfiniteData<Array<IPaymentsResponse> | undefined>
): Array<FormattedTransaction> => {
  let formattedTransfers: Array<FormattedTransaction> = [];

  transfers?.pages?.forEach(transfersPage => {
    const formattedTransfersPage =
      transfersPage &&
      transfersPage
        .filter((_, i) => i !== API_LIMITS.PAYMENTS_DEFAULT_LIMIT + 1 - 1)
        .map(transfer => {
          let amountPrefix: string = "";
          let displayName: string = "";
          let transferDescription: string = "";
          let accountInfo: string = "";
          let direction: string = "";
          let tagText: string = "";
          let isIncomingTransfer: boolean = false;
          let isStrikedThrough: boolean = false;

          if (transfer.transactionPaymentMethod === TransactionPaymentMethod.INTERNAL_TRANSFER) {
            if (targetAccountId === transfer.sourceAccount.id) {
              amountPrefix = strings.accountInformation.debit;

              switch (transfer.transactionType) {
                case TransactionType.INTEREST:
                  direction = strings.accountInformation.interestTo;
                  break;
                default:
                  direction = strings.accountInformation.to;
                  break;
              }

              displayName =
                transfer?.destinationAccount?.product?.title ||
                transfer?.destinationAccount?.product?.displayName ||
                "";
              displayName += transfer?.destinationAccount?.product?.issueNumber
                ? ` ${strings.general.issueNumber} ${transfer.destinationAccount.product.issueNumber} `
                : "";
              ({ accountInfo } = strings.accountInformation);
            } else if (transfer.destinationAccount && targetAccountId === transfer.destinationAccount.id) {
              amountPrefix = strings.accountInformation.credit;

              switch (transfer.transactionType) {
                case TransactionType.INTEREST:
                  direction = strings.accountInformation.interestFrom;
                  break;
                default:
                  direction = strings.accountInformation.from;
                  break;
              }

              displayName = transfer.sourceAccount.product?.title || transfer.sourceAccount.product?.displayName;
              displayName += transfer.sourceAccount.product.issueNumber
                ? ` ${strings.general.issueNumber} ${transfer.sourceAccount.product.issueNumber} `
                : "";

              ({ accountInfo } = strings.accountInformation);

              isIncomingTransfer = true;
            }
          } else if (transfer.transactionPaymentMethod === TransactionPaymentMethod.OUTBOUND) {
            amountPrefix = strings.accountInformation.debit;

            switch (transfer.transactionType) {
              case TransactionType.INTEREST:
                direction = strings.accountInformation.interestTo;
                displayName = transfer.sourceAccount.linkedAccount?.bankName || "";
                accountInfo = strings.accountInformation.linkedAccountInfo;
                break;
              case TransactionType.ISA_TRANSFER_OUT:
                direction = strings.accountInformation.isaTransfer;
                displayName = "";
                accountInfo = "";
                break;
              default:
                direction = strings.accountInformation.to;
                displayName = transfer.sourceAccount.linkedAccount?.bankName || "";
                accountInfo = strings.accountInformation.linkedAccountInfo;
                break;
            }
          } else if (transfer.transactionPaymentMethod === TransactionPaymentMethod.INBOUND) {
            amountPrefix = strings.accountInformation.credit;

            switch (transfer.transactionType) {
              case TransactionType.INTEREST:
                direction = strings.accountInformation.interestFrom;
                displayName = transfer.sourceAccount.linkedAccount?.bankName || "";
                accountInfo = strings.accountInformation.linkedAccountInfo;
                break;
              case TransactionType.ISA_TRANSFER_IN:
                direction = strings.accountInformation.isaTransfer;
                displayName = "";
                accountInfo = "";
                break;
              default:
                direction = "";
                displayName = "";
                accountInfo = "";
                break;
            }

            isIncomingTransfer = true;
          } else if (transfer.transactionPaymentMethod === TransactionPaymentMethod.ADJUSTMENT) {
            amountPrefix = strings.accountInformation.credit;

            switch (transfer.transactionType) {
              case TransactionType.INTEREST:
                transferDescription = strings.accountInformation.interestEarned;
                break;
              default:
                break;
            }

            isIncomingTransfer = true;
          } else if (transfer.transactionPaymentMethod === TransactionPaymentMethod.OTHER) {
            if (transfer.transactionType === TransactionType.RECEIPT) {
              amountPrefix = strings.accountInformation.credit;
              direction = "";
              displayName = "";
              accountInfo = "";
              isIncomingTransfer = true;
            } else if (transfer.transactionType === TransactionType.ISA_TRANSFER_IN) {
              amountPrefix = strings.accountInformation.credit;
              isIncomingTransfer = true;
            } else if (transferDescription === TransactionType.ISA_TRANSFER_OUT) {
              amountPrefix = strings.accountInformation.debit;
            }

            if (
              transfer.transactionType === TransactionType.ISA_TRANSFER_IN ||
              transfer.transactionType === TransactionType.ISA_TRANSFER_OUT
            ) {
              transferDescription = strings.accountInformation.isaTransfer;
            }
          }

          if (
            transfer.transactionPaymentMethod === TransactionPaymentMethod.INTERNAL_TRANSFER ||
            transfer.transactionPaymentMethod === TransactionPaymentMethod.OUTBOUND ||
            transfer.transactionPaymentMethod === TransactionPaymentMethod.INBOUND ||
            (transfer.transactionPaymentMethod === TransactionPaymentMethod.OTHER &&
              transfer.transactionType === TransactionType.RECEIPT)
          ) {
            transferDescription = strings.accountInformation.transactionAccount
              .replace("{{direction}}", direction)
              .replace("{{accountName}}", displayName)
              .replace("{{accountInfo}}", accountInfo)
              .trim();
          }

          if (transfer.status.value === TransferStatus.APPROVED || transfer.status.value === TransferStatus.PENDING) {
            tagText = strings.accountInformation.tags.Pending;
          } else if (
            transfer.status.value === TransferStatus.MANUALLY_REJECTED ||
            transfer.status.value === TransferStatus.REJECTED
          ) {
            tagText = strings.accountInformation.tags.Rejected;
            isStrikedThrough = true;
          } else if (transfer.status.value === TransferStatus.MANUAL_REVIEW) {
            tagText = strings.accountInformation.tags.ManualReview;
          }

          const dateInitiatedFormatted = transfer.dateInitiated ? formatToDisplayDate(transfer.dateInitiated) : "";
          const dateInitiatedFormattedShort = dateInitiatedFormatted.substring(3);

          return {
            ...transfer,
            amountPrefix,
            tagText,
            dateInitiatedFormatted,
            dateInitiatedFormattedShort,
            transferDescription,
            isIncomingTransfer,
            isStrikedThrough
          };
        })
        .filter(
          transfer =>
            (transfer.transactionPaymentMethod !== TransactionPaymentMethod.INBOUND &&
              transfer.transactionPaymentMethod !== TransactionPaymentMethod.OTHER &&
              transfer.transferDescription) ||
            transfer.transactionPaymentMethod === TransactionPaymentMethod.INBOUND ||
            transfer.transactionPaymentMethod === TransactionPaymentMethod.OTHER
        );

    if (formattedTransfersPage) {
      formattedTransfers = [...formattedTransfers, ...formattedTransfersPage];
    }
  });

  return formattedTransfers;
};

export default formatTransfers;
