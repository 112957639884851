import {
  Alert,
  Button,
  Card,
  Grid,
  Heading,
  RadioGroup,
  RadioItem,
  RightArrow,
  Space,
  Text,
  UserIcon,
  UsersIcon,
  WarningIcon
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { links } from "config/navLinks";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import rreplace from "react-string-replace";
import { AccountType as AccountTypeEnum } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import { isISAAccountCategory } from "utils";
import useScrollToTop from "hooks/useScrollToTop";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { SelectedAccount } from "../../../components/SelectedAccount/SelectedAccount";
import { productCategorySupportsJointAccounts } from "../utils";
import styles from "./AccountType.module.scss";

export const AccountType = () => {
  const navigate = useNavigate();
  const { title } = strings.newAccount;
  const { headingText, secondaryText, accountTypes, jointAccountAlert, isaAccountSelectHeading } =
    strings.newAccount.accountType;
  const { continueButton } = strings.general;
  const {
    state: { selectedProductSet, accountType: accountTypeFromState, editMode },
    setState,
    clearState
  } = useNewAccountContext();
  useScrollToTop();
  const [accountType, setAccountType] = useState<AccountTypeEnum | undefined>(accountTypeFromState);
  const [joinOptionEnabled, setJointOptionEnabled] = useState(true);
  const [isDifferentHeading, setIsDifferentHeading] = useState(false);

  useEffect(() => {
    if (selectedProductSet) {
      if (!productCategorySupportsJointAccounts(selectedProductSet.category)) {
        setAccountType("Single");
        setJointOptionEnabled(false);
        if (isISAAccountCategory(selectedProductSet.category!)) {
          setIsDifferentHeading(true);
        }
      }
    } else {
      navigate(links.compareAccounts.link);
    }
  }, [selectedProductSet, navigate]);

  const handleAccountTypeChange = (e: AccountTypeEnum) => {
    setAccountType(e);
  };

  const handleContinue = () => {
    setState({
      accountType
    });
    const isISAProduct = isISAAccountCategory(selectedProductSet?.category!);
    const nextPageLink = isISAProduct ? links.isaTransfer.link : links.annualSavings.link;
    navigate(editMode ? links.newAccountSummary.link : nextPageLink);
  };

  const formatSubHeading = rreplace(isaAccountSelectHeading.secondaryText, "{{DIFFERENT_ACCOUNT}}", () => (
    <Button
      key="differentAccount"
      className={styles.page__linkButton}
      to={links.compareAccounts.link}
      variant="tertiary"
      role="link"
      as={Link}
      isSlim>
      {isaAccountSelectHeading.differentAccount}
    </Button>
  ));

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.newAccountDetails.link} isEdit={editMode} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" width="auto">
            <Grid>
              <Heading level={2}>{isDifferentHeading ? isaAccountSelectHeading.headingText : headingText}</Heading>
              <Space>
                <Text>{isDifferentHeading ? formatSubHeading : secondaryText}</Text>
              </Space>
              <Space gap="5">
                <Grid
                  css={{ display: "grid", width: "100" }}
                  columns={{ "@initial": 1, "@lg": 2, "@md": 2, "@sm": 1 }}
                  align="stretch"
                  as={RadioGroup}
                  labelText={{ main: "" }}
                  onValueChange={handleAccountTypeChange}
                  className={styles.page__optionsContainer}
                  value={accountType}>
                  <div className={styles.page__accountOption}>
                    <div className={styles.page__accountOption__usersIcon}>
                      <UserIcon width="65.83" height="65.83" />
                    </div>
                    <RadioItem
                      key={accountTypes.soleAccount.id}
                      id={accountTypes.soleAccount.id}
                      label={accountTypes.soleAccount.label}
                      value={accountTypes.soleAccount.id}
                    />
                  </div>
                  <div
                    className={
                      !joinOptionEnabled
                        ? `${styles.page__accountOption} ${styles.page__accountOption__disabledContainer}`
                        : styles.page__accountOption
                    }>
                    <div
                      className={
                        joinOptionEnabled ? styles.page__accountOption__usersIcon : styles.page__accountOption__disabled
                      }>
                      <UsersIcon width="65.83" height="65.83" />
                    </div>
                    <RadioItem
                      key={accountTypes.joinAccount.id}
                      id={accountTypes.joinAccount.id}
                      label={accountTypes.joinAccount.label}
                      value={accountTypes.joinAccount.id}
                      disabled={!joinOptionEnabled}
                      className={!joinOptionEnabled ? styles.page__accountOption__disabledText : ""}
                    />
                  </div>
                </Grid>
              </Space>
              {accountType === accountTypes.joinAccount.id && (
                <Alert
                  variant="warning"
                  icon={<WarningIcon />}
                  action={
                    <Button
                      className={styles.page__accountOption__jointAlertButton}
                      iconRight
                      onClick={() => navigate(links.inbox.link)}
                      role="link"
                      variant="plain">
                      <span className={styles.page__accountOption__jointAlertButton__text}>
                        {jointAccountAlert.buttonText}
                      </span>
                      <RightArrow />
                    </Button>
                  }>
                  <Text font="inter" fontWeight="bold" css={{ mb: 0 }}>
                    {jointAccountAlert.heading}
                  </Text>
                  <Text font="inter" fontWeight="medium">
                    {formatTextWithPhoneHours(jointAccountAlert.body)}
                  </Text>
                </Alert>
              )}
              <div className={styles.page__continueButtonWrapper}>
                <Button
                  iconRight
                  disabled={accountType === undefined || accountType === "Joint"}
                  size="large"
                  onClick={handleContinue}>
                  {continueButton}
                  <RightArrow width="24" height="24" />
                </Button>
              </div>
            </Grid>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
