import { Loader, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import MessageBubble from "components/MessageBubble/MessageBubble";
import { FC } from "react";
import { IMessage, MessageDirection } from "services/data/types";
import { formatToDisplayDate, formatToDisplayTime } from "utils";
import { v4 as uuid } from "uuid";
import styles from "./MessageList.module.scss";

interface IMessageList {
  messages: IMessage[];
  gettingMessages?: boolean;
}

export const MessageList: FC<IMessageList> = ({ messages, gettingMessages = true }) => {
  // THREAD MAGIC - if the first message of a conversation is empty, ignore it
  // as it is an artifact of having /me/threads create a message.
  // This does not happen when a colleague initiates a thread.
  const firstMessageIsEmpty = messages && messages[0] && !messages[0].content;

  // this flag is if we need to show the waiting message,
  // which specifically is shown when a thread is created by the
  // customer, but the autoresponder has not yet responded
  const isLoading = firstMessageIsEmpty && gettingMessages && messages.length < 2;

  // method for determining if the day has changed
  // between messages
  const dayChanged = (index: number): boolean => {
    // hide the date if this is the last entry
    if (index === messages.length - 1) return false;

    const currentDay = messages[index].sentDate?.slice(0, 10);
    const nextDay = messages[index + 1]?.sentDate?.slice(0, 10);

    return nextDay !== currentDay;
  };

  // determine if within the list of messages if
  // the sender has changed or not. used for time indicators
  // after a group of messages
  const senderChanged = (index: number): boolean => {
    // if it's the first message or the last message, assume true
    if (index === 0 || messages.length - 1 === index) {
      return true;
    }
    return messages[index + 1]?.direction !== messages[index]?.direction;
  };

  // what the component shows when it has not yet loaded
  const skeleton = (
    <section>
      <Loader size="small" text={strings.general.loading} />
    </section>
  );

  return isLoading ? (
    skeleton
  ) : (
    <ul className={styles["message-list__container"]}>
      {messages?.map(
        (message, index) =>
          message?.content &&
          message.content !== "" && (
            <li key={uuid()} style={{ marginBottom: !senderChanged(index) ? "0" : "1.25rem" }}>
              {message.direction === MessageDirection.OUTBOUND && (
                <MessageBubble
                  left
                  bgColor="lightgrey"
                  sender={
                    message.sender && message.sender === "Automated"
                      ? strings.inbox.shawbrookLabel
                      : strings.inbox.shawbrookAgentLabel
                  }
                  message={message.content}
                  direction={message.direction}
                />
              )}
              {message.direction === MessageDirection.INBOUND && (
                <MessageBubble
                  right
                  sender={strings.inbox.youLabel}
                  message={message.content}
                  direction={message.direction}
                />
              )}
              {senderChanged(index) && (
                <Text
                  className={
                    `${styles["message-list__time"]} ` +
                    `${message?.direction === MessageDirection.INBOUND ? styles["message-list__time--right"] : ""}`
                  }
                  css={{
                    fontSize: "0.75rem",
                    letterSpacing: "0.075rem",
                    fontWeight: "500",
                    lineHeight: "150%"
                  }}>
                  {message?.sentDate && formatToDisplayTime(message.sentDate)}
                </Text>
              )}

              {dayChanged(index) && (
                <Text
                  className={styles["message-list__date"]}
                  css={{
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: " 0.875rem",
                    letterSpacing: "0.0875rem",
                    lineHeight: "150%",
                    paddingTop: "1.25rem",
                    paddingBottom: "1.5rem"
                  }}>
                  {messages[index + 1] &&
                    messages[index + 1].sentDate &&
                    formatToDisplayDate(messages[index + 1].sentDate)}
                </Text>
              )}
            </li>
          )
      )}
    </ul>
  );
};

export default MessageList;
