import { IAvailableProduct, InterestFrequency, IProduct } from "services/data/types/entities";
import { FormattedAvailableProduct } from "./interfaces";

export const formatProductsGroup = (
  productsGroup: IAvailableProduct,
  filter = { maxBalance: true }
): FormattedAvailableProduct | null => {
  const sortedProducts: IProduct[] = [...productsGroup.products]
    .filter(product => product.interestRate != null && product.interestFrequency != null)
    .sort((a, b) => b.interestRate - a.interestRate)
    .map(product => ({
      ...product,
      interestFrequency: product.interestFrequency?.toLowerCase() as InterestFrequency
    }));

  let formattedProductsGroup: FormattedAvailableProduct | null = null;

  if (sortedProducts.length) {
    const {
      issueNumber,
      category,
      maxBalance,
      jointMaxBalance,
      minBalance,
      interestRates,
      title,
      noticePeriodDays,
      lowestInterestRate,
      lowestRateUpperBound,
      term
    } = productsGroup;

    if (filter.maxBalance && maxBalance == null) {
      return null;
    }
    const { kpiLink, interestRate: maxInterestRate, interestFrequency: maxInterestFrequency } = sortedProducts[0];

    formattedProductsGroup = {
      maxInterestRate,
      maxInterestFrequency,
      soleMaxBalance: maxBalance || 0,
      jointMaxBalance: jointMaxBalance || 0,
      minBalance: minBalance || 0,
      category,
      kpiLink,
      issueNumber,
      title,
      products: sortedProducts,
      interestRates: interestRates || [],
      noticePeriodDays,
      lowestInterestRate,
      lowestRateUpperBound,
      term
    };
  }

  return formattedProductsGroup;
};
