import {
  Button,
  Card,
  Grid,
  Heading,
  InputFieldSizes,
  RightArrow,
  Space,
  Text,
  TextInput,
  Tooltip
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { bankAccountInputsConfig } from "config/bankAccountInputsConfig";
import { links } from "config/navLinks";
import useNewAccountLinkedAccounts from "hooks/useNewAccountLinkedAccounts/useNewAccountLinkedAccounts";
import useScrollToTop from "hooks/useScrollToTop";
import { useEffect, useState } from "react";
import { NumberFormatBase, NumberFormatValues } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { ILinkedAccount } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import styles from "./LinkedAccountForm.module.scss";
import { formatAccountNumberInput, formatSortCodeInput, isAllowedSortCodeInputValidator } from "./ValidationFunctions";

type ICustomTextInputProps = {
  id: string;
  isError: boolean;
  error: string;
  fieldSize: InputFieldSizes;
  label: string;
};

const AccountDataInput = ({ id, isError, error, fieldSize, label, ...props }: ICustomTextInputProps) => (
  <TextInput
    {...props}
    id={id}
    labelText={{
      main: label
    }}
    fieldSize={fieldSize}
    status={isError ? "error" : "default"}
    infoMessage={isError ? error : ""}
  />
);

export const LinkedAccountForm = () => {
  const navigate = useNavigate();
  const {
    title,
    linkedAccountForm: {
      nameLabelTooltip,
      selectExistingAccount,
      accountholderValidationError,
      accountNumberValidationError,
      sortCodeValidationError
    }
  } = strings.newAccount;
  const { continueButton } = strings.general;
  const { state, setState, clearState } = useNewAccountContext();
  useScrollToTop();
  const [linkedAccount, setLinkedAccount] = useState<ILinkedAccount | undefined>(state.linkedAccount);
  const [errors, setErrors] = useState({ holderNameError: "", sortCodeError: "", accountNumberError: "" });
  const { hasVerifiedLinkedAccounts, linkedAccountsDataFetched } = useNewAccountLinkedAccounts();

  const nameLabelTooltipText = nameLabelTooltip.replaceAll(
    "{{holderNameMaxLength}}",
    bankAccountInputsConfig.holderNameMaxLength.toString()
  );
  useEffect(() => {
    if (state.selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [state.selectedProductSet, navigate]);

  const onLinkedAccountChange = (key: string, value: string) => {
    setLinkedAccount(linkedAccState => ({ ...linkedAccState, [key]: value } as ILinkedAccount));
  };

  const handleContinue = () => {
    const isAccountNumberValidated =
      linkedAccount?.accountNumber?.length === bankAccountInputsConfig.accountNumberMaxLength;
    const isSortCodeValidated = linkedAccount?.sortCode?.length === bankAccountInputsConfig.sortCodeMaxLength;
    const isHolderNameValidated = linkedAccount?.holderName?.length! > 0;
    if (isAccountNumberValidated && isSortCodeValidated && isHolderNameValidated) {
      setState({
        linkedAccount
      });
      navigate(state.editMode ? links.newAccountSummary.link : links.newAccountInterest.link);
    } else {
      setErrors({
        ...errors,
        accountNumberError:
          linkedAccount?.accountNumber?.length! < bankAccountInputsConfig.accountNumberMaxLength
            ? accountNumberValidationError
            : "",
        sortCodeError:
          linkedAccount?.sortCode?.length! < bankAccountInputsConfig.sortCodeMaxLength ? sortCodeValidationError : "",
        holderNameError:
          linkedAccount?.holderName?.length! <= 0 ||
          linkedAccount?.holderName === "" ||
          typeof linkedAccount?.holderName === "undefined"
            ? accountholderValidationError
            : ""
      });
    }
  };

  const handleSelectExistingAccount = () => {
    navigate(links.newAccountLinkedAccount.link);
  };

  const hasExistingVerifiedLinkedAccounts = linkedAccountsDataFetched && hasVerifiedLinkedAccounts;

  const {
    headingText,
    secondaryText,
    tertiaryText = ""
  } = (
    hasExistingVerifiedLinkedAccounts
      ? strings.newAccount.linkedAccountForm.hasExistingVerifiedLinkedAccount
      : strings.newAccount.linkedAccountForm.noExistingVerifiedLinkedAccount
  ) as any;

  const isLinkedAccountFormFilled = linkedAccount?.accountNumber?.length! > 0 && linkedAccount?.sortCode?.length! > 0;

  return (
    <div className={styles.page}>
      <SecondaryHeader
        title={title}
        backLink={links.annualSavings.link}
        prevPath={links.newAccountLinkedAccountForm.link}
      />
      <Grid className={styles.page__content}>
        <section>
          <Card variant="white">
            <Heading level={2}>{headingText}</Heading>
            <Space gap="6">
              <Text>{secondaryText}</Text>
            </Space>
            {!hasExistingVerifiedLinkedAccounts && (
              <Space gap="6">
                <Text>{tertiaryText}</Text>
              </Space>
            )}
            <Space gap="3">
              <TextInput
                id="nameOnAccount"
                type="text"
                labelText={{
                  main: strings.newAccount.linkedAccountForm.nameLabel
                }}
                maxLength={bankAccountInputsConfig.holderNameMaxLength}
                infoMessage={errors.holderNameError ? errors.holderNameError : ""}
                status={errors.holderNameError ? "error" : "default"}
                className={styles.page__nameOnAccountField}
                fieldSize="6"
                onChange={e => onLinkedAccountChange("holderName", e.target.value)}
                value={linkedAccount?.holderName || ""}
                toolTip={
                  <Tooltip
                    variant="dark"
                    label="Tell me more"
                    css={{ fontFamily: "$display" }}
                    text={nameLabelTooltipText}
                  />
                }
              />
            </Space>
            <Space gap="3">
              <NumberFormatBase
                id="sortCode"
                defaultValue={linkedAccount?.sortCode}
                isError={!!errors.sortCodeError}
                error={errors.sortCodeError}
                fieldSize="3"
                label={strings.newAccount.linkedAccountForm.sortCodeLabel}
                customInput={AccountDataInput}
                valueIsNumericString
                className={styles.page__sortCodeField}
                format={formatSortCodeInput}
                isAllowed={isAllowedSortCodeInputValidator}
                onValueChange={(e: NumberFormatValues) => onLinkedAccountChange("sortCode", e.value)}
              />
            </Space>
            <Space gap="10">
              <NumberFormatBase
                id="accountNumber"
                defaultValue={linkedAccount?.accountNumber}
                isError={!!errors.accountNumberError}
                error={errors.accountNumberError}
                fieldSize="6"
                label={strings.newAccount.linkedAccountForm.accountNumberLabel}
                customInput={AccountDataInput}
                valueIsNumericString
                className={styles.page__accountNumberField}
                format={formatAccountNumberInput}
                onValueChange={(e: NumberFormatValues) => onLinkedAccountChange("accountNumber", e.formattedValue)}
              />
            </Space>
            <div className={styles.page__buttonSection}>
              {(hasExistingVerifiedLinkedAccounts && (
                <Button
                  className={styles.page__selectExistingLinkedAccountBtn}
                  onClick={handleSelectExistingAccount}
                  variant="secondary"
                  size="large">
                  {selectExistingAccount}
                </Button>
              )) || <div className={styles.page__emptyDiv}>&nbsp;</div>}
              <Button
                className={styles.page__continuebtn}
                iconRight
                disabled={!isLinkedAccountFormFilled}
                size="large"
                onClick={handleContinue}>
                {continueButton}
                <RightArrow width="24" height="24" />
              </Button>
            </div>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={state.selectedProductSet?.title!}
            issueNumber={state.selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
