import { Card, Flex, Heading, Space, Text } from "@shawbrook/sds-component-library";
import { ProductGroupContainerProps } from "./ProductGroupContainer.interface";

export const ProductGroupContainer = ({ children, title, tags }: ProductGroupContainerProps) => {
  const tagsString = tags?.join(" • ");
  return (
    <Card variant="raised" width="full">
      <Flex direction="column" gap={2}>
        {title && <Heading level={3}>{title}</Heading>}
        {tagsString && <Text font="silka">{tagsString}</Text>}
      </Flex>
      {(title || tagsString) && <Space gap={6} />}
      {children}
    </Card>
  );
};
