import { Alert, Button, Icon } from "@shawbrook/sds-component-library";
import { createContext, MouseEvent, useCallback, useContext, useMemo, useState } from "react";
import styles from "./togglableAlert.module.scss";
import { AlertContextProps, ContentProps, TogglableAlertProps, TriggerProps } from "./types";

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

const TogglableAlert = ({ children }: TogglableAlertProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAlert = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const value = useMemo(() => ({ isOpen, toggleAlert }), [isOpen, toggleAlert]);
  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

const Trigger = ({ onClick, children, ...props }: TriggerProps) => {
  const { toggleAlert } = useContext(AlertContext);
  return (
    <Button
      {...props}
      onClick={(e: MouseEvent<HTMLElement, MouseEvent>) => {
        toggleAlert();
        onClick?.(e);
      }}>
      {children}
    </Button>
  );
};

const Content = ({ children, showCloseIcon = true }: ContentProps) => {
  const { toggleAlert, isOpen } = useContext(AlertContext);
  if (isOpen) {
    return (
      <Alert
        action={
          showCloseIcon ? (
            <Trigger className={styles.togglableAlert__closeButton} variant="plain" onClick={toggleAlert}>
              <Icon name="cross" />
            </Trigger>
          ) : null
        }
        variant="generic">
        {children}
      </Alert>
    );
  }
  return null;
};

TogglableAlert.Trigger = Trigger;
TogglableAlert.Content = Content;
export { TogglableAlert };
