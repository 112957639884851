import { useQuery } from "@tanstack/react-query";
import { getMaturityInstruction } from "services/data/api";
import { QueryKeys } from "services/data/types";

export const useGetMaturityInstruction = ({ sourceAccountId = "", enabled = true, cacheTime = 0 }) => {
  // need to disable cache because in case of review submission screen we need
  // to make sure the maturityData that we store in the state is up to date
  const {
    data: maturityInstructionData,
    isLoading: isMaturityInstructionLoading,
    isError: isMaturityInstructionError
  } = useQuery({
    queryKey: [QueryKeys.MATURITY_INSTRUCTION, sourceAccountId],
    queryFn: () => getMaturityInstruction(sourceAccountId),
    enabled,
    cacheTime
  });

  return {
    maturityInstructionData,
    isMaturityInstructionError,
    isMaturityInstructionLoading
  };
};
