import {
  Alert,
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  RadioGroup,
  RadioItem,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ButtonGroup } from "components/ButtonGroup";
import { Aside } from "components/MaturityInstruction/Aside";
import { PageLayout } from "components/Page";
import { links } from "config/navLinks";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { MaturityInstructionType } from "services/data/types";
import { formatBalance } from "utils";
import { stringReplace } from "utils/replace";

export const SelectReinvestmentType = () => {
  const {
    accountId,
    handleReinvestmentTypeChange,
    reinvestmentType,
    maturingAccountData,
    loading,
    maxBalanceExceeded,
    reinvestmentAccount
  } = useMaturityInstructionContext();

  const linkedAccountName = maturingAccountData?.linkedAccount?.bankName;

  const { title: pageTitle } = strings.reinvestment;
  const { question, options, maxBalanceErrorBody } = strings.selectReinvestmentType;
  const { continueButton } = strings.general;
  const continueLink = links.chooseInterest.link.replace(":id", accountId);
  const isContinueButtonDisabled =
    !reinvestmentType || (maxBalanceExceeded && reinvestmentType === MaturityInstructionType.REINVEST_NEW);

  const errorMessageContent = useMemo(() => {
    if (!maxBalanceExceeded) return "";
    const { accountType } = maturingAccountData || {};
    if (!accountType) return "";
    if (!reinvestmentAccount) return "";

    const maxBalance =
      accountType === "Joint" ? reinvestmentAccount.jointMaxBalance : reinvestmentAccount.soleMaxBalance;

    return stringReplace(maxBalanceErrorBody, {
      "{{MAX_BALANCE}}": formatBalance(maxBalance || 0, true)
    });
  }, [maturingAccountData, maxBalanceErrorBody, maxBalanceExceeded, reinvestmentAccount]);

  return (
    <PageLayout title={pageTitle} loading={loading}>
      <PageLayout.Content>
        <Card width="full" variant="white" radius="none">
          <Flex direction="column" gap={8}>
            <Heading level={2}>{question}</Heading>
            <RadioGroup
              labelText={{}}
              ariaLabel="reinvestment-type"
              orientation="vertical"
              onValueChange={handleReinvestmentTypeChange}
              value={reinvestmentType}>
              {options.map(({ label, value }) => {
                if (value === "PARTIAL") {
                  const formattedLabel = stringReplace(label, {
                    "{{LINKED_ACCOUNT}}": linkedAccountName
                  });
                  return <RadioItem value={value} label={formattedLabel} id={value} aria-label={value} key={value} />;
                }
                return (
                  <Flex direction="column" gap={4} key={value}>
                    <RadioItem value={value} label={label} id={value} aria-label={value} />
                    {!!errorMessageContent && reinvestmentType === MaturityInstructionType.REINVEST_NEW && (
                      <Alert ariaLive="polite" variant="error" icon={<Icon color="error" name="error" />}>
                        <Text>{errorMessageContent} </Text>
                      </Alert>
                    )}
                  </Flex>
                );
              })}
            </RadioGroup>
            <ButtonGroup justify="end">
              <Button as={Link} iconRight size="large" disabled={isContinueButtonDisabled} to={continueLink}>
                {continueButton}
                <Icon name="RightArrow" color="current" />
              </Button>
            </ButtonGroup>
          </Flex>
        </Card>
      </PageLayout.Content>
      <PageLayout.Aside>
        <Aside showMaturingAccount="overview" showReinvestmentAccount maturingAccountId={accountId} />
      </PageLayout.Aside>
    </PageLayout>
  );
};
