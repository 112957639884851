import strings from "assets/strings/strings.json";
import { INTEREST_FREQUENCY } from "state/reinvestmentState";
import { stringReplace } from "../replace";
import { FormatInterestRateLabelProps } from "./formatInterestRateLabel.interface";

const { optionLabel } = strings.chooseInterest;

/**
 * @returns a constructed string of interest information e.g. 'Annually – 2.00% gross, fixed (AER 2.00%)'
 */
export const formatInterestRateLabel = ({ rate, annualRate, frequency, isFixedRate }: FormatInterestRateLabelProps) => {
  const { text, frequency: frequencyTextOptions, rateType, aer: aerString } = optionLabel;

  const frequencyText =
    frequency === INTEREST_FREQUENCY.annually ? frequencyTextOptions.annually : frequencyTextOptions.monthly;

  const aerText = typeof annualRate === "number" ? ` (${aerString})` : "";

  const baseText = text + aerText;

  return stringReplace(baseText, {
    "{{FREQUENCY}}": frequencyText,
    "{{RATE}}": rate.toFixed(2),
    "{{RATE_TYPE}}": isFixedRate ? rateType.fixed : rateType.variable,
    "{{ANNUAL_RATE}}": annualRate?.toFixed(2)
  });
};
