import { Box, Button, Heading, LeftArrow, Loader, Space, Text } from "@shawbrook/sds-component-library";
import { links } from "config/navLinks";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import strings from "assets/strings/strings.json";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import IconLink from "components/IconLink/IconLink";
import LoadingSection from "components/LoadingSection/LoadingSection";
import config from "config";
import useAccount from "hooks/useAccount";
import useScrollToTop from "hooks/useScrollToTop";
import useSubmitThread from "hooks/useSubmitThread";
import rreplace from "react-string-replace";
import { CreateThreadRequest } from "services/data/types";
import { useInboxContext } from "state/inboxState";
import styles from "./InboxAccountsPage.module.scss";

export const InboxAccountsPage: FC = () => {
  useScrollToTop();
  const context = useInboxContext();
  const navigate = useNavigate();
  const { accountsData, accountErrored, accountResolved, accountsError } = useAccount();

  const [selectedAccount, setSelectedAccount] = useState({} as CreateThreadRequest);
  const {
    postThread,
    isSubmitThreadError,
    submitThreadError,
    isSubmitThreadSuccess,
    submitThreadData,
    isSubmitThreadLoading
  } = useSubmitThread(selectedAccount);

  const set = new Set();
  const linkedAccounts =
    accountsData &&
    accountsData.filter &&
    accountsData?.filter(o => {
      if (!o.linkedAccount || set.has(o.linkedAccount?.accountNumber)) return false;
      set.add(`${o.linkedAccount?.accountNumber}`);
      return true;
    });
  useEffect(() => {
    if (selectedAccount.subject) postThread();
  }, [postThread, selectedAccount]);

  useEffect(() => {
    // redirect if the user has submitted a new thread
    if (!isSubmitThreadError && isSubmitThreadSuccess && submitThreadData) {
      context.setState({
        isGetMessages: true
      });
      navigate(`${links.inbox.link}/thread/${submitThreadData.createdThreadId}`);
    }
  }, [context, isSubmitThreadError, isSubmitThreadSuccess, navigate, submitThreadData]);

  if (accountErrored || isSubmitThreadError) {
    return (
      <ErrorRequestView
        requestErrors={[accountsError, submitThreadError]}
        context={context}
        errorModal={<ErrorModal open />}
      />
    );
  }

  const clickHandler =
    (accountId: string, displayName: string = "") =>
    () => {
      const content: CreateThreadRequest = {
        accountId,
        subject: strings.inbox.accountBtnLabel.replace("{{nameOfAccount}}", displayName),
        sender: "create-thread"
      };
      if (accountId === "none") {
        setSelectedAccount({ subject: displayName || strings.inbox.otherAccountBtnLabel, sender: "create-thread" });
      } else {
        setSelectedAccount(content);
      }
    };

  // this page uses a loading section instead of a skeleton / ghost loader
  // components within this page have their own skeleton loaders
  return !accountResolved ? (
    <LoadingSection />
  ) : (
    <section className={styles.inbox}>
      <Box css={{ "@md": { display: "none" }, "> a": { p: 0, m: 0 } }}>
        <IconLink to={links.inbox.link} icon={<LeftArrow />} />
      </Box>
      <Space gap={12} />
      <Space gap={2}>
        <Heading level={4} css={{ "@sm": { m: 0 } }}>
          {strings.inbox.accountsHeading}
        </Heading>
      </Space>
      <Space gap="6">
        <Box>
          <Text>
            {rreplace(strings.inbox.topMessage, "{{HELP_CENTRE_LINK}}", (_, i) => (
              <a
                key={`helpCentreLink${i}`}
                className={styles.inbox__helpCentreLink}
                href={config.savingsHelpCentreLink.link}
                target="_blank"
                rel="noreferrer">
                {config.savingsHelpCentreLink.text}
              </a>
            ))}
          </Text>
        </Box>
      </Space>

      {accountsData &&
        accountsData.map &&
        accountsData.map((el, index) => (
          <Button
            disabled={isSubmitThreadLoading}
            variant="secondary"
            size="small"
            key={el.id}
            css={{ "--animation-order": index + 1 }}
            onClick={clickHandler(
              el.accountId,
              el.product.title ? `${el.product.title} Issue ${el.product.issueNumber}` : el.product.displayName
            )}>
            {isSubmitThreadLoading && selectedAccount.accountId === el.accountId ? (
              <Loader size="small" />
            ) : (
              strings.inbox.accountBtnLabel.replace(
                "{{nameOfAccount}}",
                el.product.title ? `${el.product.title} Issue ${el.product.issueNumber}` : el.product.displayName
              )
            )}
          </Button>
        ))}
      {linkedAccounts?.map((account, i) => (
        <Button
          key={uuidv4()}
          disabled={isSubmitThreadLoading}
          onClick={clickHandler(
            "none",
            strings.inbox.linkedAccountBtnLabel.replace("{{nameOfAccount}}", account?.linkedAccount?.bankName || "")
          )}
          variant="secondary"
          size="small"
          css={{ "--animation-order": (accountsData?.length || 0) + 1 + i }}>
          {isSubmitThreadLoading && selectedAccount.subject?.includes("My linked") ? (
            <Loader size="small" />
          ) : (
            strings.inbox.linkedAccountBtnLabel.replace("{{nameOfAccount}}", account?.linkedAccount?.bankName || "")
          )}
        </Button>
      ))}

      <Button
        disabled={isSubmitThreadLoading}
        onClick={clickHandler("none", strings.inbox.otherAccountThreadTitle)}
        variant="secondary"
        size="small"
        css={{
          "--animation-order": !accountsData
            ? 1
            : accountsData.length + (!linkedAccounts ? 0 : linkedAccounts.length) + 1
        }}>
        {isSubmitThreadLoading && selectedAccount.subject?.includes(strings.inbox.otherAccountBtnLabel) ? (
          <Loader size="small" />
        ) : (
          strings.inbox.otherAccountBtnLabel
        )}
      </Button>
    </section>
  );
};

export default InboxAccountsPage;
