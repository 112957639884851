import { useEffect } from "react";
import { Flex, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import useScrollToTop from "hooks/useScrollToTop";
import { useSendOTPProcess } from "hooks/useSendOTPProcess/useSendOTPProcess";
import { MobileNumberVerificationChoice, PageHeader, PatternTextInput } from "journeys/components/";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import { useFormContext } from "react-hook-form";
import {
  CUSTOMER_CONTACT_DETAILS,
  CUSTOMER_MOBILE,
  ComponentState,
  IScreenProps
} from "services/data/types/bankfastUserMigration";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";
import { formatMobileNumberInput } from "services/validationSchemas";
import styles from "./Mobile.module.scss";

export const Mobile = ({
  flowId,
  setPostResult,
  currentStepIndex,
  setCurrentState,
  currentState = ComponentState.INITIAL,
  onSubmit,
  continueBtnLoading,
  setIsBtnHidden
}: IScreenProps) => {
  useScrollToTop();
  const {
    mobile: {
      description,
      description2,
      title,
      mobile: { label }
    }
  } = strings.bankfastUserMigrationMobile;
  const {
    formState: { errors }
  } = useFormContext();

  useSendOTPProcess({
    otpType: "mobile",
    flowId,
    setPostResult,
    currentStepIndex,
    setCurrentState,
    currentState
  });

  useEffect(() => {
    if (getBlockServerErrorStatus(errors)) {
      setIsBtnHidden?.(false);
    }
  }, [errors?.root?.serverError?.message, errors, setIsBtnHidden]);

  if (getBlockServerErrorStatus(errors)) {
    return (
      <>
        <PageHeader title={title} />
        <ApiAlerts errors={errors} />
      </>
    );
  }

  return (
    <Flex gap="6" direction="column">
      <PageHeader title={title}>
        {description && <Text fontWeight="medium">{description}</Text>}
        {description2 && <Text>{description2}</Text>}
      </PageHeader>

      <PatternTextInput
        id="mobileNumber"
        fieldName={CUSTOMER_MOBILE}
        label={label}
        autoFocus
        className={styles["mobileNumber--field"]}
        autoComplete="tel"
        type="tel"
        format={formatMobileNumberInput}
        valueIsNumericString
        errorFieldNames={[CUSTOMER_MOBILE, CUSTOMER_CONTACT_DETAILS, "OtpCredentials.MobileNumber"]}
      />

      <MobileNumberVerificationChoice loading={continueBtnLoading} onSubmit={onSubmit} />
    </Flex>
  );
};

export default Mobile;
