import { Card, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";

export type ITermsExplained = {
  className?: string;
};

export const TermsExplained = (props: ITermsExplained) => {
  const {
    title,
    aer: { title: aerTitle, content: aerContent },
    gross: { title: grossTitle, content: grossContent }
  } = strings.termsExplained;
  return (
    <Card variant="filled" radius="none" {...props}>
      <Space gap={5}>
        <Heading as="h4" level={3}>
          {title}
        </Heading>
      </Space>
      <Space gap={5}>
        <Space gap="2">
          <Text fontWeight="semiBold">{aerTitle}</Text>
        </Space>
        <Text color="grey">{aerContent}</Text>
      </Space>
      <Space gap="5">
        <Space gap="2">
          <Text fontWeight="semiBold">{grossTitle}</Text>
        </Space>
        <Text color="grey">{grossContent}</Text>
      </Space>
    </Card>
  );
};
