import strings from "assets/strings/strings.json";
import FEATURE_FLAGS from "config/featureFlags";
import { useMaturityInstruction } from "hooks/useMaturityInstruction";
import DefaultLayout from "layouts/DefaultLayout";
import ComingSoon from "pages/ComingSoon";
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

export const MaturityInstructionLayout = () => {
  const { id } = useParams();
  const value = useMaturityInstruction(id || "");
  const { pathname } = useLocation();
  const { title } = strings.reinvestment;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (FEATURE_FLAGS.allowReinvestments) {
    return (
      <DefaultLayout className="layoutWrapper--landing layoutWrapper--landing--shortest">
        <Outlet context={value} />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout className="layoutWrapper--landing">
      <ComingSoon headingText={title} />
    </DefaultLayout>
  );
};
