import { Flex, PhoneIncomingIcon } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { useVerificationOTPProcess } from "hooks/useVerificationOTPProcess/useVerificationOTPProcess";
import { Description, OtpVerification, PageHeader } from "journeys/components/";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CUSTOMER_MOBILE,
  ComponentState,
  IScreenProps,
  MOBILE_VERIFICATION_SCREEN_MODE
} from "services/data/types/bankfastUserMigration/";
import useScrollToTop from "hooks/useScrollToTop";
import { MobileOtpMode } from "state/bankfastUserMigrationState";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";
import Styles from "./MobileOtp.module.scss";

export const MobileOTP = ({
  flowId,
  setPostResult,
  setCurrentState,
  currentState = ComponentState.INITIAL,
  customerData
}: IScreenProps) => {
  useScrollToTop();
  const {
    getValues,
    formState: { errors }
  } = useFormContext();
  const navigate = useNavigate();

  const {
    "mobile-otp": { descriptionCall, descriptionText, title, passcode }
  } = strings.bankfastUserMigrationMobile;

  const description =
    getValues(MOBILE_VERIFICATION_SCREEN_MODE) === MobileOtpMode.VOICE ? descriptionCall : descriptionText;

  useEffect(() => {
    if (getValues(CUSTOMER_MOBILE) === "") {
      navigate(strings.bankfastUserMigrationMobile.changeMobile.continueButtonLink);
    }
  }, [getValues, navigate]);

  useVerificationOTPProcess({
    otpType: "mobile",
    setPostResult,
    flowId,
    currentState,
    setCurrentState,
    customerData
  });

  if (getBlockServerErrorStatus(errors)) {
    return (
      <>
        <PageHeader title={title} />
        <ApiAlerts errors={errors} />
      </>
    );
  }

  return (
    <>
      <PageHeader
        title={title}
        icon={
          getValues(MOBILE_VERIFICATION_SCREEN_MODE) === MobileOtpMode.VOICE && (
            <Flex as="span" className={Styles.otpCallIcon}>
              <PhoneIncomingIcon width="24" height="24" />
            </Flex>
          )
        }>
        <Description description={description} identifier="[MOBILE]" value={getValues(CUSTOMER_MOBILE)} />
      </PageHeader>

      <OtpVerification strings={passcode} otpType="mobile" otpId="mobileVerificationOTP" />
    </>
  );
};

export default MobileOTP;
