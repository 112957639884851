import { Alert, Button, Card, Flex, Heading, InfoIcon, Loader, Text } from "@shawbrook/sds-component-library";
import { useState } from "react";

import { ArrowLeft } from "assets/images";
import { API_LIMITS } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import IconLink from "components/IconLink/IconLink";
import RequestInProgressModal from "components/RequestInProgressModal";
import SuccessModal from "components/SuccessModal";
import { links } from "config/navLinks";
import useMoveMoneyReviewSubmit from "hooks/useMoveMoneyReviewSubmit";
import useScrollToTop from "hooks/useScrollToTop";
import useSubmitTransfer from "hooks/useSubmitTransfer";
import useTransfer from "hooks/useTransfer";
import { ErrorModal } from "pages/MoveMoney/components/Modals";
import { TransferCard } from "pages/MoveMoney/components/TransferCard";
import { TransferStatus } from "services/data/types";
import { useMoveMoneyContext } from "state/moveMoneyState";
import { formatBalance, formatSortCode, getAvailableBalance } from "utils";
import getTransferSuccessMessage from "utils/getTransferSuccessMessage";
import styles from "./MoveMoney.module.scss";

export const MoveMoneyReviewSubmit = () => {
  const { state, setState } = useMoveMoneyContext();
  const { reviewAndSubmit, amount, reference } = strings.moveMoney;
  const [isGetTransfer, setIsGetTransfer] = useState<boolean | null>(null);
  const { back } = strings.general;
  const startGetTransfer = Boolean(isGetTransfer && state.paymentId);

  useScrollToTop();
  const { postTransfer, isSubmitTransferError, isSubmitTransferSuccess, isSubmitTransferLoading, submitTransferData } =
    useSubmitTransfer({
      sourceAccountIdArg: state.fromAccount?.id,
      destinationAccountIdArg: state.toAccount?.id,
      amountValueArg: state.amount.value,
      useLinkedAccount: !state.toAccount?.internal,
      referenceArg: state.reference
    });
  const { transferStatus, transferData } = useTransfer({
    sourceAccountIdArg: state.fromAccount?.id,
    paymentIdArg: state.paymentId,
    isRefetch: startGetTransfer && API_LIMITS.REFETCH_TIMEOUT,
    enabled: startGetTransfer,
    resetState: setIsGetTransfer
  });
  const {
    successCallback,
    errorCallback,
    cancelHandler,
    calculateBalance,
    whenDescription,
    successAccountTitle,
    accountFromName,
    accountToName
  } = useMoveMoneyReviewSubmit({
    isSubmitTransferError,
    isSubmitTransferSuccess,
    submitTransferData,
    moveMoneyContext: { state, setState },
    isGetTransfer,
    setIsGetTransfer,
    transferData,
    transferStatus
  });

  return (
    <div className={styles["move-money__wrapper"]}>
      <RequestInProgressModal open={Boolean(isGetTransfer)} />

      <SuccessModal
        customCallback={successCallback}
        open={state.displaySuccessModal}
        url={links.landing.link}
        content={getTransferSuccessMessage(
          state.amount.value ?? 0,
          successAccountTitle,
          Boolean(state.toAccount?.internal),
          submitTransferData?.dateInitiated
        )}
        header={strings.moveMoney.modals.success.success}
        btnContent={strings.moveMoney.modals.success.done}
        headerMarginBottom={0}
      />

      <ErrorModal
        customCallback={errorCallback}
        open={state.displayErrorModal}
        transferStatus={transferData?.status?.value}
        tryAgainCallback={transferData?.status?.value === TransferStatus.FAILED && errorCallback}
      />

      <header>
        <IconLink to={links.moveMoney.link} icon={<ArrowLeft />} text={back} />
        <Heading color="white">{reviewAndSubmit.title}</Heading>
      </header>

      <div className={styles.page__content}>
        <section>
          <Card css={{ width: "100%", padding: "0.75rem 1.25rem" }}>
            <dl>
              {state.fromAccount && (
                <TransferCard
                  title={reviewAndSubmit.from}
                  subTitle={accountFromName}
                  value={`${state.fromAccount?.accountNumber} ${formatSortCode(state.fromAccount?.sortCode || "")}`}
                />
              )}

              {state.toAccount && (
                <TransferCard
                  title={reviewAndSubmit.to}
                  subTitle={accountToName}
                  value={`${state.toAccount?.accountNumber} ${formatSortCode(state.toAccount?.sortCode || "")}`}
                />
              )}

              {state.amount && (
                <TransferCard
                  title={amount}
                  subTitleWeight="semiBold"
                  subTitle={formatBalance(state.amount?.value ?? 0)}
                />
              )}

              {state.reference && (
                <TransferCard title={reference} subTitleWeight="semiBold" subTitle={state.reference} />
              )}

              {state.fromAccount && state.amount && (
                <TransferCard
                  title={reviewAndSubmit.balance}
                  subTitle={calculateBalance(getAvailableBalance(state?.fromAccount), state.amount.value ?? 0)}
                  subTitleWeight="semiBold"
                />
              )}
            </dl>

            {reviewAndSubmit.paymentDate[whenDescription] && (
              <>
                <Text as="span" font="silka" color="grey">
                  {reviewAndSubmit.when || "When"}
                </Text>
                <Alert variant="info" icon={<InfoIcon />} css={{ p: "$1 $2" }}>
                  <Text fontWeight="medium" font="silka">
                    {reviewAndSubmit.paymentDate[whenDescription]}
                  </Text>
                </Alert>
              </>
            )}
          </Card>

          <Flex justify="between" css={{ marginTop: "2rem" }}>
            <Button variant="secondary" as="a" onClick={cancelHandler}>
              {reviewAndSubmit.cancel}
            </Button>
            <Button
              onClick={() => postTransfer()}
              className={styles.mmPrimaryButton}
              data-testid="submit"
              variant="primary"
              disabled={isSubmitTransferLoading}
              type="submit">
              {isSubmitTransferLoading ? <Loader size="small" /> : reviewAndSubmit.submit}
            </Button>
          </Flex>
        </section>

        <aside>
          <AskAQuestion />
        </aside>
      </div>
    </div>
  );
};

export default MoveMoneyReviewSubmit;
