import {
  Box,
  Button,
  Card,
  Grid,
  Heading,
  RadioGroup,
  RadioItem,
  RightArrow,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import useScrollToTop from "hooks/useScrollToTop";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import { SecondaryHeader } from "../../../components/SecondaryHeader/SecondaryHeader";
import styles from "./NoticePeriod.module.scss";

export const NoticePeriod = () => {
  const navigate = useNavigate();
  const { headingText, subHeadingText, periodFixedTerm, startTransfer } = strings.newAccount.notice;
  const { title } = strings.newAccount;
  const {
    state: { selectedProductSet, noticePeriod, fullTransfer, toppedUpAccountInCY, editMode, editSelection },
    setState,
    clearState
  } = useNewAccountContext();
  useScrollToTop();
  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState<string | undefined>(noticePeriod?.hasNoticePeriod);
  const [selectedStartTransfer, setSelectedStartTransfer] = useState<string | undefined>(noticePeriod?.startTransfer);
  const [showStartTransfer, setShowStartTransfer] = useState<boolean>(false);
  const [isContinueDisabled, setIsContinuedDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    if (editMode) {
      setState({
        transferISASavingsToCurrentAccount: editSelection?.transferISASavingsToCurrentAccount,
        isaDetails: editSelection?.isaDetails,
        fullTransfer: editSelection?.fullTransfer,
        toppedUpAccountInCY: editSelection?.toppedUpAccountInCY,
        transferAmount: editSelection?.transferAmount,
        noticePeriod: { hasNoticePeriod: selectedNoticePeriod, startTransfer: selectedStartTransfer }
      });
    } else {
      setState({ noticePeriod: { hasNoticePeriod: selectedNoticePeriod, startTransfer: selectedStartTransfer } });
    }
    navigate(editMode ? links.newAccountSummary.link : links.nationalInsurance.link);
  };

  const handleNoticePeriodSelect = (value: string) => {
    setSelectedNoticePeriod(value);
  };

  const handleStartTransferSelect = (value: string) => {
    setSelectedStartTransfer(value);
  };

  const handleBackEditNavigation = () => {
    if (editSelection?.fullTransfer === "Yes" && editSelection.toppedUpAccountInCY === "Yes") {
      return links.transferAmount.link;
    }

    if (editSelection?.fullTransfer === "Yes" && editSelection.toppedUpAccountInCY === "No") {
      return links.taxYearWaypoint.link;
    }

    return links.transferAmount.link;
  };

  const handleBackLinkNavigation = () => {
    if (fullTransfer === "Yes" && toppedUpAccountInCY === "Yes") {
      return links.transferAmount.link;
    }

    if (fullTransfer === "Yes" && toppedUpAccountInCY === "No") {
      return links.taxYearWaypoint.link;
    }

    return links.transferAmount.link;
  };

  useEffect(() => {
    if (selectedNoticePeriod === "Yes") {
      setIsContinuedDisabled(true);
      setShowStartTransfer(true);
      if (selectedStartTransfer) {
        setIsContinuedDisabled(false);
      }
    } else {
      setIsContinuedDisabled(true);
      setSelectedStartTransfer(undefined);
      setShowStartTransfer(false);
      if (selectedNoticePeriod) {
        setIsContinuedDisabled(false);
      }
    }
  }, [selectedNoticePeriod, selectedStartTransfer]);

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={editMode ? handleBackEditNavigation() : handleBackLinkNavigation()} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer} width="auto">
            <Heading level={2}>{headingText}</Heading>
            <Text fontWeight="medium">{subHeadingText}</Text>

            <Box>
              <RadioGroup
                orientation="vertical"
                labelText={{ main: periodFixedTerm.questionText }}
                value={selectedNoticePeriod}
                onValueChange={handleNoticePeriodSelect}>
                {periodFixedTerm.options.map(option => (
                  <RadioItem id={option.label} key={option.label} value={option.value} label={option.label} />
                ))}
              </RadioGroup>
            </Box>

            {showStartTransfer && (
              <Box>
                <RadioGroup
                  orientation="vertical"
                  labelText={{ main: startTransfer.questionText }}
                  value={selectedStartTransfer}
                  onValueChange={handleStartTransferSelect}>
                  {startTransfer.options.map(option => (
                    <RadioItem
                      id={option.label}
                      key={option.label}
                      value={option.value}
                      label={option.label}
                      style={{ minWidth: "32px" }}
                    />
                  ))}
                </RadioGroup>
              </Box>
            )}

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isContinueDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
