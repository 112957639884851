import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ArrowLeft } from "assets/images";
import { STATUS_CODE } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { AxiosError, AxiosResponse } from "axios";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import Card from "components/Card/Card";
import { CardSection } from "components/Card/interfaces";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import IconLink from "components/IconLink/IconLink";
import LinkedAccount from "components/LinkedAccount/LinkedAccount";
import { LoadingSection } from "components/LoadingSection/LoadingSection";
import TransactionHistory from "components/TransactionHistory";
import useAccount from "hooks/useAccount";
import useHasLinkedAccountsMissed from "hooks/useHasLinkedAccountsMissed";
import useScrollToTop from "hooks/useScrollToTop";
import useTransfers from "hooks/useTransfers";
import { IAccountDetails } from "services/data/types";
import { useMainContext } from "state/mainState";
import { QUERY_STATUS } from "types/types";
import { formatToDisplayDate, getAvailableBalance, getCapitalBalance } from "utils";
import styles from "./Account.module.scss";

export const Account: FC = () => {
  const context = useMainContext();
  const location = useLocation();
  let { single } = location.state || { single: undefined };
  const params = useParams();
  useScrollToTop();
  const accountId = params.id;
  const { accountDetailData, accountsData, isSingleAccount, accountResolved, accountErrored, accountError } =
    useAccount({ accountId: single != null ? accountId : null });
  const { transfersQuery, hasNextPage, offset, loadNextPage } = useTransfers({ sourceAccountIdArg: accountId });
  const { isLinkedAccountMissed: isLinkedAccountMissing } = useHasLinkedAccountsMissed({
    accountDetails: accountDetailData,
    accounts: accountsData,
    accountResolved
  });

  let accountData = {} as IAccountDetails;
  if (single != null) {
    accountData = accountDetailData as IAccountDetails;
  } else if (accountsData) {
    single = isSingleAccount;
    accountData = accountsData.find(account => accountId && account.id === accountId) as IAccountDetails;
  }

  const isLinkedAccountVerified = accountData?.linkedAccount?.verified;

  if (accountId && accountResolved && accountsData && !accountData) {
    const notFoundError = new AxiosError("", undefined, undefined, undefined, {
      status: STATUS_CODE.NOT_FOUND
    } as AxiosResponse);

    return (
      <ErrorRequestView
        context={context}
        includeNotFound
        requestErrors={[notFoundError]}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  if (
    accountResolved &&
    !offset &&
    transfersQuery.status !== QUERY_STATUS.loading &&
    (accountErrored || transfersQuery.error)
  ) {
    return (
      <ErrorRequestView
        context={context}
        includeNotFound
        requestErrors={[accountError, transfersQuery.error]}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  return (
    <section className={styles.account__wrapper}>
      <IconLink to="/" icon={<ArrowLeft />} text={strings.general.back} />
      <section className={styles.account__mainContent}>
        {(!accountResolved || (!offset && transfersQuery.status === QUERY_STATUS.loading)) && (
          <div className={styles.account__container}>
            <LoadingSection />
          </div>
        )}
        {accountResolved && (offset || transfersQuery.status !== QUERY_STATUS.loading) && (
          <div className={styles.account__container}>
            <Card
              accountId={accountData.id}
              title={accountData.product.title || accountData.product.displayName}
              status={accountData.status}
              balance={getCapitalBalance(accountData)}
              issueNumber={`${strings.general.issueNumber} ${accountData.product.issueNumber}`}
              availableBalance={getAvailableBalance(accountData)}
              rate={accountData.product.interestRate}
              category={accountData.product.category}
              frequency={accountData.product.interestFrequency || accountData.interest.interestFrequency}
              endOfTerm={
                accountData.maturity?.maturityDate ? formatToDisplayDate(accountData.maturity.maturityDate) : ""
              }
              executionDate={accountData.maturity?.executionDate}
              transfer
              key={accountData.id}
              sortCode={accountData.sortCode}
              accountNumber={accountData.accountNumber}
              noticePeriodDays={accountData.product.noticePeriodDays}
              greyBox
              single={single}
              maxBalance={accountData.product.maxBalance}
              accountDetails
              accountType={accountData.accountType}
              section={CardSection.ACCOUNT}
              linkedAccount={accountData.linkedAccount}
              openToFunding={accountData.openToFunding.enabled}
              openToFundingReason={accountData.openToFunding.reason}
              unauthorisedAccountCredit={accountData.unauthorisedAccountCredit}
              preMaturing={accountData.maturity?.preMaturing}
              maturityInstructionsSet={accountData.maturity?.instructionsSet}
              maturityInstructionType={accountData.maturity?.instructionType}
              completionDate={accountData.maturity?.completionDate}
            />
            <TransactionHistory
              transactions={transfersQuery.data}
              targetAccountId={accountId as string}
              title={strings.accountInformation.transactionHistoryLabel}
              nextPageAvailable={hasNextPage}
              offset={offset}
              status={transfersQuery.status}
              fetchStatus={transfersQuery.fetchStatus}
              loadNextPage={loadNextPage}
            />
          </div>
        )}
        {accountResolved && (
          <aside>
            <div className={styles.card__linkedAccount}>
              <LinkedAccount
                isLinkedAccountMissing={isLinkedAccountMissing}
                isLinkedAccountVerified={isLinkedAccountVerified}
                bank={accountData?.linkedAccount?.bankName || ""}
                name={accountData?.linkedAccount?.holderName || ""}
                acNumber={accountData?.linkedAccount?.accountNumber || ""}
                sortCode={accountData?.linkedAccount?.sortCode || ""}
                hasTitle
              />
              <AskAQuestion />
            </div>
          </aside>
        )}
      </section>
    </section>
  );
};

export default Account;
