import { Text } from "@shawbrook/sds-component-library";
import { formatToDisplayDate } from "utils";
import { FormattedDateProps } from "./FormattedDate.interface";

export const FormattedDate = ({ dateToFormat, fontWeight = "bold", as = "span", ...props }: FormattedDateProps) => {
  if (!dateToFormat) return null;
  return (
    <Text fontWeight={fontWeight} as={as} {...props}>
      {formatToDisplayDate(dateToFormat)}
    </Text>
  );
};
