import { Button, Card, Grid, Heading, RightArrow, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import useScrollToTop from "hooks/useScrollToTop";

import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { getInitialValue } from "../utils";
import { FullTransferYesTopped } from "./FullTransfer/FullTransferYesTopped";
import { PartialTransferNoTopped } from "./PartialTransfer/PartialTransferNoTopped";
import { PartialTransferYesTopped } from "./PartialTransfer/PartialTransferYesTopped";
import styles from "./TransferAmount.module.scss";

export const TransferAmount = () => {
  const navigate = useNavigate();
  const { title } = strings.newAccount;
  const { headingText1, headingText2 } = strings.newAccount.transferAmount;
  const {
    state,
    state: { selectedProductSet, editMode, editSelection },
    setState,
    clearState
  } = useNewAccountContext();
  useScrollToTop();

  const methods = useForm({
    defaultValues: (getInitialValue(state, editMode, "transferAmount") as FieldValues) || {},
    mode: "onBlur"
  });
  const {
    handleSubmit,
    formState: { isValid }
  } = methods;

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = (formData: FieldValues) => {
    if (editMode) {
      setState({ editSelection: { ...editSelection, transferAmount: formData } });
    } else {
      setState({ transferAmount: formData });
    }
    navigate(links.notice.link);
  };

  const fullTransfer = getInitialValue(state, editMode, "fullTransfer") as string;
  const toppedUpAccountInCY = getInitialValue(state, editMode, "toppedUpAccountInCY") as string;

  const partialTransferNoTopped = useMemo(
    () => fullTransfer === "No" && toppedUpAccountInCY === "No",
    [fullTransfer, toppedUpAccountInCY]
  );

  const partialTransferYesTopped = useMemo(
    () => fullTransfer === "No" && toppedUpAccountInCY === "Yes",
    [fullTransfer, toppedUpAccountInCY]
  );

  const fullTransferYesTopped = useMemo(
    () => fullTransfer === "Yes" && toppedUpAccountInCY === "Yes",
    [fullTransfer, toppedUpAccountInCY]
  );

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.taxYearWaypoint.link} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer} width="auto">
            <Space gap="2">
              <Heading level={2}>{fullTransferYesTopped ? headingText2 : headingText1}</Heading>
            </Space>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleContinue)}>
                {partialTransferNoTopped && <PartialTransferNoTopped />}
                {partialTransferYesTopped && <PartialTransferYesTopped />}
                {fullTransferYesTopped && <FullTransferYesTopped />}
                <Button
                  className={styles.page__cardContainer__continueButton}
                  iconRight
                  disabled={!isValid}
                  type="submit"
                  size="large">
                  {strings.general.continueButton}
                  <RightArrow width="24" height="24" />
                </Button>
              </form>
            </FormProvider>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
