import { Text } from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { useState } from "react";

import strings from "assets/strings/strings.json";
import Card from "components/Card/Card";
import cardStyles from "components/Card/Card.module.scss";
import ErrorRequestView from "components/ErrorRequestView";
import useAccount from "hooks/useAccount";
import useMoveMoneyFrom from "hooks/useMoveMoneyFrom";
import useScrollToTop from "hooks/useScrollToTop";
import { useMoveMoneyContext } from "state/moveMoneyState";

import { getAvailableBalance, isISAAccountCategory } from "utils";

import { CardSection } from "components/Card/interfaces";
import styles from "./MoveMoney.module.scss";
import MoveMoneyWrapper from "./MoveMoneyWrapper";

export const MoveMoneyFrom = () => {
  const context = useMoveMoneyContext();
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  useScrollToTop();
  const { accountsData, accountDetailData, accountResolved, accountErrored, accountsError, accountError } = useAccount({
    accountId: selectedAccountId
  });
  const { backUrl, withdrawableAccounts, nonWithdrawableAccounts, subTitle } = useMoveMoneyFrom({
    accountsData,
    accountDetailData,
    accountErrored
  });

  if (!accountResolved) return <MoveMoneyWrapper isLoaded={accountResolved} backUrl={backUrl} />;

  if (accountErrored) {
    return <ErrorRequestView context={context} requestErrors={[accountsError, accountError]} />;
  }

  const nonWithdrawableCx = classnames({
    [styles["page__content-accounts--next"]]: withdrawableAccounts.length
  });

  return (
    <MoveMoneyWrapper description={subTitle} isLoaded={accountResolved} backUrl={backUrl}>
      <section>
        {withdrawableAccounts.length > 0 && (
          <section>
            {withdrawableAccounts.map(el => (
              <Card
                key={el.id}
                title={el.product.title || el.product.displayName}
                issueNumber={`${strings.general.issueNumber} ${el.product.issueNumber}`}
                balance={getAvailableBalance(el)}
                sortCode={el.sortCode}
                accountNumber={el.accountNumber}
                accountId={el.id}
                fromISA={isISAAccountCategory(el.product.category)}
                customClasses={cardStyles["card__move-money-account-element"]}
                onClickHandler={() => setSelectedAccountId(el.id)}
                role="link"
              />
            ))}
          </section>
        )}
        {nonWithdrawableAccounts.length > 0 && (
          <section className={nonWithdrawableCx}>
            {withdrawableAccounts.length > 0 && (
              <Text fontWeight="medium" font="silka" css={{ fontSize: "$4", mb: "$3" }}>
                {strings.moveMoney.moveMoneyFrom.nonWithdrawableTitle}
              </Text>
            )}
            {nonWithdrawableAccounts.map(el => (
              <Card
                key={el.id}
                title={el.product.title || el.product.displayName}
                issueNumber={`${strings.general.issueNumber} ${el.product.issueNumber}`}
                balance={getAvailableBalance(el)}
                withdrawable={el.withdrawability.enabled}
                outboundWithdrawable={el.outboundWithdrawability?.enabled}
                withdrawableReason={el.withdrawability.reason}
                openToFunding={el.openToFunding.enabled}
                openToFundingReason={el.openToFunding.reason}
                category={el.product.category}
                section={CardSection.MOVE_MONEY_FROM}
                endOfTermForNonDisplay={el.maturity?.maturityDate}
                sortCode={el.sortCode}
                accountNumber={el.accountNumber}
                accountId={el.id}
                customClasses={`${cardStyles["card__move-money-account-element"]} ${cardStyles["card__move-money-account-element--inactive"]}`}
                linkedAccount={el.linkedAccount}
                unauthorisedAccountCredit={el.unauthorisedAccountCredit}
              />
            ))}
          </section>
        )}
      </section>
    </MoveMoneyWrapper>
  );
};

export default MoveMoneyFrom;
