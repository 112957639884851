import {
  Alert,
  Button,
  Card,
  Grid,
  Heading,
  RightArrow,
  SelectInput,
  Space,
  Text,
  Tooltip,
  WarningIcon
} from "@shawbrook/sds-component-library";
import { CURRENCIES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import classNames from "classnames";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import WhyWeAskThis from "components/WhyWeAskThisLink";
import { links } from "config/navLinks";
import useCurrencyInput from "hooks/useCurrencyInput";
import useScrollToTop from "hooks/useScrollToTop";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { useLocation, useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import { formatBalance, isFixedAccountCategory, isISAAccountCategory } from "utils";

import styles from "./AnnualSavingsGoal.module.scss";

export const AnnualSavingsGoal = () => {
  const navigate = useNavigate();
  const previousPathName: string | undefined = useLocation().state?.previousLocationPathName;
  const {
    textInput: { label, whyWeAskThisContent },
    dropdownInput: { sourceOfDepositOptions, label: dropdownLabel, tooltip },
    validations: { maximumError, minimumError },
    title,
    headingText,
    secondaryText
  } = strings.annualSavings;
  const { accountTypes } = strings.newAccount.accountType;
  const {
    state,
    state: { selectedProductSet, accountType, annualSavings, editMode, niNumber, transferISASavingsToCurrentAccount },
    setState,
    clearState
  } = useNewAccountContext();
  const [sourceOfDeposit, setSourceOfDeposit] = useState(annualSavings?.sourceOfDeposit);
  const product = selectedProductSet;

  useScrollToTop();

  const {
    amount: annualDeposit,
    handleChange: handleDepositAmountChange,
    onInputRefChange: depositChangeRef
  } = useCurrencyInput(annualSavings?.annualDeposit.toString() || "");

  const { soleAccount } = accountTypes;

  const maxValidationAmount =
    accountType === soleAccount.id ? selectedProductSet?.soleMaxBalance : selectedProductSet?.jointMaxBalance;
  const isMaxValidationTrigger =
    maxValidationAmount !== undefined &&
    maxValidationAmount !== null &&
    annualDeposit !== undefined &&
    Number(annualDeposit) > maxValidationAmount;

  const isContinueDisabled =
    annualDeposit === undefined || annualDeposit === "" || sourceOfDeposit === undefined || isMaxValidationTrigger;

  const isISA = selectedProductSet?.category && isISAAccountCategory(selectedProductSet.category);
  let backLink = links.newAccountType.link;
  if (isISA) {
    if (niNumber) {
      // If NI provided (not changed)
      backLink = links.nationalInsurance.link;
    } else if (transferISASavingsToCurrentAccount === "Yes") {
      // Transfer flow if no NI provided. According to flow No transfer return user to previous available step but for Yes transfer flow.
      backLink = links.notice.link;
    } else {
      // No transfer flow if no NI provided (not changed)
      backLink = links.isaTransfer.link;
    }
  }

  const inputContainerCx = classNames(styles.page__cardContainer__textInputBlock, {
    [styles["page__cardContainer__textInputBlock--error"]]: isMaxValidationTrigger
  });

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [state, navigate, accountTypes, selectedProductSet, accountType]);

  const handleSourceOfDepositChange = (sourceOfDepositValue: string) => {
    setSourceOfDeposit(sourceOfDepositValue);
  };

  const handleContinue = () => {
    const isNavigatedFromPreviousPath =
      previousPathName !== undefined && previousPathName === links.newAccountLinkedAccountForm.link;
    setState({
      annualSavings: { annualDeposit: Number(annualDeposit) ?? 0, sourceOfDeposit: sourceOfDeposit ?? "" }
    });
    const nextComponent = isNavigatedFromPreviousPath ? previousPathName : links.newAccountLinkedAccount.link;
    navigate(editMode ? links.newAccountSummary.link : nextComponent);
  };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={backLink} isEdit={editMode} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer}>
            <div>
              <Space gap="2">
                <Heading level={2}>{headingText}</Heading>
              </Space>
              <Text>{secondaryText}</Text>
            </div>
            <div className={styles.page__cardContainer__textInputBlock}>
              <div className={inputContainerCx}>
                <Space gap={isMaxValidationTrigger ? "1" : "2"}>
                  <Text className={styles.page__cardContainer__textInputBlock__label} font="silka">
                    {label}
                  </Text>
                </Space>
                {isMaxValidationTrigger && (
                  <Space gap="2">
                    <Text fontWeight="normal" className={styles.page__cardContainer__textInputBlock__inputError}>
                      {maximumError.replace("{{MAX_BALANCE}}", `${formatBalance(maxValidationAmount || 0)}`)}
                    </Text>
                  </Space>
                )}

                <div className={styles.page__cardContainer__textInputBlock__wrapper}>
                  <CurrencyInput
                    className={inputContainerCx}
                    id="annualdeposit"
                    value={annualDeposit}
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="AnnualDeposit"
                    aria-labelledby="annualDepositLabel"
                    onValueChange={(value?: string, _?: string, values?: CurrencyInputOnChangeValues) =>
                      handleDepositAmountChange(value || "", undefined, undefined, values?.formatted)
                    }
                    prefix={CURRENCIES.GBP}
                    autoComplete="off"
                    data-testid="annualdeposit"
                    ref={depositChangeRef}
                  />
                </div>
              </div>

              <WhyWeAskThis content={whyWeAskThisContent} alternativeFontStyle />

              {annualDeposit !== undefined &&
                annualDeposit !== "" &&
                Number(annualDeposit) < (product?.lowestRateUpperBound || 0) &&
                product &&
                product.category &&
                !isFixedAccountCategory(product?.category) && (
                  <Alert
                    variant="warning"
                    icon={<WarningIcon />}
                    title={minimumError
                      .replace("{{MIN_BALANCE}}", formatBalance(product?.lowestRateUpperBound || 0))
                      .replace("{{RATE}}", (product?.lowestInterestRate || 0).toString())}
                  />
                )}
            </div>

            <Space gap="5">
              <div className={styles.page__cardContainer__selectInputWrapper}>
                <SelectInput
                  labelText={{ main: dropdownLabel }}
                  toolTip={<Tooltip text={tooltip} side="bottom" variant="dark" />}
                  optionsList={sourceOfDepositOptions}
                  id="sourceofdeposit"
                  onValueChange={handleSourceOfDepositChange}
                  value={sourceOfDeposit}
                />
              </div>
            </Space>

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isContinueDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
