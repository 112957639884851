import { Button, Checkbox, Flex, Icon } from "@shawbrook/sds-component-library";
import { ButtonGroup } from "components/ButtonGroup";
import { GreyCard } from "components/GreyCard";
import { Dispatch, SetStateAction } from "react";

import strings from "assets/strings/strings.json";
import { Link } from "react-router-dom";

export interface ReviewInstructionFooterProps {
  isSubmitting?: boolean;
  confirmationReceived?: boolean;
  setConfirmationReceived?: Dispatch<SetStateAction<boolean>>;
}
const { submitBtn, cancelBtn, backToHomeButton } = strings.general;
const { checkbox: confirmationLabel } = strings.reviewSubmission.submissionForm;

export const ReviewInstructionFooter = ({
  isSubmitting,
  confirmationReceived,
  setConfirmationReceived
}: ReviewInstructionFooterProps) => {
  if (isSubmitting) {
    return (
      <GreyCard>
        <Flex direction="column" gap={6}>
          <Checkbox
            label={confirmationLabel}
            onCheckedChange={checked => setConfirmationReceived?.(!!checked)}
            id="confirmInformation"
            checked={confirmationReceived}
          />
          <ButtonGroup direction="rowReverse">
            <Button size="large" iconRight disabled={!confirmationReceived}>
              {submitBtn}
              <Icon name="RightArrow" color="current" />
            </Button>
            <Button variant="tertiary">{cancelBtn}</Button>
          </ButtonGroup>
        </Flex>
      </GreyCard>
    );
  }
  return (
    <ButtonGroup direction="rowReverse">
      <Button size="large" as={Link} to="/">
        {backToHomeButton}
      </Button>
    </ButtonGroup>
  );
};
