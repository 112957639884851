import { addDays, parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { DATES, possibleDateFormats, TIMEZONES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import { LIMITS, MATURED_FUNDS } from "assets/strings/values";
import { FormattedDate } from "components/FormattedDate";
import { IAccountSummary, Instruction, InterestFrequency, IProduct, ProductCategory } from "services/data/types";
import { REINVESTEMENT_TYPES } from "state/reinvestmentState";
import { formatTextWithPhoneHours } from "./formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { stringReplace } from "./replace";

export const formatSortCode = (sortCode: string): string => {
  const sortCodeLength = 6;

  if (!sortCode || sortCode.length !== sortCodeLength) return "";

  return sortCode
    .split("")
    .map((el, i) => (i % 2 === 1 && i !== sortCode.length - 1 ? `${el}-` : el))
    .join("");
};

export const formatBalance = (amount: number, shouldBeInteger: boolean = false): string => {
  const digits = shouldBeInteger ? 0 : 2;
  return !amount
    ? "£0.00"
    : `£${amount.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits })}`;
};

export const formatToDisplayBalance = (balance: number) => {
  const [pounds, pence] = formatBalance(balance).split(".");
  return (
    <>
      {pounds}.<small>{pence}</small>
    </>
  );
};

export const getOppositeBalanceInput = (input: string, balance: number): string => {
  const oppositeBalance = balance - parseFloat(input);
  return Number.isNaN(oppositeBalance) ? "" : oppositeBalance.toFixed(2).replace(/[.]00$/, "");
};

export const isDatetimeString = (str: string) => {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{7} [+-]\d{2}:\d{2}$/;
  const isValidFormat = regex.test(str);
  return isValidFormat;
};

export const isISOUTCDate = (str: string) => {
  // from https://stackoverflow.com/questions/3143070/regex-to-match-an-iso-8601-datetime-string
  const ISO8601Test =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
  if (!ISO8601Test.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !Number.isNaN(d.getTime()) && typeof d.toISOString() === typeof str;
};

export const convertToISOLocalDate = (
  strDate: string,
  dateFormat: string = "",
  timeZone: string = TIMEZONES.London
): string => {
  let isoLocalDate = "-";

  if (dateFormat) {
    const date = parse(strDate, dateFormat, new Date());
    if (!Number.isNaN(date)) {
      try {
        isoLocalDate = formatInTimeZone(date, timeZone, DATES.isoWithTimezoneShort);
      } catch (e) {
        /* logging will be added here */
      }
    }
  }

  return isoLocalDate;
};

export const convertTo12HTime = (
  hours: number,
  minutes?: number,
  twoDigitsHours: boolean = false,
  twoDigitsMinutes: boolean = false
) => {
  if (hours > 23 || (minutes && minutes > 59)) return "";
  const suffix = hours >= 12 ? "pm" : "am";
  let convertedHours: string | number = ((hours + 11) % 12) + 1;
  let convertedMinutes: string | number | undefined = minutes;
  if (twoDigitsHours) {
    convertedHours = `0${convertedHours}`.slice(-2);
  }
  if (typeof convertedMinutes !== "undefined" && twoDigitsMinutes) {
    convertedMinutes = `0${convertedMinutes}`.slice(-2);
  }
  return `${convertedHours}${typeof convertedMinutes !== "undefined" ? `:${convertedMinutes}` : ""}${suffix}`;
};

export const getDay = (date: Date, timeZone: string = TIMEZONES.London) => formatInTimeZone(date, timeZone, DATES.day);
export const getFullMonth = (date: Date, timeZone: string = TIMEZONES.London) =>
  formatInTimeZone(date, timeZone, DATES.fullMonth);
export const getYear = (date: Date, timeZone: string = TIMEZONES.London) =>
  formatInTimeZone(date, timeZone, DATES.year);
export const getHour = (date: Date, timeZone: string = TIMEZONES.London) =>
  formatInTimeZone(date, timeZone, DATES.hour);
export const getMinutes = (date: Date, timeZone: string = TIMEZONES.London) =>
  formatInTimeZone(date, timeZone, DATES.minutes);
export const getSeconds = (date: Date, timeZone: string = TIMEZONES.London) =>
  formatInTimeZone(date, timeZone, DATES.seconds);

export const displayDate = (date: Date, timeZone: string = TIMEZONES.London) =>
  `${`0${getDay(date, timeZone)}`.slice(-2)} ${getFullMonth(date, timeZone)} ${getYear(date, timeZone)}`;

export const formatToDisplayDate = (dateFromBackend: string) => {
  let formattedDate = "-";

  if (isISOUTCDate(dateFromBackend)) {
    formattedDate = displayDate(new Date(dateFromBackend));
  } else if (isDatetimeString(dateFromBackend)) {
    formattedDate = displayDate(new Date(dateFromBackend));
  } else {
    possibleDateFormats.some(format => {
      const convertedDate = convertToISOLocalDate(dateFromBackend, format);
      if (convertedDate !== "-") {
        formattedDate = displayDate(new Date(convertedDate));
        return true;
      }
      return false;
    });
  }
  return formattedDate;
};

// given a date from the backend, return the time in the format "HH:MM" (no am/pm, or seconds)
export const formatToDisplayTime = (dateFromBackend: string) => {
  let formattedDate = "-";
  const date = new Date(dateFromBackend);
  if (date.toString() !== "Invalid Date") {
    formattedDate = date?.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
  }
  return formattedDate;
};

export const getInstructionDisplayDate = (dateFormats: string[], dateFromBackend?: string) => {
  let instructionDate = "";

  if (dateFromBackend) {
    let ISOMaturityDate = "-";
    dateFormats.some(format => {
      const isoDate = convertToISOLocalDate(dateFromBackend, format);
      if (isoDate !== "-") {
        ISOMaturityDate = isoDate;
        return true;
      }
      return false;
    });
    if (ISOMaturityDate !== "-") {
      instructionDate = formatToDisplayDate(addDays(new Date(ISOMaturityDate), 1).toISOString());
    }
  }

  return instructionDate;
};

export const greetings = (hours: number): string => {
  if (hours < 12) return strings.greetings.morning;
  if (hours < 18) return strings.greetings.afternoon;
  return strings.greetings.evening;
};

export const tranformMobileForAPISend = (mobilePhoneNumber: string): string =>
  strings.general.MOBILE_PHONE_PREFIX.concat(mobilePhoneNumber.replaceAll(" ", "").replace(/^0/, ""));

export const isFixedAccount = (displayName: string) => /.*Fixed((?!ISA).)*$/.test(displayName);

export const isFixedISAAccount = (displayName: string) => /Fixed.*ISA/.test(displayName);

export const isEAISAAccount = (displayName: string) => /Easy Access.*ISA/.test(displayName);

export const isNoticeISAAccount = (displayName: string) => /Notice.*ISA/.test(displayName);

export const isISAAccountCategory = (category: ProductCategory) =>
  category === ProductCategory.EASY_ACCESS_ISA ||
  category === ProductCategory.FIXED_RATE_ISA ||
  category === ProductCategory.NOTICE_ISA;

export const isFixedAccountCategory = (category: ProductCategory) =>
  category === ProductCategory.FIXED_RATE || category === ProductCategory.FIXED_RATE_ISA;

export const getPhoneFormat = (phone: string = "") => {
  let formattedPhone = phone.replace("+44", "0").replace(/\s/g, "");

  if (/^03/.test(formattedPhone)) {
    formattedPhone = formattedPhone.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
  }

  return formattedPhone;
};

export const isTimePastMaturityCutoffTime = (maturityDate: string, currentDateTime: Date = new Date()): boolean => {
  const mDate = new Date(maturityDate);
  mDate.setHours(
    +(process.env.REACT_APP_MATURITY_INSTRUCTIONS_CUTOFF_TIME_HOUR || 17),
    +(process.env.REACT_APP_MATURITY_INSTRUCTIONS_CUTOFF_TIME_MIN || 0),
    +(process.env.REACT_APP_MATURITY_INSTRUCTIONS_CUTOFF_TIME_SEC || 0)
  );
  return mDate.getTime() < currentDateTime.getTime();
};

export const getMaturityInfoText = (maturityDate: string) =>
  formatTextWithPhoneHours(strings.card.maturingSoonMessage).replace("{{DATE}}", maturityDate);

export const getPastCutoffTimeMaturityAlertMessage = (completionDate: string) =>
  stringReplace(strings.card.pastCutoffTimeAlert.message, {
    "{{COMPLETION_DATE}}": completionDate ? <FormattedDate dateToFormat={completionDate} /> : "-"
  });

export const getInstructionsSetInfoText = (infoText: string, maturityDate?: string, linkedAccountBank?: string) =>
  stringReplace(infoText, {
    "{{DATE}}": maturityDate ? <FormattedDate dateToFormat={maturityDate} /> : "-",
    "{{BANK_NAME}}": linkedAccountBank || ""
  });

export const getReinvestmnentType = (instructions: Instruction[] | undefined) => {
  if (instructions?.length === 1) return REINVESTEMENT_TYPES.full;
  if (instructions?.length === 2) return REINVESTEMENT_TYPES.partial;
  return undefined;
};

export const findProductForTerm = (products: IProduct[], frequency: InterestFrequency) =>
  products.find(el => el.interestFrequency === frequency);

export const findInterestRate = (products: IProduct[], frequency: InterestFrequency) =>
  findProductForTerm(products, frequency)?.interestRate;

export const getCancelModalText = (
  editMode: boolean,
  hasInstructionChanged: boolean | string | undefined,
  accountProductCategory: ProductCategory | null,
  text: string
) =>
  (editMode &&
    !hasInstructionChanged &&
    text
      .replace("{{MATURED_GROSS}}", LIMITS.MATURED_GROSS)
      .replace(
        "{{MATURED_FUNDS}}",
        accountProductCategory?.includes("ISA") ? MATURED_FUNDS.MATURED_FUNDS_ISA : MATURED_FUNDS.MATURED_FUNDS
      )) ||
  undefined;

export const getAvailableBalance = (account?: IAccountSummary) =>
  typeof account?.balances?.clearedBalance?.amount !== "undefined"
    ? account.balances.clearedBalance.amount
    : account?.balances?.availableBalance?.amount ?? 0;

export const getCapitalBalance = (account?: IAccountSummary) =>
  typeof account?.balances?.bookedBalance?.amount !== "undefined"
    ? account.balances.bookedBalance.amount
    : account?.balances?.capitalBalance?.amount ?? 0;

export const isProductEAFixedISA = (category: ProductCategory) => {
  const nonNoticeCategory = [
    ProductCategory.EASY_ACCESS,
    ProductCategory.EASY_ACCESS_ISA,
    ProductCategory.FIXED_RATE_ISA
  ];

  return nonNoticeCategory.includes(category);
};

export default {
  formatSortCode,
  formatBalance,
  formatToDisplayDate,
  getInstructionDisplayDate,
  greetings,
  displayDate,
  isISOUTCDate,
  isFixedAccount,
  isISAAccountCategory,
  getMaturityInfoText,
  getPhoneFormat,
  isEAISAAccount,
  findInterestRate,
  convertToISOLocalDate,
  getFullMonth,
  getDay,
  getYear,
  getHour,
  getMinutes,
  getAvailableBalance,
  getBookedBalance: getCapitalBalance
};
