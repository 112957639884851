import { Flex } from "@shawbrook/sds-component-library";
import classNames from "classnames";
import { ButtonGroupProps } from "./ButtonGroup.interface";
import styles from "./ButtonGroup.module.scss";

export const ButtonGroup = ({ children, align, justify = "between", direction = "row" }: ButtonGroupProps) => {
  const cx = classNames(styles.buttonGroup, {
    [styles[`buttonGroup--align-${align}`]]: !!align,
    [styles[`buttonGroup--justify-${justify}`]]: !!justify,
    [styles[`buttonGroup--direction-${direction}`]]: !!direction
  });
  return (
    <Flex justify="center" align="stretch" direction="column" gap={4} className={cx}>
      {children}
    </Flex>
  );
};
