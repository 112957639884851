import { Flex, Loader, Menu } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import FailureModal from "components/FailureModal/FailureModal";
import SuccessModal from "components/SuccessModal";
import { links } from "config/navLinks";
import { useCloseAccount } from "hooks/useCloseAccount/useCloseAccount";
import { useNavigate, useParams } from "react-router-dom";
import { AccountStatus, ILinkedAccount, ProductCategory } from "services/data/types";
import styles from "./AccountMenu.module.scss";

interface IAccountMenuProps {
  issueNumber: string;
  title?: string;
  status?: AccountStatus;
  category?: ProductCategory;
  linkedAccount?: ILinkedAccount;
  unauthorisedAccountCredit?: boolean;
}

export const AccountMenu = ({
  title,
  status,
  issueNumber,
  category,
  linkedAccount,
  unauthorisedAccountCredit
}: IAccountMenuProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.id || "";

  const { backToHomeButton } = strings.general;
  const {
    messageUnfundedFRAndISA,
    subject,
    successModal: { eaAndISATitle: modalTitle, fixedRateAndISAUnfundedContent: modalContent },
    failureModal: { title: failureModalTitle, eaAndFixedContent }
  } = strings.closeAccount;
  const { closeAccount } = strings.card;

  const threadSubject = subject.replace("{{ACCOUNT_TITLE}}", `${title} ${strings.general.issueNumber} ${issueNumber}`);
  const { postThread, isSubmitThreadLoading, showSuccessModal, showFailureModal } = useCloseAccount({
    accountId,
    subject: threadSubject,
    messageContent: messageUnfundedFRAndISA
  });

  const isFRAndISAUnfunded =
    status === AccountStatus.UNFUNDED &&
    (category === ProductCategory.FIXED_RATE || category === ProductCategory.FIXED_RATE_ISA);

  const noVerifiedLinkedAccLink = links.linkedAccountIssue.link.replace(":id", accountId || "");

  const handleCloseAccount = () => {
    if (isFRAndISAUnfunded) {
      postThread();
      return;
    }
    if (!linkedAccount || !linkedAccount?.accountNumber || !linkedAccount?.verified) {
      navigate(noVerifiedLinkedAccLink);
      return;
    }
    navigate(links.closeAccount.link.replace(":id", accountId || ""));
  };

  return (
    <>
      <SuccessModal
        open={showSuccessModal}
        url={links.landing.link}
        content={modalContent}
        header={modalTitle.replace("{{ACCOUNT_TITLE}}", title || "")}
        btnContent={backToHomeButton}
        headerMarginBottom="$6"
        biggerFont
      />
      <FailureModal
        header={failureModalTitle}
        content={eaAndFixedContent}
        btnContent={strings.general.sendMessage}
        open={showFailureModal}
        url={links.inbox.link}
      />

      <Flex direction="column" justify="center" align="center">
        <Menu variant="secondary">
          <Menu.Trigger
            className={styles.trigger}
            disabled={unauthorisedAccountCredit}
            data-testid="account-menu"
            aria-label="account_menu"
          />
          <Menu.Content>
            <Menu.Item as="button" onClick={handleCloseAccount} className={styles.button}>
              {closeAccount} {isSubmitThreadLoading && <Loader className={styles.loader} />}
            </Menu.Item>
          </Menu.Content>
        </Menu>
      </Flex>
    </>
  );
};
