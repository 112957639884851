import { Button, Container, Flex, Heading, Icon, Layout, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PageLoader } from "components/PageLoader";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PageContentProps, PageContextProps, PageProps } from ".";
import styles from "./pageLayout.module.scss";

const PageContext = createContext<PageContextProps>({ loading: false });

const PageLayout = ({ title, children, back, loading }: PageProps) => {
  const navigate = useNavigate();
  const value = useMemo(() => ({ loading }), [loading]);
  const { back: backButtonText } = strings.general;

  return (
    <PageContext.Provider value={value}>
      <div className={styles.page__header}>
        <Container size="3-5">
          <Space gap={8} />
          <Flex direction="column" gap="3">
            {back !== null && (
              <Button
                variant="plain"
                type="button"
                role="link"
                onClick={back === undefined ? () => navigate(-1) : back}
                className={styles.page__backButton}>
                <Icon name="leftArrow" color="current" />
                {backButtonText}
              </Button>
            )}
            <Heading level={1} color="white">
              {title}
            </Heading>
            <Space gap="0-5" />
          </Flex>
        </Container>
      </div>
      <Container size="3-5">
        <Layout.Grid>{children}</Layout.Grid>
      </Container>
    </PageContext.Provider>
  );
};

const PageContent = ({ children, ...props }: PageContentProps) => {
  const { loading } = useContext(PageContext);
  return <Layout.TwoColumnContent {...props}>{loading ? <PageLoader /> : children}</Layout.TwoColumnContent>;
};
const PageAside = ({ children, ...props }: PageContentProps) => {
  const { loading } = useContext(PageContext);
  return (
    <Layout.TwoColumnSide {...props}>{loading ? <PageLoader showSpinner={false} /> : children}</Layout.TwoColumnSide>
  );
};

PageLayout.Content = PageContent;
PageLayout.Aside = PageAside;

export { PageLayout };
