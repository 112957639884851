/**
 * @param balance The base amount in pounds and pence (e.g. 100.56)
 * @param withdrawal The withdrawal amount in pounds and pence (e.g. 27.38)
 * @returns The remaining balance after the withdrawal in pounds and pence (e.g. 73.18)
 */

export const calculateRemainingBalance = (balance: number, withdrawal: number) => {
  const balanceInPence = parseFloat(balance.toFixed(2)) * 100;
  const withdrawalInPence = parseFloat(withdrawal.toFixed(2)) * 100;

  const difference = (balanceInPence - withdrawalInPence) / 100;
  return difference;
};
