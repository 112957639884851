import { Button, Icon } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import classNames from "classnames";
import { ComponentProps } from "react";
import { stringReplace } from "utils/replace";
import styles from "./showMoreButton.module.scss";

export type ShowMoreButtonProps = ComponentProps<typeof Button> & {
  remaining?: number;
  showLess?: boolean;
};

export const ShowMoreButton = ({ remaining, className, showLess, ...props }: ShowMoreButtonProps) => {
  const { showMore, showLess: showLessString } = strings.general;
  const showMoreString = showLess
    ? showLessString
    : stringReplace(showMore, {
        "{{limit}}": remaining || ""
      });

  const cx = classNames(className, styles.showMoreButton);

  return (
    <Button variant="tertiary" iconRight isSlim className={cx} {...props}>
      {showMoreString}
      <Icon name={showLess ? "upArrow" : "DownArrowIcon"} color="current" size={20} />
    </Button>
  );
};
