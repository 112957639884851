import useAccount from "hooks/useAccount";
import { useGetMaturityInstruction } from "query/useMaturityInstructionQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IAccountDetails, MaturityInstructionType, ReceivedInstructionProduct } from "services/data/types";
import { INTEREST_FREQUENCY, InterestFrequency } from "state/reinvestmentState";
import { getAvailableBalance } from "utils";
import { calculateRemainingBalance } from "utils/calculateRemainingBalance/calculateRemainingBalance";
import { FormattedAvailableProduct } from "utils/formatAvailableProducts/interfaces";
import { UseMaturityInstruction } from "./useMaturityInstruction.interface";

export const useMaturityInstruction = (accountId: string): UseMaturityInstruction => {
  const [reinvestmentType, setReinvestmentType] = useState<MaturityInstructionType>();
  const [reinvestmentAccount, setReinvestmentAccount] = useState<FormattedAvailableProduct | undefined>();
  const [reinvestmentProduct, setReinvestmentProduct] = useState<
    ReceivedInstructionProduct | IAccountDetails["product"]
  >();
  const [amountToReinvest, setAmountToReinvest] = useState(0);

  if (!accountId) {
    throw new Error("Account ID is required");
  }

  const { isMaturityInstructionLoading, maturityInstructionData } = useGetMaturityInstruction({
    sourceAccountId: accountId
  });

  const { accountDetailData: maturingAccountData, isAccountDetailLoading: isMaturingAccountDataLoading } = useAccount({
    accountId
  });

  const investmentInstruction = maturityInstructionData?.instructions?.find(({ type }) =>
    [
      MaturityInstructionType.REINVEST_NEW,
      MaturityInstructionType.PARTIAL,
      MaturityInstructionType.REINVEST_EXISTING
    ].includes(type)
  );
  const withdrawalInstruction = maturityInstructionData?.instructions?.find(
    ({ type }) => type === MaturityInstructionType.WITHDRAW
  );

  const {
    accountDetailData: reinvestmentExistingAccountData,
    isAccountDetailLoading: isReinvestmentExistingAccountDataLoading
  } = useAccount({
    accountId: investmentInstruction?.toAccount?.id
  });

  const amountToWithdraw = calculateRemainingBalance(getAvailableBalance(maturingAccountData), amountToReinvest);

  const loading =
    isMaturingAccountDataLoading ||
    isMaturityInstructionLoading ||
    (!!investmentInstruction?.toAccount?.id && isReinvestmentExistingAccountDataLoading);

  useEffect(() => {
    if (isMaturingAccountDataLoading) return;
    setReinvestmentType(maturingAccountData?.maturity?.instructionType);
  }, [maturingAccountData, isMaturingAccountDataLoading]);

  useEffect(() => {
    if (isMaturityInstructionLoading) return;
    if (!maturityInstructionData?.type) return;

    if (investmentInstruction?.type === MaturityInstructionType.REINVEST_EXISTING) {
      setReinvestmentProduct(reinvestmentExistingAccountData?.product);
    } else {
      setReinvestmentProduct(investmentInstruction?.toProduct);
    }
    setAmountToReinvest(investmentInstruction?.amount?.amount || 0);
  }, [
    isMaturityInstructionLoading,
    maturityInstructionData?.instructions,
    maturityInstructionData?.type,
    reinvestmentExistingAccountData?.product,
    investmentInstruction
  ]);

  const maxBalanceExceeded = useMemo(() => {
    const { accountType } = maturingAccountData || {};
    const accountBalance = getAvailableBalance(maturingAccountData);

    if (!reinvestmentAccount) return false;
    if (!accountType) return false;
    const maxBalance =
      accountType === "Joint" ? reinvestmentAccount.jointMaxBalance : reinvestmentAccount.soleMaxBalance;
    if (!maxBalance) return false;
    return accountBalance > maxBalance;
  }, [maturingAccountData, reinvestmentAccount]);

  const getProductByInterestFrequency = useCallback(
    (frequency?: InterestFrequency) => {
      if (!frequency) return undefined;
      const product = reinvestmentAccount?.products.find(
        ({ interestFrequency: productInterestFrequency }) => productInterestFrequency === frequency
      );
      return product;
    },
    [reinvestmentAccount?.products]
  );

  const handleReinvestmentTypeChange = (value: MaturityInstructionType) => {
    switch (value) {
      case MaturityInstructionType.REINVEST_NEW:
        setReinvestmentType(MaturityInstructionType.REINVEST_NEW);
        setAmountToReinvest(getAvailableBalance(maturingAccountData));
        break;
      case MaturityInstructionType.PARTIAL:
        setReinvestmentType(MaturityInstructionType.PARTIAL);
        setAmountToReinvest(0);
        break;
      default:
        throw new Error("Invalid reinvestment type");
    }
  };
  const handleReinvestmentInterestRateChange = (value: InterestFrequency) => {
    switch (value) {
      case "monthly":
        setReinvestmentProduct(getProductByInterestFrequency(INTEREST_FREQUENCY.monthly));
        break;
      case "annually":
        setReinvestmentProduct(getProductByInterestFrequency(INTEREST_FREQUENCY.annually));

        break;
      default:
        throw new Error("Invalid interest frequency type");
    }
  };

  return {
    accountId,
    reinvestmentAccount,
    setReinvestmentAccount,
    maturingAccountData,
    reinvestmentType,
    handleReinvestmentTypeChange,
    handleReinvestmentInterestRateChange,
    getProductByInterestFrequency,
    reinvestmentProduct,
    amountToReinvest,
    setAmountToReinvest,
    amountToWithdraw,
    loading,
    investmentInstruction,
    withdrawalInstruction,
    maxBalanceExceeded
  };
};
