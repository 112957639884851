import { IAvailableProduct, ProductCategory } from "services/data/types";
import { formatProductsGroup } from "../formatProductsGroup";
import { FormattedAvailableProduct } from "../interfaces";
import { transformTerm } from "../transformTerm";

const ALLOWED_CATEGORIES = {
  ISA: [ProductCategory.FIXED_RATE_ISA, ProductCategory.EASY_ACCESS_ISA],
  NON_ISA: [ProductCategory.FIXED_RATE, ProductCategory.NOTICE, ProductCategory.EASY_ACCESS]
};

export const sortAndCategoriseProducts = (availableProducts: IAvailableProduct[], isISA?: boolean) => {
  const formattedProducts = availableProducts.map(product => formatProductsGroup(product, { maxBalance: false }));
  const allowedCategories = isISA ? ALLOWED_CATEGORIES.ISA : ALLOWED_CATEGORIES.NON_ISA;

  const productsByCategory = formattedProducts.reduce((acc, product) => {
    if (!product) return acc;
    const { category } = product;
    if (!allowedCategories.includes(category)) return acc;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {} as Record<ProductCategory, FormattedAvailableProduct[]>);

  const categories = Object.keys(productsByCategory) as ProductCategory[];
  categories.forEach(category => {
    productsByCategory[category] = productsByCategory[category].sort(
      (a, b) => transformTerm(a.term) - transformTerm(b.term)
    );
  });

  return productsByCategory;
};
