import { Flex, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ChangeAlert, PageHeader } from "journeys/components/";
import useScrollToTop from "hooks/useScrollToTop";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import StepMobileIds from "pages/BankfastUserMigrationMobile/StepIds";
import { useFormContext } from "react-hook-form";
import { CUSTOMER_MOBILE, ComponentState, IScreenProps } from "services/data/types/bankfastUserMigration/";
import { useDidUpdate } from "utils/customHooks";

export const ChangeMobile = ({ flowId, setCurrentState, currentState }: IScreenProps) => {
  useScrollToTop();
  const { title, introA, introB, boxText, boxTextB, thisIsMyMobileNumberLink } =
    strings.bankfastUserMigrationMobile.changeMobile;
  const { getValues, setValue } = useFormContext();
  const navigateToStep = useNavigateToStep(flowId);

  useDidUpdate(() => {
    if (currentState === ComponentState.PRESUBMIT) {
      setCurrentState?.(ComponentState.INITIAL);
      setValue(CUSTOMER_MOBILE, "");
      navigateToStep(StepMobileIds.MOBILE);
    }
  }, [currentState]);

  return (
    <Flex gap="6" direction="column">
      <PageHeader title={title}>
        <Text fontWeight="medium">
          <Text as="span">{introA}</Text>
          <Text as="span" fontWeight="bold">
            {getValues(CUSTOMER_MOBILE)}
          </Text>
          <Text as="span">{introB}</Text>
        </Text>
      </PageHeader>

      <ChangeAlert boxText={boxText} boxTextB={boxTextB} buttonLabel={thisIsMyMobileNumberLink} />
    </Flex>
  );
};

export default ChangeMobile;
