import { Button, Flex, Icon, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { Link } from "react-router-dom";
import { INTEREST_FREQUENCY } from "state/reinvestmentState";
import { findInterestRate, formatBalance } from "utils";
import { getMaturityInstructionAccountCategoryStrings } from "utils/getMaturityInstructionAccountCategoryStrings";
import { AvailableProductCard } from "../AvailableProductCard";
import styles from "./ProductCardItem.module.scss";
import { InterestRateProps, MaxBalanceProps, ProductCardItemProps } from "./ProductCardItem.types";

const InterestRate = ({ rate, frequency }: InterestRateProps) => (
  <Text as="span">
    <Text as="span" fontWeight="bold">
      {`${rate.toFixed(2)}% `}
    </Text>
    {frequency}
  </Text>
);

const MaxBalance = ({ balance, maxBalanceType }: MaxBalanceProps) => (
  <Text as="span">
    <Text as="span" fontWeight="bold">
      {formatBalance(balance, true)}
    </Text>
    {maxBalanceType}
  </Text>
);

export const ProductCardItem = ({ account, onSelectDestination, onSelectClick }: ProductCardItemProps) => {
  const {
    products,
    title: accountTitle,
    maxInterestRate,
    category,
    soleMaxBalance,
    jointMaxBalance,
    kpiLink
  } = account;

  const { interestRateText } = getMaturityInstructionAccountCategoryStrings(category);
  const annualInterestRate = findInterestRate(products, INTEREST_FREQUENCY.annually);
  const monthlyInterestRate = findInterestRate(products, INTEREST_FREQUENCY.monthly);

  const hasSoleAndJointMaxBalance = !!soleMaxBalance && !!jointMaxBalance;
  const {
    interest: { interestRate },
    gross: { gross, monthly, annually },
    maxBalance: { maxBalance, sole, joint },
    summaryPdf
  } = strings.selectAccount.productCard;
  const { select } = strings.general;

  return (
    <AvailableProductCard>
      <AvailableProductCard.Header title={accountTitle}>
        {kpiLink && (
          <a href={kpiLink} target="_blank" rel="noopener noreferrer">
            <Flex gap={1} align="center">
              <Text as="span" font="silka">
                {summaryPdf}
              </Text>
              <Icon name="download" size={20} />
            </Flex>
          </a>
        )}
      </AvailableProductCard.Header>
      <Flex justify="between" className={styles.productCardItem__columnsHolder} wrap="wrap">
        <AvailableProductCard.Column direction="column" title={interestRate}>
          <Flex className={styles.productCardItem__interestRateColumn} align="baseline" gap={2}>
            <Text as="span" textStyle="xlarge" font="silka" className={styles.productCardItem__interestRate}>
              {maxInterestRate.toFixed(2)}%
            </Text>
            <Text>{interestRateText}</Text>
          </Flex>
        </AvailableProductCard.Column>
        {(annualInterestRate || monthlyInterestRate) && (
          <AvailableProductCard.Column direction="column" title={gross}>
            {annualInterestRate && <InterestRate rate={annualInterestRate} frequency={annually} />}
            {monthlyInterestRate && <InterestRate rate={monthlyInterestRate} frequency={monthly} />}
          </AvailableProductCard.Column>
        )}
        <AvailableProductCard.Column
          direction="column"
          title={!!soleMaxBalance || !!jointMaxBalance ? maxBalance : undefined}>
          {!!soleMaxBalance && (
            <MaxBalance balance={soleMaxBalance} maxBalanceType={hasSoleAndJointMaxBalance ? ` ${sole}` : ""} />
          )}
          {!!jointMaxBalance && (
            <MaxBalance balance={jointMaxBalance} maxBalanceType={hasSoleAndJointMaxBalance ? ` ${joint}` : ""} />
          )}
        </AvailableProductCard.Column>
        <AvailableProductCard.Column
          direction="column"
          justify="end"
          align="end"
          className={styles.productCardItem__selectButtonColumn}>
          {/* TODO - remove disabled prop when journey is in place */}
          <Button
            as={Link}
            iconRight
            className={styles.productCardItem__selectButton}
            to={onSelectDestination}
            onClick={onSelectClick}>
            {select}
            <Icon name="rightArrow" color="current" />
          </Button>
        </AvailableProductCard.Column>
      </Flex>
    </AvailableProductCard>
  );
};
