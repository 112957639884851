import { Fragment, ReactNode } from "react";
import rreplace from "react-string-replace";
import { v4 as uuid } from "uuid";

export const stringReplace = (input: string | ReactNode[], replacements: Record<string, ReactNode>) => {
  const replacementObject = Object.entries(replacements);
  let output: ReactNode[] | string = input;
  replacementObject.forEach(([key, value]) => {
    output = rreplace(output, key, () => <Fragment key={uuid()}>{value}</Fragment>);
  });
  return output;
};
