import { Tag, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";

export interface AccountTagProps {
  accountName?: string;
  accountIssueNumber?: number;
}

export const AccountTag = ({ accountIssueNumber = 0, accountName }: AccountTagProps) => {
  const { issueNumber: issueNumberText } = strings.general;
  return (
    <Tag>
      <Text>
        {accountName && (
          <Text as="span" fontWeight="bold">
            {accountName}
          </Text>
        )}
        {accountIssueNumber > 0 && ` ${issueNumberText} ${accountIssueNumber}`}
      </Text>
    </Tag>
  );
};
