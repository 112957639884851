import { Button, Card, Flex, Heading, Icon, RadioGroup, RadioItem, Tooltip } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ButtonGroup } from "components/ButtonGroup";
import { Aside } from "components/MaturityInstruction/Aside";
import { PageLayout } from "components/Page";
import { links } from "config/navLinks";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { INTEREST_FREQUENCY, InterestFrequency } from "state/reinvestmentState";
import { isFixedAccountCategory } from "utils";
import { formatInterestRateLabel } from "utils/formatInterestRateLabel";

const { interestOptionsTitle, tooltip, ariaLabels } = strings.chooseInterest;
const { title: pageTitle } = strings.reinvestment;
const { reviewButton } = strings.general;

export const SelectInterestType = () => {
  const navigate = useNavigate();
  const {
    accountId,
    reinvestmentAccount,
    reinvestmentType,
    reinvestmentProduct,
    handleReinvestmentInterestRateChange
  } = useMaturityInstructionContext();

  useEffect(() => {
    if (!reinvestmentAccount || !reinvestmentType) {
      navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
    }
  }, [accountId, navigate, reinvestmentAccount, reinvestmentType]);

  const { category: newAccountCategory, products: newAccountProducts } = reinvestmentAccount || {};

  const interestRates = newAccountProducts?.reduce((rates, { interestRate, interestFrequency }) => {
    if (!interestFrequency || interestRate < 0) return rates;
    return { ...rates, [interestFrequency]: interestRate };
  }, {} as Record<InterestFrequency, number>);

  const newAccountIsFixedRate = newAccountCategory ? isFixedAccountCategory(newAccountCategory) : undefined;

  const reviewLink = links.submitInstruction.link.replace(":id", accountId);
  return (
    <PageLayout title={pageTitle}>
      <PageLayout.Content>
        <Card width="full" variant="white" radius="none">
          <Flex direction="column" gap={8}>
            <Heading level={2}>
              {interestOptionsTitle}
              <Tooltip align="center" side="bottom" avoidCollisions {...tooltip} variant="dark" />
            </Heading>
            <RadioGroup
              labelText={{}}
              ariaLabel={ariaLabels.interestFrequency}
              orientation="vertical"
              onValueChange={handleReinvestmentInterestRateChange}
              value={reinvestmentProduct?.interestFrequency}>
              {interestRates?.annually && interestRates?.annually > 0 && (
                <RadioItem
                  value={INTEREST_FREQUENCY.annually}
                  label={formatInterestRateLabel({
                    annualRate: interestRates.annually,
                    rate: interestRates.annually,
                    frequency: INTEREST_FREQUENCY.annually,
                    isFixedRate: !!newAccountIsFixedRate
                  })}
                  id={INTEREST_FREQUENCY.annually}
                  aria-label={ariaLabels.annually}
                />
              )}
              {interestRates?.monthly && interestRates?.monthly > 0 && (
                <RadioItem
                  value={INTEREST_FREQUENCY.monthly}
                  label={formatInterestRateLabel({
                    annualRate: interestRates.annually,
                    rate: interestRates.monthly,
                    frequency: INTEREST_FREQUENCY.monthly,
                    isFixedRate: !!newAccountIsFixedRate
                  })}
                  id={INTEREST_FREQUENCY.monthly}
                  aria-label={ariaLabels.monthly}
                />
              )}
            </RadioGroup>
            <ButtonGroup justify="end">
              <Button
                as={Link}
                iconRight
                size="large"
                disabled={!reinvestmentProduct?.interestFrequency}
                to={reviewLink}>
                {reviewButton}
                <Icon name="RightArrow" color="current" />
              </Button>
            </ButtonGroup>
          </Flex>
        </Card>
      </PageLayout.Content>
      <PageLayout.Aside>
        <Aside showMaturingAccount="overview" showReinvestmentAccount maturingAccountId={accountId} />
      </PageLayout.Aside>
    </PageLayout>
  );
};
