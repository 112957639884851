import { useRef, useState } from "react";
import { IPollForUpdatedDataProps } from "./pollForUpdatedData.interface";

// This file implements polling based on the method described on the SO response by React Query developer
//    Link to SO thread: https://stackoverflow.com/questions/69027427/react-query-can-i-use-react-query-for-polling-until-i-get-certain-data
//    Link to related GH Pull Request: https://github.com/TanStack/query/pull/2622

const currentTimeMs = (): number => performance.now();

export const pollForUpdatedData =
  <TQueryDataType>(reactQueryFn: any) =>
  ({ isValidDataReturned, maxTotalTime = 30000, pollingInterval = 1000 }: IPollForUpdatedDataProps<TQueryDataType>) =>
  ({ queryParams }: any) => {
    const [enabled, setEnabled] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const startTimeMsRef = useRef<number>(performance.now());
    const elapsedTimeMsRef = useRef<number>(0);

    // This function is passed to react query's useQuery as a refetchInterval function.
    // From documentation for refetchInterval here : https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
    //
    // refetchInterval: number | false | ((query: Query) => number | false | undefined)
    //  Optional
    //    If set to a number, all queries will continuously refetch at this frequency in milliseconds
    //    If set to a function, the function will be executed with the query to compute a frequency
    //
    // Not mentioned in the docs, that if the function returns false, then refetching/polling will stop.

    const refetchInterval = (data: TQueryDataType | undefined): number | false => {
      if (enabled) {
        elapsedTimeMsRef.current = currentTimeMs() - startTimeMsRef.current;
        const isValid = data && isValidDataReturned(data);

        if (!isValid) {
          if (elapsedTimeMsRef.current <= maxTotalTime) {
            return pollingInterval;
          }
          setTimedOut(true);
        }

        setCompleted(true);
      }

      return false;
    };

    const triggerPolling = () => {
      startTimeMsRef.current = currentTimeMs();

      setEnabled(true);
    };

    const queryReturn = reactQueryFn({ ...queryParams, enabled, refetchInterval });

    return {
      ...queryReturn,
      triggerPolling,
      elapsedTime: elapsedTimeMsRef.current,
      completed,
      timedOut
    };
  };
