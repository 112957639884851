import { Box } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { legalDocsConfig } from "config/legalDocsConfig";
import { FC } from "react";
import { IProductDocuments } from "./ProductDocuments.interface";
import styles from "./ProductDocuments.module.scss";
import { ProductDocumentsItem } from "./ProductDocumentsItem";

export const ProductDocuments: FC<IProductDocuments> = ({ isISA, kpi, productName }) => {
  let productsList = Object.entries(strings.productDocuments);
  if (!isISA) productsList = productsList.slice(0, 3);
  return (
    <Box className={styles.productDocumentsItem}>
      {productsList.map(([key, val]) => {
        const { title } = val;
        let { content } = val;
        if (key === "keyProductInformation") content = content.replace("{{PRODUCT_NAME}}", productName);
        const link = key === "keyProductInformation" ? kpi : legalDocsConfig[key];
        if (!link) return null;

        return <ProductDocumentsItem key={key} link={link} title={title} content={content} />;
      })}
    </Box>
  );
};
