import { Card, Divider, Heading, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { MaturityInstructionType } from "services/data/types";
import { stringReplace } from "utils/replace";
import { InterestPreferencesGenericProps } from "./InterestPreferences.types";

const { interest, maturing: maturingAccountLabel, selected: selectedAccountLabel } = strings.reviewSubmission;
const { staticSavings, maturingText, frequency } = interest;

// Todo - uncomment when edit journey is implemented
// const { editButton } = strings.general;

const InterestPreferencesPartial = ({ interestFrequencyText }: InterestPreferencesGenericProps) => (
  <>
    <Divider align="center" />

    <Heading level={3}>{interest.partial.title}</Heading>
    <Space gap={8} />
    <Heading level={4}>{maturingAccountLabel}</Heading>
    <Space gap={2} />
    <Card variant="outlined" width="full">
      {maturingText}
    </Card>
    <Space gap={6} />
    <Heading level={4}>{selectedAccountLabel}</Heading>
    <Space gap={2} />
    <Card variant="outlined" width="full">
      {staticSavings}
    </Card>
    <Space gap={4} />
    <Card variant="outlined" width="full">
      {interestFrequencyText}
      {/* Todo - uncomment when edit journey is implemented */}
      {/* <Link to="/" className="underline"><Text color="secondary">{editButton}</Text></Link> */}
    </Card>
    <Divider align="center" />
  </>
);

const InterestPreferencesFull = ({ interestFrequencyText }: InterestPreferencesGenericProps) => (
  <>
    <Divider align="center" />
    <Heading level={3}>{interest.full.title}</Heading>
    <Space gap={4} />
    <Card variant="outlined" width="full">
      {staticSavings}
    </Card>
    <Space gap={4} />
    <Card variant="outlined" width="full">
      {interestFrequencyText}
      {/* Todo - uncomment when edit journey is implemented */}
      {/* <Link to="/" className="underline"><Text color="secondary">{editButton}</Text></Link> */}
    </Card>
    <Divider align="center" />
  </>
);

export const InterestPreferences = () => {
  const { reinvestmentType, reinvestmentProduct } = useMaturityInstructionContext();
  if (!reinvestmentType) return null;
  if ([MaturityInstructionType.COMPLEX, MaturityInstructionType.WITHDRAW].includes(reinvestmentType)) return null;

  const { interestRate: newProductInterestRate, interestFrequency: newProductInterestFrequency } =
    reinvestmentProduct || {};

  const interestFrequencyText = stringReplace(frequency, {
    "{{FREQUENCY}}": newProductInterestFrequency?.toLowerCase(),
    "{{RATE}}": newProductInterestRate
  });

  if (MaturityInstructionType.PARTIAL === reinvestmentType)
    return <InterestPreferencesPartial interestFrequencyText={interestFrequencyText} />;

  if ([MaturityInstructionType.REINVEST_EXISTING, MaturityInstructionType.REINVEST_NEW].includes(reinvestmentType)) {
    return <InterestPreferencesFull interestFrequencyText={interestFrequencyText} />;
  }
  return null;
};
