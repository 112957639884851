import { Box, Button, Card, Grid, Heading, RadioGroup, RadioItem, RightArrow } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import useScrollToTop from "hooks/useScrollToTop";
import { SecondaryHeader } from "../../../components/SecondaryHeader/SecondaryHeader";
import { getInitialValue } from "../utils";
import styles from "./FullPartialTransfer.module.scss";

export const FullPartialTransfer = () => {
  const navigate = useNavigate();
  const { headingText, questionText, options } = strings.newAccount.fullPartialTransfer;
  const { title } = strings.newAccount;
  const {
    state,
    state: { selectedProductSet, editMode, editSelection },
    setState,
    clearState
  } = useNewAccountContext();
  useScrollToTop();
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    getInitialValue(state, editMode, "fullTransfer") as string
  );
  const isContinueDisabled = selectedOption === undefined;

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    if (editMode) {
      setState({ editSelection: { ...editSelection, fullTransfer: selectedOption } });
    } else {
      setState({ fullTransfer: selectedOption });
    }
    navigate(links.taxYearWaypoint.link);
  };

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.isaDetails.link} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer} width="auto">
            <Box>
              <Heading level={2}>{headingText}</Heading>
            </Box>

            <Box>
              <RadioGroup
                orientation="vertical"
                labelText={{ main: questionText }}
                value={selectedOption}
                onValueChange={handleSelect}>
                {options.map(option => (
                  <RadioItem id={option.label} key={option.label} value={option.value} label={option.label} />
                ))}
              </RadioGroup>
            </Box>

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isContinueDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
