import strings from "assets/strings/strings.json";
import { PRODUCT_CATEGORY_KEYS } from "config/productCategoryKeys";
import { ProductCategory } from "services/data/types";
import { AccountCategoryStrings } from "./types";

const categories = strings.reinvestment.accounts.categories as AccountCategoryStrings;

export function getMaturityInstructionAccountCategoryStrings(category: ProductCategory) {
  return categories[PRODUCT_CATEGORY_KEYS[category]] || {};
}
