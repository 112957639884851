import { Container, Flex, Tag, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ITransactionHistoryItemProps } from "components/TransactionHistoryItem/interfaces";
import styles from "components/TransactionHistoryItem/TransactionHistoryItem.module.scss";
import inlineStyles from "components/TransactionHistoryItem/TransactionHistoryItem.style";
import { FC } from "react";
import { formatBalance } from "utils";

export const TransactionHistoryItem: FC<ITransactionHistoryItemProps> = ({
  date,
  details,
  tagText,
  status,
  isStrikedThrough,
  amount,
  isIncomingTransfer,
  reference,
  amountPrefix
}) => {
  const statusStr = status as keyof typeof inlineStyles.tag;
  const cx = {
    ...inlineStyles.container.padding,
    ...inlineStyles.container.margin
  };
  const amountCx = isIncomingTransfer ? inlineStyles.text.deposit : inlineStyles.text.withdrawal;
  const tagCx = {
    ...inlineStyles.tag[statusStr],
    ...inlineStyles.tagText
  };

  return (
    <Container data-testid="transaction-item" css={cx} space="0">
      {reference ? (
        <>
          {details && (
            <div
              className={`${styles["transaction-history-item__grid"]} ${styles["transaction-history-item__grid--two"]}`}>
              <Text as="span" color="grey" fontWeight="medium">
                {date}
              </Text>
              <Text as="span" color="grey" fontWeight="medium">
                {strings.accountInformation.referenceTitle}
              </Text>
            </div>
          )}
          {!details && (
            <div
              className={`${styles["transaction-history-item__grid"]} ${styles["transaction-history-item__grid--three"]}`}>
              <Text as="span" color="grey" fontWeight="medium">
                {date}
              </Text>
              <Text as="span" color="grey" fontWeight="medium">
                {strings.accountInformation.referenceTitle}
              </Text>
              <Text fontWeight="bold" as={isStrikedThrough ? "del" : "span"} css={amountCx}>
                {`${amountPrefix}${formatBalance(amount)}`}
              </Text>
            </div>
          )}
        </>
      ) : (
        <Flex justify="between">
          <Text as="span" color="grey" fontWeight="medium">
            {date}
          </Text>
          {!details && (
            <Text fontWeight="bold" as={isStrikedThrough ? "del" : "span"} css={amountCx}>
              {`${amountPrefix}${formatBalance(amount)}`}
            </Text>
          )}
        </Flex>
      )}
      {reference && details && (
        <div
          className={`${styles["transaction-history-item__grid"]} ${styles["transaction-history-item__grid--three"]}`}>
          <Text as="span" fontWeight="medium">
            {details}
          </Text>
          <Text as="span" fontWeight="medium">
            {reference}
          </Text>
          <Text
            as={isStrikedThrough ? "del" : "span"}
            fontWeight="bold"
            className={styles["transaction-history-item__text--right"]}
            css={amountCx}>
            {`${amountPrefix}${formatBalance(amount)}`}
          </Text>
        </div>
      )}
      {!reference && details && (
        <Flex justify="between">
          <Text as="span" fontWeight="medium">
            {details}
          </Text>
          <Text fontWeight="bold" as={isStrikedThrough ? "del" : "span"} css={amountCx}>
            {`${amountPrefix}${formatBalance(amount)}`}
          </Text>
        </Flex>
      )}
      {reference && !details && (
        <div
          className={`${styles["transaction-history-item__grid"]} ${styles["transaction-history-item__grid--three"]}`}>
          <Text as="span" fontWeight="medium" className={styles["transaction-history-item__referenceOnly"]}>
            {reference}
          </Text>
        </div>
      )}
      {tagText && (
        <Flex justify="start" className={styles["transaction-history-item__tag-container"]}>
          <Flex align="center">
            <Tag css={tagCx}>{tagText}</Tag>
          </Flex>
        </Flex>
      )}
    </Container>
  );
};
