import { Alert, Card, Heading, Icon, Space, Text } from "@shawbrook/sds-component-library";

import strings from "assets/strings/strings.json";
import { FormattedDate } from "components/FormattedDate";
import { Aside } from "components/MaturityInstruction/Aside";
import { InterestPreferences } from "components/MaturityInstruction/InterestPreferences/InterestPreferences";
import { MaturityInstructionAccountGrid } from "components/MaturityInstruction/MaturityInstructionAccountGrid";
import { MaturityInstructionProductDocuments } from "components/MaturityInstruction/MaturityInstructionProductDocuments/MaturityInstructionProductDocuments";
import { ReviewInstructionFooter } from "components/MaturityInstruction/ReviewInstructionFooter";
import { PageLayout } from "components/Page";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { useState } from "react";
import { MaturityInstructionType } from "services/data/types";
import { stringReplace } from "utils/replace";
import { ReviewInstructionProps } from "./ReviewInstruction.interface";

const { subtitle, reviewDescription, infoText } = strings.reviewSubmission;
const { title: pageTitle } = strings.reinvestment;

export const ReviewInstruction = ({ isEditing, isSubmitting }: ReviewInstructionProps) => {
  const { reinvestmentType, loading, maturingAccountData, investmentInstruction } = useMaturityInstructionContext();

  const [confirmationReceived, setConfirmationReceived] = useState(false);

  const { maturityDate, executionDate } = maturingAccountData?.maturity || {};

  const description = stringReplace(isSubmitting ? reviewDescription.edit : reviewDescription.view, {
    "{{DATE}}": <FormattedDate dateToFormat={maturityDate} />
  });

  const alertText = stringReplace(
    reinvestmentType === MaturityInstructionType.WITHDRAW ? infoText.withdrawFull : infoText.reinvest,
    {
      "{{DATE}}": <FormattedDate dateToFormat={executionDate} />
    }
  );

  return (
    <PageLayout title={pageTitle} loading={loading}>
      <PageLayout.Content>
        <Heading level={2}>{subtitle}</Heading>
        <Space gap={2} />
        <Text>{description}</Text>
        <Space gap={7} />

        <Card width="full" variant="white" radius="none">
          {executionDate && investmentInstruction?.id && (
            <Alert ariaLive="polite" icon={<Icon color="charcoal" name="info" />}>
              <Text>{alertText}</Text>
            </Alert>
          )}
          <MaturityInstructionAccountGrid isEditing={isEditing || isSubmitting} />
          <Space gap={7} />
          <InterestPreferences />
          <MaturityInstructionProductDocuments />

          <ReviewInstructionFooter
            isSubmitting={isSubmitting}
            confirmationReceived={confirmationReceived}
            setConfirmationReceived={setConfirmationReceived}
          />
        </Card>
      </PageLayout.Content>
      <PageLayout.Aside>
        <Aside />
      </PageLayout.Aside>
    </PageLayout>
  );
};
