import { Card, Flex, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { Link } from "react-router-dom";
import { AccountTag } from "../AccountTag";
import { ReviewAccountCardProps } from "./ReviewAccountCard.interface";
import styles from "./ReviewAccountCard.module.scss";

export const ReviewAccountCard = ({
  accountIssueNumber,
  accountName,
  title,
  subTitle,
  variant,
  editLink,
  label,
  labelWeight = "medium"
}: ReviewAccountCardProps) => {
  const { editButton } = strings.general;

  return (
    <Flex direction="column">
      {label && (
        <Space gap={2}>
          <Text fontWeight={labelWeight} as="span">
            {label}
          </Text>
        </Space>
      )}
      <Card variant={variant} width="full" className={styles.card}>
        <Flex direction="column" gap={1} align="start">
          {(accountIssueNumber || accountName) && (
            <AccountTag accountIssueNumber={accountIssueNumber} accountName={accountName} />
          )}
          {title && (
            <Heading as="h3" level="1" fontWeight="semiBold" className={styles.card__heading}>
              {title}
            </Heading>
          )}

          {subTitle && <Text>{subTitle}</Text>}
          {editLink && (
            <Link to="/" className="underline">
              <Text color="secondary">{editButton}</Text>
            </Link>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};
