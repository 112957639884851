import { Card, Loader, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PageLoaderProps } from "./types";

export const PageLoader = ({ showSpinner = true }: PageLoaderProps) => {
  const { loading } = strings.general;
  return (
    <Card width="full" variant="white" radius="none">
      <Space gap={20} />
      {showSpinner && <Loader showRing size="medium" spacing="medium" text={loading} textColor="primary" />}
      <Space gap={20} />
    </Card>
  );
};
