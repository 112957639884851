import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { MaturityInstructionType } from "services/data/types";
import { AccountGridFullWithdrawal } from "../AccountGridFullWithdrawal";
import { AccountGridPartial } from "../AccountGridPartial";
import { AccountGridFullReinvestment } from "../AccountGridReinvestment";
import { MaturityInstructionAccountGridProps } from "./MaturityInstructionAccountGridProps.interface";

export const MaturityInstructionAccountGrid = ({ isEditing }: MaturityInstructionAccountGridProps) => {
  const {
    reinvestmentType,
    amountToWithdraw: calculatedWithdrawalAmount,
    withdrawalInstruction
  } = useMaturityInstructionContext();

  const amountToWithdraw = isEditing ? calculatedWithdrawalAmount : withdrawalInstruction?.amount?.amount || 0;

  if (!reinvestmentType) return null;

  if (reinvestmentType === MaturityInstructionType.WITHDRAW) {
    return <AccountGridFullWithdrawal amountToWithdraw={amountToWithdraw} />;
  }

  if ([MaturityInstructionType.REINVEST_NEW, MaturityInstructionType.REINVEST_EXISTING].includes(reinvestmentType)) {
    return <AccountGridFullReinvestment />;
  }

  if (reinvestmentType === MaturityInstructionType.PARTIAL) {
    return <AccountGridPartial amountToWithdraw={amountToWithdraw} />;
  }
  return null;
};
