import { Button, Card, Flex, Heading, List, Loader, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import FailureModal from "components/FailureModal/FailureModal";
import LoadingSection from "components/LoadingSection/LoadingSection";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import SuccessModal from "components/SuccessModal";
import { contentConfig } from "config/contentConfig";
import { links } from "config/navLinks";
import { PRODUCT_CATEGORY_KEYS } from "config/productCategoryKeys";
import useAccount from "hooks/useAccount";
import { useCloseAccount } from "hooks/useCloseAccount/useCloseAccount";
import useScrollToTop from "hooks/useScrollToTop";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rreplace from "react-string-replace";
import { IAccountDetails, ProductCategory } from "services/data/types";
import { formatToDisplayDate, isProductEAFixedISA } from "utils";
import styles from "./CloseAccount.module.scss";

type CategoryPartialContent = {
  heading: string;
  content?: string[];
  description: string;
};

type CategoryContent = {
  content?: string[];
  description: string;
  part2?: CategoryPartialContent;
};

type ContentStrings = {
  [key: string]: CategoryContent;
};

const getCategoryStrings = (category?: ProductCategory) => {
  if (!category) return undefined;
  const closeAccountStrings = strings.closeAccount as unknown as ContentStrings;
  return closeAccountStrings[PRODUCT_CATEGORY_KEYS[category]];
};

interface ICloseAccountFixedRate {
  accountDetails: IAccountDetails;
  handleBackToHome: () => void;
}

const CloseAccountFixedRate: FC<ICloseAccountFixedRate> = ({ accountDetails, handleBackToHome }) => {
  const {
    general: { backToHomeButton: backToHome },
    closeAccount: {
      fixedRateBond: { heading, content }
    }
  } = strings;

  const formattedMaturityDate = formatToDisplayDate(accountDetails?.maturity?.maturityDate || "");

  return (
    <>
      <Space>
        <Heading level="2" as="h2">
          {heading.replace("{{ACCOUNT_TITLE}}", accountDetails?.product.title || "")}
        </Heading>
      </Space>
      <Space gap="10">
        <Text>{content.replace("{{MATURITY_DATE}}", formattedMaturityDate)}</Text>
      </Space>
      <Flex justify="end" gap="4" className={styles.buttons}>
        <Button type="button" size="large" onClick={handleBackToHome}>
          {backToHome}
        </Button>
      </Flex>
    </>
  );
};

export const CloseAccount = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.id || "";
  const { accountDetailData, accountResolved, isAccountDetailLoading } = useAccount({ accountId });

  const {
    title,
    heading,
    headingNotice,
    cancel,
    confirm,
    confirmNotice,
    message,
    messageFixedRateISA,
    messageNotice,
    subject,
    successModal: {
      noticeTitle,
      eaAndISATitle,
      fixedRateISAContent,
      noticeContent: noticeModalContent,
      eaAndISAContent
    },
    failureModal: { title: failureModalTitle, eaAndFixedContent, noticeContent }
  } = strings.closeAccount;
  const { backToHomeButton } = strings.general;

  const isFixedRateISA = accountDetailData?.product.category === ProductCategory.FIXED_RATE_ISA;
  const isNotice = accountDetailData?.product.category === ProductCategory.NOTICE;

  let messageContent = message;
  if (isFixedRateISA) {
    messageContent = messageFixedRateISA.replace(
      "{{EARLY_EXIT_CHARGE_DAYS}}",
      accountDetailData?.product?.earlyExitChargeDays?.toString() || ""
    );
  } else if (isNotice) {
    messageContent = messageNotice;
  }

  const threadSubject = subject.replace(
    "{{ACCOUNT_TITLE}}",
    `${accountDetailData?.product.title} ${strings.general.issueNumber} ${accountDetailData?.product.issueNumber}`
  );
  const { postThread, isSubmitThreadLoading, showFailureModal, showSuccessModal } = useCloseAccount({
    accountId,
    subject: threadSubject,
    messageContent
  });

  const accountDetailsLink = links.account.link.replace(":id", accountId);
  const noVerifiedLinkedAccLink = links.linkedAccountIssue.link.replace(":id", accountId);

  useEffect(() => {
    if (accountResolved && !accountDetailData) {
      navigate(accountDetailsLink);
    }
  }, [accountDetailData, accountDetailsLink, accountResolved, navigate]);

  const handleCloseAccount = () => {
    if (
      !accountDetailData?.linkedAccount ||
      !accountDetailData?.linkedAccount.accountNumber ||
      !accountDetailData?.linkedAccount?.verified
    ) {
      navigate(noVerifiedLinkedAccLink);
      return;
    }
    postThread();
  };

  const handleCancel = () => {
    navigate(accountDetailsLink);
  };

  const handleBackToHome = () => {
    navigate(links.landing.link);
  };

  const isLinkedAccount = accountDetailData?.linkedAccount && accountDetailData?.linkedAccount.accountNumber;
  const isLinkedAccountVerified = accountDetailData?.linkedAccount?.verified;
  // prevent users from manually entering this page and requesting acc closure
  // if there's a linked account issue
  useEffect(() => {
    if (accountResolved && (!isLinkedAccount || !isLinkedAccountVerified)) {
      navigate(accountDetailsLink);
    }
  }, [accountDetailsLink, accountResolved, isLinkedAccount, isLinkedAccountVerified, navigate]);

  if (accountResolved && !accountDetailData) {
    return null;
  }

  const categoryStrings = getCategoryStrings(accountDetailData?.product.category);

  const getFailureModalContent = () => {
    if (accountDetailData?.product.category === ProductCategory.NOTICE) {
      return noticeContent;
    }
    if (accountDetailData?.product.category === ProductCategory.FIXED_RATE_ISA) {
      return messageFixedRateISA;
    }
    if (accountDetailData && isProductEAFixedISA(accountDetailData?.product.category)) {
      return eaAndFixedContent;
    }
    return "";
  };

  const pageHeading = isNotice ? headingNotice : heading;
  const modalTitle = isNotice ? noticeTitle : eaAndISATitle;
  const nonModalContent = isFixedRateISA
    ? fixedRateISAContent.replace(
        "{{EARLY_EXIT_CHARGE_DAYS}}",
        accountDetailData?.product?.earlyExitChargeDays?.toString() || ""
      )
    : eaAndISAContent;
  const modalContent = isNotice ? noticeModalContent : nonModalContent;
  const confirmButtonTitle = isNotice ? confirmNotice : confirm;

  const formattedModalContent = rreplace(
    modalContent.replace("{{BANK_NAME}}", accountDetailData?.linkedAccount?.bankName || ""),
    "{{STATEMENTS_AND_DOCUMENTS}}",
    () => (
      <Text key={strings.documents.title} as="span" font="silka" fontWeight="medium" css={{ fontSize: "1.5rem" }}>
        {strings.documents.title}
      </Text>
    )
  );

  return (
    <div className={styles.page}>
      <SuccessModal
        open={showSuccessModal}
        url={links.landing.link}
        content={formattedModalContent}
        header={modalTitle.replace("{{ACCOUNT_TITLE}}", accountDetailData?.product.title || "")}
        btnContent={backToHomeButton}
        headerMarginBottom="$6"
        biggerFont
      />
      <FailureModal
        header={failureModalTitle}
        content={getFailureModalContent()}
        btnContent={strings.general.sendMessage}
        open={showFailureModal}
        url={links.inbox.link}
      />
      <SecondaryHeader title={title} backLink={accountDetailsLink} />
      <section className={styles.page__content}>
        <section>
          <Card width="full" variant="white" className={styles.page__content__card}>
            {isAccountDetailLoading && <LoadingSection />}
            {!isAccountDetailLoading &&
              (accountDetailData?.product.category !== ProductCategory.FIXED_RATE ? (
                <>
                  <Space>
                    <Heading level="2" as="h2">
                      {pageHeading.replace("{{ACCOUNT_TITLE}}", accountDetailData?.product.title || "")}
                    </Heading>
                  </Space>
                  {categoryStrings?.content && (
                    <Space>
                      <List>
                        {categoryStrings.content.map(el => (
                          <List.Item key={el}>
                            {el.replace("{{BANK_NAME}}", accountDetailData?.linkedAccount?.bankName || "")}
                          </List.Item>
                        ))}
                      </List>
                    </Space>
                  )}
                  <Space gap={categoryStrings?.part2 ? "8" : "10"}>
                    <Text>
                      {categoryStrings?.description
                        .replace("{{ACCESS_PERIOD_AFTER_CLOSURE}}", contentConfig.accessPeriodAfterClosure.toString())
                        .replace(
                          "{{EARLY_EXIT_CHARGE_DAYS}}",
                          accountDetailData?.product?.earlyExitChargeDays?.toString() || ""
                        )}
                    </Text>
                  </Space>

                  {categoryStrings?.part2 && (
                    <>
                      <Space gap="2">
                        <Heading level="3" as="h3">
                          {categoryStrings.part2.heading}
                        </Heading>
                      </Space>
                      {categoryStrings.part2.content && (
                        <List>
                          {categoryStrings.part2.content.map(el => (
                            <List.Item key={el}>
                              {el.replace("{{BANK_NAME}}", accountDetailData?.linkedAccount?.bankName || "")}
                            </List.Item>
                          ))}
                        </List>
                      )}
                      <Space gap="10">
                        <Text>
                          {categoryStrings.part2.description.replace(
                            "{{ACCESS_PERIOD_AFTER_CLOSURE}}",
                            contentConfig.accessPeriodAfterClosure.toString()
                          )}
                        </Text>
                      </Space>
                    </>
                  )}
                  <Flex justify="between" gap="4" className={styles.buttons}>
                    <Button
                      className={styles.buttons__cancelButton}
                      variant="tertiary"
                      type="button"
                      size="large"
                      onClick={handleCancel}>
                      {cancel}
                    </Button>
                    <Button
                      loading={isSubmitThreadLoading}
                      loader={<Loader css={{ width: "1.25rem", height: "1.25rem" }} />}
                      type="button"
                      size="large"
                      onClick={handleCloseAccount}>
                      {confirmButtonTitle}
                    </Button>
                  </Flex>
                </>
              ) : (
                <CloseAccountFixedRate accountDetails={accountDetailData} handleBackToHome={handleBackToHome} />
              ))}
          </Card>
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
