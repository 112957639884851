import { Flex, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { TextInput } from "journeys/components";
import { PageHeader } from "journeys/components/";
import useScrollToTop from "hooks/useScrollToTop";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";
import { useFormContext } from "react-hook-form";
import { CUSTOMER_EMAIL, ComponentState, IScreenProps } from "services/data/types/bankfastUserMigration/";
import { useSendOTPProcess } from "../../../hooks/useSendOTPProcess/useSendOTPProcess";

export const Email = ({
  flowId,
  setPostResult,
  currentStepIndex,
  setCurrentState,
  currentState = ComponentState.INITIAL
}: IScreenProps) => {
  useScrollToTop();

  const {
    email: {
      description,
      description2,
      title,
      email: { label }
    }
  } = strings.bankfastUserMigration;
  const {
    formState: { errors }
  } = useFormContext();

  useSendOTPProcess({
    otpType: "email",
    flowId,
    setPostResult,
    currentStepIndex,
    setCurrentState,
    currentState
  });

  if (getBlockServerErrorStatus(errors)) {
    return (
      <>
        <PageHeader title={title} />
        <ApiAlerts errors={errors} />
      </>
    );
  }

  return (
    <Flex gap="6" direction="column">
      <PageHeader title={title}>
        {description && <Text>{description}</Text>}
        {description2 && <Text>{description2}</Text>}
      </PageHeader>

      <TextInput
        id="emailAddress"
        label={label}
        fieldName={CUSTOMER_EMAIL}
        fieldSize="full"
        autoFocus
        autoComplete="email"
        type="email"
      />
    </Flex>
  );
};

export default Email;
