import { Flex, Heading, Space, Text } from "@shawbrook/sds-component-library";
import classNames from "classnames";
import styles from "./AvailableProductCard.module.scss";
import { ProductCardColumnProps, ProductCardHeaderProps, ProductCardProps } from "./AvailableProductCard.types";

const AvailableProductCard = ({ children }: ProductCardProps) => <div>{children}</div>;

const ProductCardHeader = ({ title, children }: ProductCardHeaderProps) => (
  <Flex className={styles.productCard__header} direction="column" gap={2}>
    {title ? <Heading level={4}>{title}</Heading> : <span />}
    <div>{children}</div>
  </Flex>
);

const ProductCardColumn = ({ title, children, className, ...props }: ProductCardColumnProps) => {
  const cx = classNames(className, styles.productCard__column);
  return (
    <Flex {...props} className={cx}>
      {title && (
        <>
          <Text color="grey" as="span">
            {title}
          </Text>
          <Space gap={3} />
        </>
      )}
      {children}
    </Flex>
  );
};

AvailableProductCard.Header = ProductCardHeader;
AvailableProductCard.Column = ProductCardColumn;
export { AvailableProductCard };
