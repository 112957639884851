import strings from "assets/strings/strings.json";
import { MaturityInstructionType } from "services/data/types";

const { reinvest, withdrawl, generic } = strings.card.maturityInstructionBanner;

export const MATURITY_INSTRUCTION_TYPE_TO_BANNER_MAPPING: Record<MaturityInstructionType, string> = {
  [MaturityInstructionType.REINVEST_NEW]: reinvest,
  [MaturityInstructionType.REINVEST_EXISTING]: reinvest,
  [MaturityInstructionType.PARTIAL]: generic,
  [MaturityInstructionType.COMPLEX]: generic,
  [MaturityInstructionType.WITHDRAW]: withdrawl
};
