import { getAccountInformationFromId } from "services/data/api";
import { IAccountSummary, QueryKeys } from "services/data/types";
import { commonUseQuery, pollForUpdatedData } from "utils/pollForUpdatedData";
import { IUsePollNewAccountStatusProps } from "./interface";

const usePollNewAccountStatus = ({
  accountId,
  isValidDataReturned,
  maxTotalTime,
  pollingInterval
}: IUsePollNewAccountStatusProps<IAccountSummary>) =>
  pollForUpdatedData<IAccountSummary>(
    commonUseQuery({
      queryKey: [QueryKeys.ACCOUNT_DETAIL, accountId],
      queryFn: () => getAccountInformationFromId(accountId)
    })
  )({ isValidDataReturned, maxTotalTime, pollingInterval })({});

export { usePollNewAccountStatus };
