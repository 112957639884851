import { Divider } from "@shawbrook/sds-component-library";
import { ProductDocuments } from "components/ProductDocuments/ProductDocuments";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import { isISAAccountCategory } from "utils";
import { createDisplayName } from "utils/createDisplayName";

export const MaturityInstructionProductDocuments = () => {
  const { reinvestmentProduct } = useMaturityInstructionContext();

  const {
    title: newProductTitle,
    issueNumber: newProductIssueNumber,
    kpiLink: newProductKpiLink,
    category: newProductCategory
  } = reinvestmentProduct || {};

  if (!newProductTitle) return null;

  const isIsa = !!newProductCategory && isISAAccountCategory(newProductCategory);

  const newProductDisplayName = createDisplayName({
    title: newProductTitle || "",
    issueNumber: newProductIssueNumber
  });

  return (
    <>
      <ProductDocuments isISA={isIsa} kpi={newProductKpiLink} productName={newProductDisplayName} />
      <Divider align="center" />
    </>
  );
};
