import { Box, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PRODUCT_CARD_SECTION, ProductCard } from "components/ProductCard/ProductCard";
import { links } from "config/navLinks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ProductCategory } from "services/data/types/entities";
import { useNewAccountContext } from "state/newAccountState";
import { INTEREST_FREQUENCY } from "state/reinvestmentState";
import { findInterestRate } from "utils";
import { FormattedAvailableProduct } from "utils/formatAvailableProducts/interfaces";
import styles from "./CompareAccountsCategory.module.scss";

interface ICompareAccountsCategoryProps {
  categoryProducts: FormattedAvailableProduct[];
  category: string;
}

type TitleDescription = {
  title: string;
  description: string[];
  interestRateText: string;
};

const CATEGORY_MAP: Readonly<Record<string, string>> = Object.freeze({
  [ProductCategory.EASY_ACCESS]: strings.newAccount.compareAccounts.categories.easyAccess.title,
  [ProductCategory.EASY_ACCESS_ISA]: strings.newAccount.compareAccounts.categories.easyAccessISA.title,
  [ProductCategory.NOTICE]: strings.newAccount.compareAccounts.categories.notice.title,
  [ProductCategory.FIXED_RATE]: strings.newAccount.compareAccounts.categories.fixedRate.title,
  [ProductCategory.FIXED_RATE_ISA]: strings.newAccount.compareAccounts.categories.fixedRateISA.title
});

export const findCategoryInterestRateText = (category: string) => {
  const categoriesStrings = strings.newAccount.compareAccounts.categories;
  const { interestRateText } = Object.values(categoriesStrings).find(
    el => el.title === CATEGORY_MAP[category]
  ) as TitleDescription;

  return interestRateText;
};

export const CompareAccountsCategory: FC<ICompareAccountsCategoryProps> = ({ categoryProducts, category }) => {
  const navigate = useNavigate();
  const interestRateText = findCategoryInterestRateText(category);
  const { setState } = useNewAccountContext();

  const handleProductSelection = (product: FormattedAvailableProduct) => {
    setState({ selectedProductSet: product, selectedProductId: undefined });
    navigate(links.newAccountDetails.link);
  };

  return (
    <Box className={styles.productsList}>
      {categoryProducts.map((product: FormattedAvailableProduct) => (
        <Space key={`${product.title}-${product.products[0].id}`} gap="5">
          <ProductCard
            displayName={product.title || ""}
            variableProductType={product.category !== ProductCategory.FIXED_RATE}
            interestRateAnnual={findInterestRate(product.products, INTEREST_FREQUENCY.annually)}
            interestRateMonthly={findInterestRate(product.products, INTEREST_FREQUENCY.monthly)}
            maxInterestRate={product.maxInterestRate}
            maxBalanceSole={product.soleMaxBalance}
            maxBalanceJoint={product.category === ProductCategory.EASY_ACCESS ? product.jointMaxBalance : null}
            pdf={product.kpiLink}
            onSelect={() => handleProductSelection(product)}
            interestRateText={interestRateText}
            section={PRODUCT_CARD_SECTION.NEW_ACCOUNT}
          />
        </Space>
      ))}
    </Box>
  );
};
