import { Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { formatToDisplayBalance } from "utils";
import { ReviewAccountCard } from "../ReviewAccountCard";
import { AccountGridFullWithdrawalProps } from "./AccountGridFullWithdrawal.interface";

const { amountToWithdraw: amountToWithdrawLabel } = strings.reviewSubmission;

export const AccountGridFullWithdrawal = ({ amountToWithdraw }: AccountGridFullWithdrawalProps) => (
  <>
    <ReviewAccountCard
      variant="outlined-pink"
      title={formatToDisplayBalance(amountToWithdraw)}
      label={amountToWithdrawLabel}
      labelWeight="bold"
    />
    <Space gap={8} />
  </>
);
