import {
  Button,
  Card,
  Flex,
  RadioGroup,
  RadioItem,
  RightArrow,
  Space,
  Tooltip
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import useScrollToTop from "hooks/useScrollToTop";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InterestFrequency, InterestPaymentOption } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import convertToTitleCase from "utils/convertToTitleCase";
import { generateInterestFrequencyLabel } from "utils/generateInterestFrequencyLabel/generateInterestFrequencyLabel";
import styles from "./InterestOptions.module.scss";
import { inlineStyles as interestOptionStyles } from "./InterestOptions.styles";

export const InterestOptions = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const {
    state: {
      selectedProductSet,
      interestPaymentOption: interestPaymentOptionFromState,
      selectedProductId: selectedProductIdFromState,
      selectedFrequency,
      editMode,
      linkedAccount
    },
    setState,
    clearState
  } = useNewAccountContext();
  const [interestPaymentOption, setInterestPaymentOption] = useState<InterestPaymentOption | undefined>(
    interestPaymentOptionFromState
  );
  const initialProductId =
    selectedProductIdFromState ||
    selectedProductSet?.products.find(el => el.interestFrequency === selectedFrequency)?.id;
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(initialProductId);
  const previousComponent =
    typeof linkedAccount?.bankName !== "undefined"
      ? links.newAccountLinkedAccount.link
      : links.newAccountLinkedAccountForm.link;

  const { title } = strings.newAccount;
  const { paymentOptionsTitle, paymentOptions, tooltip, interestFrequencyTitle, interestFrequencyLabel } =
    strings.newAccount.interestOptions;
  const { reviewButton } = strings.general;
  const products = selectedProductSet?.products;

  useEffect(() => {
    if (!selectedProductSet || !products) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet, products]);

  const handleReview = () => {
    setState({
      selectedProductId,
      interestPaymentOption,
      selectedFrequency: selectedProductSet?.products.find(product => product.id === selectedProductId)
        ?.interestFrequency as InterestFrequency
    });

    navigate(links.newAccountSummary.link);
  };

  const handleInterestPaymentOptionChange = (e: InterestPaymentOption) => {
    setInterestPaymentOption(e);
  };

  const handleSelectedProductChange = (e: string) => {
    setSelectedProductId(e);
  };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={previousComponent} isEdit={editMode} />

      <section className={styles.page__content}>
        <section>
          <Card variant="raised" className={styles.page__content__card}>
            <Space gap="10" className={styles.page__content__radioGroup}>
              <RadioGroup
                labelText={{
                  main: paymentOptionsTitle
                }}
                onValueChange={handleInterestPaymentOptionChange}
                value={interestPaymentOption}
                ariaLabel="choose interest payment option"
                className={styles.page__content__radioGroup__options}
                orientation="vertical">
                {Object.entries(paymentOptions).map(([optionValue, { helpText, label }]) => (
                  <RadioItem
                    key={optionValue}
                    value={optionValue === "savings" ? InterestPaymentOption.SAVINGS : InterestPaymentOption.LINKED}
                    label={label}
                    subLabel={[helpText]}
                    id={optionValue}
                    aria-label={label}
                    className={styles.page__content__radioGroup__item}
                  />
                ))}
              </RadioGroup>
            </Space>
            <Space gap="10" className={styles.page__content__radioGroup}>
              <RadioGroup
                labelText={{
                  main: interestFrequencyTitle
                }}
                onValueChange={handleSelectedProductChange}
                value={selectedProductId}
                className={styles.page__content__radioGroup__options}
                ariaLabel="choose interest frequency"
                id="interest-frequency"
                toolTip={
                  <Tooltip alignOffset={0} avoidCollisions side="bottom" sideOffset={8} text={tooltip} variant="dark" />
                }
                orientation="vertical">
                {products
                  ?.sort((a, b) => (a.interestFrequency > b.interestFrequency ? 1 : -1))
                  ?.map(({ displayName, id, interestFrequency, interestRate, category }) => (
                    <RadioItem
                      key={id}
                      value={id}
                      label={generateInterestFrequencyLabel(
                        interestFrequencyLabel,
                        convertToTitleCase(interestFrequency),
                        interestRate,
                        category,
                        selectedProductSet?.maxInterestRate || 0
                      )}
                      id={id}
                      aria-label={displayName}
                      className={styles.page__content__radioGroup__item}
                    />
                  ))}
              </RadioGroup>
            </Space>
            <Flex justify="end" css={interestOptionStyles.continueButton}>
              <Button
                iconRight
                type="button"
                size="large"
                disabled={!interestPaymentOption || !selectedProductId}
                onClick={handleReview}>
                {reviewButton}
                <RightArrow width="24" height="24" />
              </Button>
            </Flex>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            issueNumber={selectedProductSet?.issueNumber || 0}
            productName={selectedProductSet?.title || ""}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
