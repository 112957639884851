import {
  Box,
  Button,
  Card,
  Grid,
  Heading,
  RadioGroup,
  RadioItem,
  RightArrow,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import useScrollToTop from "hooks/useScrollToTop";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import { SecondaryHeader } from "../../../components/SecondaryHeader/SecondaryHeader";
import { getInitialValue } from "../utils";
import styles from "./ISATransferIn.module.scss";

export const ISATransferIn = () => {
  const navigate = useNavigate();
  const { headingText, secondaryText, questionText, options } = strings.newAccount.ISATransfer;
  const { title } = strings.newAccount;
  const {
    state,
    state: { selectedProductSet, transferISASavingsToCurrentAccount, editMode },
    setState,
    clearState
  } = useNewAccountContext();
  useScrollToTop();
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    getInitialValue(state, editMode, "transferISASavingsToCurrentAccount") as string
  );

  const isContinueDisabled = selectedOption === undefined;

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    if (editMode) {
      if (selectedOption === options[1].value) {
        setState({
          transferISASavingsToCurrentAccount: selectedOption,
          isaDetails: undefined,
          fullTransfer: undefined,
          toppedUpAccountInCY: undefined,
          transferAmount: undefined,
          noticePeriod: undefined,
          editSelection: undefined
        });
      } else {
        setState({ editSelection: { transferISASavingsToCurrentAccount: selectedOption } });
      }
    } else {
      setState({ transferISASavingsToCurrentAccount: selectedOption });
    }
    const nextPage = selectedOption === options[0].value ? links.signpost.link : links.nationalInsurance.link;
    navigate(editMode && selectedOption === options[1].value ? links.newAccountSummary.link : nextPage);
  };

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className={styles.page}>
      {/* Because of the signposting page the edit logic is a bit different in this page. */}
      <SecondaryHeader
        title={title}
        backLink={editMode ? links.newAccountSummary.link : links.newAccountType.link}
        isEdit={editMode && transferISASavingsToCurrentAccount === options[1].value}
      />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer}>
            <Box>
              <Heading level={2}>{headingText}</Heading>
              <Text fontWeight="medium">{secondaryText}</Text>
            </Box>

            <Space gap="9">
              <Box>
                <RadioGroup
                  orientation="vertical"
                  labelText={{ main: questionText }}
                  value={selectedOption}
                  onValueChange={handleSelect}>
                  {options.map(option => (
                    <RadioItem id={option.label} key={option.label} value={option.value} label={option.label} />
                  ))}
                </RadioGroup>
              </Box>
            </Space>

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isContinueDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
