import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Heading,
  RightArrow,
  Space,
  Tag,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ProductDocuments } from "components/ProductDocuments/ProductDocuments";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import WarningModal from "components/WarningModal/WarningModal";
import { links } from "config/navLinks";
import { newAccountConfig } from "config/newAccountConfig";
import useAccount from "hooks/useAccount";
import useCustomer from "hooks/useCustomer";
import { usePollNewAccountStatus } from "hooks/usePollNewAccountStatus";
import useScrollToTop from "hooks/useScrollToTop";
import useSubmitNewAccount from "hooks/useSubmitNewAccount";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccountStatus,
  INewAccountPayload,
  InterestPaymentOption,
  LinkedAccountUnverifiedReason,
  ProductCategory
} from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import { CheckedState, PostSubmitPopupType } from "types/types";
import { formatBalance, formatSortCode, isISAAccountCategory } from "utils";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { generateInterestFrequencyLabel } from "utils/generateInterestFrequencyLabel/generateInterestFrequencyLabel";
import { getCustomerId } from "utils/getCustomerId/getCustomerId";

import { PostSubmitPopup, PostSubmitPopupProps } from "../PostSubmit/PostSubmitPopup";
import { productCategorySupportsJointAccounts } from "../utils";
import { ISATransferInfo } from "./ISATransferInfo/ISATransferInfo";
import styles from "./Summary.module.scss";

const enum EditModalVariant {
  PRODUCT = "product",
  TYPE = "type"
}

const enum PostSubmitStates {
  IDLE = "idle",
  WAITING = "waiting",
  ERROR = "error",
  FETCHING_ACCOUNT_DETAIL = "fetching",
  SHOW_FUNDING_MODAL = "funding"
}

export const NewAccountSummary = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const {
    state: {
      accountType,
      annualSavings,
      interestPaymentOption,
      linkedAccount,
      selectedFrequency,
      selectedProductId,
      selectedProductSet,
      niNumber,
      transferISASavingsToCurrentAccount,
      fullTransfer,
      isaDetails,
      toppedUpAccountInCY,
      noticePeriod,
      transferAmount
    },
    setState,
    clearState
  } = useNewAccountContext();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [editModal, setEditModal] = useState<{
    showModal: boolean;
    modalVariant: undefined | null | EditModalVariant;
  }>({ showModal: false, modalVariant: undefined });
  const [confirmChecked, setConfirmChecked] = useState(false);
  const { accountResolved, accountsData } = useAccount();
  const [newAccountPosted, setNewAccountPosted] = useState<boolean>(false);
  const [postSubmitPopupState, setPostSubmitPopupState] = useState<PostSubmitStates>(PostSubmitStates.IDLE);

  const newAccountId = useRef<string | null>(null);
  const {
    data: newAccountDetailData,
    isError: isNewAccountDetailError,
    completed: newAccountFetchCompleted,
    triggerPolling: fetchNewAccountDetails
  } = usePollNewAccountStatus({
    accountId: newAccountId.current,
    maxTotalTime: 90000,
    isValidDataReturned: (data: any) =>
      data &&
      data.status &&
      !(
        data.status === AccountStatus.PENDING ||
        (data.linkedAccount &&
          (data.linkedAccount.verified === false || data.linkedAccount.verified === undefined) &&
          data.linkedAccount.unverifiedReason === LinkedAccountUnverifiedReason.BANK_CHECK_IN_PROGRESS)
      )
  });

  const hasEA =
    (newAccountDetailData &&
      accountResolved &&
      accountsData &&
      accountsData.some(
        account =>
          account.product.category === ProductCategory.EASY_ACCESS &&
          account.accountId !== newAccountDetailData.accountId
      )) ||
    false;

  const customerId = getCustomerId();

  const payload: INewAccountPayload = {
    accountType: accountType || "",
    initialDeposit: {
      estimatedDepositAmount: annualSavings?.annualDeposit || 0,
      sourceOfDeposit: annualSavings?.sourceOfDeposit || ""
    },
    interest: {
      interestFrequency: selectedFrequency || "",
      interestPaymentMethod: interestPaymentOption || ""
    },
    ...(transferISASavingsToCurrentAccount === "Yes" && {
      isaTransfer: {
        sourceAccount: {
          sortCode: isaDetails?.sortCode.replaceAll("-", "") || "",
          accountNumber: isaDetails?.accountNumber || "",
          rollNumber: isaDetails?.buildingSocietyRollNumber || ""
        },
        investmentType: isaDetails?.investmentType || "",
        provider: {
          name:
            (isaDetails?.isaProvider.companyName === "Other" && isaDetails?.isaProviderNameIfOther) ||
            isaDetails?.isaProvider.companyName ||
            ""
        },
        fullBalance: fullTransfer === "Yes",
        hasCurrentYearSubscriptions: toppedUpAccountInCY === "Yes",

        ...(fullTransfer === "Yes"
          ? {
              transferAmount: null,
              hasFundsFromPreviousTaxYears: transferAmount?.isaContainsSavingFromPY === "Yes",
              ...(toppedUpAccountInCY === "Yes"
                ? {
                    subscribedAmount: {
                      amount: transferAmount?.amountPaidToISA || 0,
                      currency: "GBP"
                    }
                  }
                : {
                    subscribedAmount: null
                  })
            }
          : {
              hasFundsFromPreviousTaxYears: null,
              ...(transferAmount?.transferMoreMoney === "Yes"
                ? {
                    transferAmount: {
                      amount: transferAmount?.additionalAmount || 0,
                      currency: "GBP"
                    }
                  }
                : {
                    transferAmount: null
                  }),
              subscribedAmount: {
                amount: transferAmount?.amountPaidToISA || 0,
                currency: "GBP"
              }
            }),

        hasNoticePeriod: noticePeriod?.hasNoticePeriod === "Yes",
        immediateTransfer: noticePeriod?.hasNoticePeriod === "No" ? null : noticePeriod?.startTransfer === "immediately"
      }
    }),
    linkedAccount: {
      accountNumber: linkedAccount?.accountNumber || "",
      sortCode: linkedAccount?.sortCode || "",
      bankName: linkedAccount?.bankName || "",
      holderName: linkedAccount?.holderName || ""
    },
    product: {
      id: selectedProductId || ""
    },
    ...(niNumber && {
      associatedCustomers: [
        {
          personalDetails: {
            nationalInsuranceNumber: {
              number: niNumber?.replaceAll(" ", "").toUpperCase()
            }
          },
          id: customerId || "",
          relationship: newAccountConfig.defaultCustomerRelationship
        }
      ]
    })
  };

  const {
    isSubmitNewAccountError,
    isSubmitNewAccountLoading,
    isSubmitNewAccountSuccess,
    postNewAccount,
    submitNewAccountData
  } = useSubmitNewAccount(payload);
  const { customerData } = useCustomer();
  const {
    title,
    editModal: {
      editProduct: { title: editProductTitle, description: editProductDescription },
      editType: { title: editTypeTitle, description: editTypeDescription, accept, decline }
    }
  } = strings.newAccount;
  const { aer } = strings.general;
  const {
    title: summaryTitle,
    description,
    selectedAccount,
    accountType: { title: accountTypeTitle, single, joint },
    deposit: { title: depositTitle, estimatedDeposit, depositSource },
    linkedAccount: linkedAccountTitle,
    niNumber: niNumberTitle,
    interestPreferences: { title: interestPreferencesTitle, savings, linked, interestFrequencyLabel },
    checkBoxContent,
    cancelModal
  } = strings.newAccount.summary;
  const { submitBtn, cancelBtn } = strings.general;

  const product = selectedProductId && selectedProductSet?.products.find(el => el.id === selectedProductId);

  const isISA = product ? isISAAccountCategory(product.category) : false;

  let editModalTitle = "";
  let editModalDescription = "";
  let acceptModal: undefined | string;
  let declineModal: undefined | string;
  let resetJourneyLink = links.compareAccounts.link;

  switch (editModal.modalVariant) {
    case EditModalVariant.PRODUCT:
      editModalTitle = editProductTitle;
      editModalDescription = editProductDescription;
      break;
    case EditModalVariant.TYPE:
      editModalTitle = editTypeTitle;
      editModalDescription = formatTextWithPhoneHours(editTypeDescription);
      resetJourneyLink = links.inbox.link;
      acceptModal = accept;
      declineModal = decline;
      break;
    default:
      break;
  }

  const confirmationCheckboxHandler = (checked: CheckedState) => {
    if (typeof checked === "boolean") {
      setConfirmChecked(checked);
    }
  };

  const validateModel = () => {
    let isValid = false;
    if (
      !_.isEmpty(accountType) &&
      annualSavings &&
      annualSavings?.annualDeposit > 0 &&
      !_.isEmpty(annualSavings?.sourceOfDeposit) &&
      !_.isEmpty(interestPaymentOption) &&
      linkedAccount &&
      !_.isEmpty(linkedAccount.accountNumber) &&
      !_.isEmpty(linkedAccount?.holderName) &&
      !_.isEmpty(linkedAccount?.sortCode) &&
      !_.isEmpty(selectedFrequency) &&
      !_.isEmpty(selectedProductId) &&
      selectedProductSet
    ) {
      isValid = true;
    }
    return isValid;
  };

  const hasFailedLinkedAccountVerification =
    newAccountDetailData &&
    newAccountDetailData.linkedAccount?.verified === false &&
    newAccountDetailData.linkedAccount?.unverifiedReason === LinkedAccountUnverifiedReason.BANK_CHECK_FAIL;

  const isManual = isaDetails?.isaProvider.companyName === "Other" || isaDetails?.investmentType === "stocksAndShares";

  let postSubmitPopupType: PostSubmitPopupType = "waiting";
  if (newAccountPosted) {
    // We will not bother with the states unless a new account has been posted.
    switch (postSubmitPopupState) {
      case PostSubmitStates.IDLE:
        // Set state to waiting, when we get a loading indicator
        if (isSubmitNewAccountLoading) {
          setPostSubmitPopupState(PostSubmitStates.WAITING);
        }
        postSubmitPopupType = "waiting";
        break;
      case PostSubmitStates.WAITING:
        // Check if our mutation has completed or errored and set state accordingly
        if (isSubmitNewAccountSuccess) {
          // For now setting state to funding action to show ui
          newAccountId.current = submitNewAccountData;
          setPostSubmitPopupState(PostSubmitStates.FETCHING_ACCOUNT_DETAIL);
          fetchNewAccountDetails();
        } else if (isSubmitNewAccountError) {
          setPostSubmitPopupState(PostSubmitStates.ERROR);
        }
        // if it is not a success or error, then the data is still loading and we do nothing
        postSubmitPopupType = "waiting";
        break;
      case PostSubmitStates.ERROR:
        // Do nothing, as we are in error state and we will show the error popup next up
        postSubmitPopupType = "error";
        break;
      case PostSubmitStates.FETCHING_ACCOUNT_DETAIL:
        postSubmitPopupType = "waiting";
        // Check if data has loaded and show funding modal
        if (isNewAccountDetailError) {
          setPostSubmitPopupState(PostSubmitStates.ERROR);
          postSubmitPopupType = "error";
        }

        if (newAccountFetchCompleted) {
          if (
            !newAccountDetailData ||
            !(
              newAccountDetailData?.status === AccountStatus.UNFUNDED ||
              newAccountDetailData?.status === AccountStatus.OPEN
            )
          ) {
            setPostSubmitPopupState(PostSubmitStates.ERROR);
            postSubmitPopupType = "error";
          } else {
            setPostSubmitPopupState(PostSubmitStates.SHOW_FUNDING_MODAL);
            if (transferISASavingsToCurrentAccount === "Yes") {
              postSubmitPopupType = isManual ? "showManualModal" : "showElectronicModal";
            } else {
              postSubmitPopupType = hasFailedLinkedAccountVerification ? "showNearlyThereModal" : "showFundingModal";
            }
          }
        }

        break;
      case PostSubmitStates.SHOW_FUNDING_MODAL:
        // Do nothing as we will show the funding modal when in this state
        if (transferISASavingsToCurrentAccount === "Yes") {
          postSubmitPopupType = isManual ? "showManualModal" : "showElectronicModal";
        } else {
          postSubmitPopupType = hasFailedLinkedAccountVerification ? "showNearlyThereModal" : "showFundingModal";
        }

        break;
      default:
        // this will never be called, so reset to ERROR in case of an unknown state
        postSubmitPopupType = "error";
        setPostSubmitPopupState(PostSubmitStates.ERROR);
        break;
    }
  }

  const showPostSubmitPopup = postSubmitPopupState !== PostSubmitStates.IDLE;

  const postSubmitPopupProps: PostSubmitPopupProps = {
    type: postSubmitPopupType,
    name: customerData?.name?.givenNames ? customerData.name.givenNames[0] : "",
    linkedAccountNumber: newAccountDetailData?.linkedAccount?.accountNumber || "",
    newAccountId: newAccountDetailData?.accountId || "",
    accountDetails: {
      name: customerData?.name ? `${customerData.name.givenNames?.join(" ")} ${customerData.name.familyName!}` : "",
      accountNumber: newAccountDetailData?.accountNumber || "",
      sortCode: newAccountDetailData?.sortCode || ""
    },
    hasEa: hasEA,
    isVerified: !hasFailedLinkedAccountVerification
  };

  const handleSubmit = () => {
    postNewAccount();
    setNewAccountPosted(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleResetState = useCallback(() => {
    setState({
      accountType: undefined,
      annualSavings: {
        annualDeposit: 0,
        sourceOfDeposit: ""
      },
      interestPaymentOption: undefined,
      linkedAccount: {
        id: "",
        accountNumber: "",
        bankName: "",
        holderName: "",
        sortCode: "",
        verified: false
      },
      isaDetails: undefined,
      selectedFrequency: undefined,
      selectedProductId: "",
      selectedProductSet: undefined
    });
  }, [setState]);

  const handleCancel = () => {
    setShowCancelModal(false);
    handleResetState();
    navigate(links.landing.link);
  };

  useEffect(() => {
    if (!selectedProductSet || !product) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet, product]);

  useEffect(() => {
    setState({ editMode: false });
  }, [setState]);

  const handleCloseWarningModal = () => {
    setEditModal({ showModal: false, modalVariant: null });
  };

  const handleEdit = (link: string) => {
    setState({ editMode: true });
    navigate(link);
  };

  const handleResetJourney = () => {
    clearState();
    navigate(resetJourneyLink, {
      state: editModal.modalVariant === EditModalVariant.TYPE ? { allowBackNavigation: false } : {}
    });
  };

  return (
    <div className={styles.page}>
      {newAccountPosted && showPostSubmitPopup && <PostSubmitPopup {...postSubmitPopupProps} />}
      <Grid>
        <WarningModal
          handleClose={handleCloseCancelModal}
          handleConfirm={handleCancel}
          open={showCancelModal}
          title={cancelModal.title}
          text={cancelModal.text}
        />
      </Grid>
      <WarningModal
        handleClose={handleCloseWarningModal}
        handleConfirm={handleResetJourney}
        open={editModal.showModal}
        title={editModalTitle}
        text={editModalDescription}
        accept={acceptModal}
        decline={declineModal}
      />
      <SecondaryHeader title={title} backLink={links.newAccountInterest.link} />
      <Grid className={styles.page__content}>
        <section>
          <Space gap="5">
            <Heading level={2}>{summaryTitle}</Heading>
          </Space>
          <Space gap="5">
            <Text>{description}</Text>
          </Space>
          <Card variant="white" width="full" className={styles.page__content__card}>
            <Text fontWeight="bold">{selectedAccount}</Text>
            <Space
              gap="8"
              className={`${styles.optionsCard__content} ${styles["optionsCard__content--pink"]} ${styles["optionsCard__content--regularPadding"]}`}>
              <Tag isLarge>
                <Text as="span" fontWeight="bold">
                  {product && product.title}{" "}
                  <span className={`${styles.optionsCard__content__tag}`}>
                    {`${strings.general.issueNumber} ${product && product.issueNumber}`}{" "}
                  </span>
                </Text>
              </Tag>
              <Space gap="0">
                <Text textStyle="xlarge">{`${selectedProductSet?.maxInterestRate.toFixed(2)}% ${aer}`}</Text>
              </Space>
              <Button
                css={{ pt: "0" }}
                variant="tertiary"
                onClick={() => setEditModal({ showModal: true, modalVariant: EditModalVariant.PRODUCT })}>
                {strings.general.editButton}
              </Button>
            </Space>
            <Space gap="5">
              <Heading level={3}>{accountTypeTitle}</Heading>
            </Space>
            <Space gap="8" className={styles.optionsCard__content}>
              <Space gap="0">
                <Text>{accountType === "Single" ? single : joint}</Text>
              </Space>
              {selectedProductSet && productCategorySupportsJointAccounts(selectedProductSet.category) && (
                <Button
                  variant="tertiary"
                  onClick={() => setEditModal({ showModal: true, modalVariant: EditModalVariant.TYPE })}>
                  {strings.general.editButton}
                </Button>
              )}
            </Space>

            {isISA && <ISATransferInfo handleEdit={handleEdit} />}

            {niNumber && (
              <>
                <Space gap="5">
                  <Heading level={3}>{niNumberTitle}</Heading>
                </Space>
                <Space gap="8" className={styles.optionsCard__content}>
                  <Space gap="0">
                    <Text>{niNumber}</Text>
                  </Space>
                  <Button variant="tertiary" onClick={() => handleEdit(links.nationalInsurance.link)}>
                    {strings.general.editButton}
                  </Button>
                </Space>
              </>
            )}

            <Space gap="5">
              <Heading level={3}>{depositTitle}</Heading>
            </Space>
            <Space gap="3">
              <Text css={{ fontSize: "1.25rem" }}>{estimatedDeposit}</Text>
            </Space>
            <Space gap="5" className={styles.optionsCard__content}>
              <Space gap="0">
                <Text>{formatBalance(annualSavings?.annualDeposit || 0)}</Text>
              </Space>
              <Button variant="tertiary" onClick={() => handleEdit(links.annualSavings.link)}>
                {strings.general.editButton}
              </Button>
            </Space>
            <Space gap="3">
              <Text css={{ fontSize: "1.25rem" }}>{depositSource}</Text>
            </Space>
            <Space gap="8" className={styles.optionsCard__content}>
              <Space gap="0">
                <Text>{annualSavings?.sourceOfDeposit}</Text>
              </Space>
              <Button variant="tertiary" onClick={() => handleEdit(links.annualSavings.link)}>
                {strings.general.editButton}
              </Button>
            </Space>
            <Space gap="8">
              <Space gap="5">
                <Heading level={3}>{linkedAccountTitle}</Heading>
              </Space>
              <Box className={styles.optionsCard__content}>
                <Space gap="0">
                  <Text fontWeight="bold">{linkedAccount?.bankName}</Text>
                </Space>
                <Space gap="0">
                  <Text>{linkedAccount?.holderName}</Text>
                </Space>
                <Space gap="0">
                  <Text>{formatSortCode(linkedAccount?.sortCode || "")}</Text>
                </Space>
                <Space gap="0">
                  <Text>{linkedAccount?.accountNumber}</Text>
                </Space>
                <Button
                  variant="tertiary"
                  onClick={() =>
                    handleEdit(
                      typeof linkedAccount?.bankName !== "undefined"
                        ? links.newAccountLinkedAccount.link
                        : links.newAccountLinkedAccountForm.link
                    )
                  }>
                  {strings.general.editButton}
                </Button>
              </Box>
            </Space>
            <Space gap="5">
              <Heading level={3}>{interestPreferencesTitle}</Heading>
            </Space>

            <Space gap="5" className={styles.optionsCard__content}>
              <Space gap="0">
                <Text>{interestPaymentOption === InterestPaymentOption.SAVINGS ? savings : linked}</Text>
              </Space>
              <Button variant="tertiary" onClick={() => handleEdit(links.newAccountInterest.link)}>
                {strings.general.editButton}
              </Button>
            </Space>
            {product && selectedProductSet && (
              <Space gap="0" className={styles.optionsCard__content}>
                <Space gap="0">
                  <Text>
                    {generateInterestFrequencyLabel(
                      interestFrequencyLabel,
                      product.interestFrequency,
                      product.interestRate,
                      product.category,
                      selectedProductSet?.maxInterestRate
                    )}
                  </Text>
                </Space>
                <Button variant="tertiary" onClick={() => handleEdit(links.newAccountInterest.link)}>
                  {strings.general.editButton}
                </Button>
              </Space>
            )}
            <Space gap="3">
              <Divider />
            </Space>
            {product && (
              <Space gap="6">
                <ProductDocuments
                  productName={`${product.title} ${
                    product.issueNumber ? `${strings.general.issueNumber} ${product?.issueNumber}` : ""
                  }`}
                  isISA={isISA}
                  kpi={product.kpiLink}
                />
              </Space>
            )}
            <Card variant="filled" width="auto">
              <Checkbox
                id="newAccount-confirm"
                data-testid="newAccount-confirm"
                onCheckedChange={confirmationCheckboxHandler}
                label={checkBoxContent}
              />
              <Flex className={styles.optionsCard__submissionCard}>
                <Button
                  variant="tertiary"
                  className={styles.optionsCard__submissionCard__cancelButton}
                  onClick={() => setShowCancelModal(true)}>
                  {cancelBtn}
                </Button>
                <Button
                  iconRight
                  size="large"
                  className={styles.optionsCard__submissionCard__submitButton}
                  disabled={!confirmChecked || !validateModel()}
                  variant="primary"
                  onClick={handleSubmit}>
                  {submitBtn}
                  <RightArrow width="24" height="24" />
                </Button>
              </Flex>
            </Card>
          </Card>
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
