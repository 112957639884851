import "@shawbrook/sds-component-library/lib/assets/fonts/fonts.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FC } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { GlobalContextWrapper } from "GlobalContextWrapper";
import "assets/css/App.scss";
import FEATURE_FLAGS from "config/featureFlags";
import { links } from "config/navLinks";
import { BankfastUserMigrationLayout, InboxLayout, Info, Main, MoveMoneyLayout } from "layouts";
import DefaultWrapper from "layouts/DefaultWrapper";
import { ErrorBoundary } from "layouts/ErrorBoundary/ErrorBoundary";
import { MaturityInstructionLayout } from "layouts/MaturityInstruction";
import { NewAccountLayout } from "layouts/NewAccountLayout/NewAccountLayout";
import {
  Account,
  Documents,
  FundingInstructions,
  InboxAccountsPage,
  InboxThreadPage,
  Landing,
  Logout,
  MoveMoney,
  MoveMoneyFrom,
  MoveMoneyReviewSubmit,
  MoveMoneyTo,
  MyProfile,
  NotFound
} from "pages";
import { CloseAccount } from "pages/Account/CloseAccount/CloseAccount";
import { LinkedAccountIssue } from "pages/Account/CloseAccount/LinkedAccountIssue/LinkedAccountIssue";
import {
  Success as BankfastUserMigrationSuccess,
  Wrapper as BankfastUserMigrationWrapper
} from "pages/BankfastUserMigration";
import { BankfastUserMigrationFlow } from "pages/BankfastUserMigration/Steps";
import LoggedOut from "pages/LoggedOut/LoggedOut";
import { ReviewInstruction } from "pages/MaturityInstruction/ReviewInstruction";
import { SelectInterestType } from "pages/MaturityInstruction/SelectInterestType";
import { SelectNewAccount } from "pages/MaturityInstruction/SelectNewAccount";
import { SelectReinvestmentType } from "pages/MaturityInstruction/SelectReinvestmentType";
import { AccountDetails } from "pages/NewAccount/AccountDetails/AccountDetails";
import { AccountType } from "pages/NewAccount/AccountType/AccountType";
import { AnnualSavingsGoal } from "pages/NewAccount/AnnualSavingsGoal/AnnualSavingsGoal";
import { CompareAccounts } from "pages/NewAccount/CompareAccounts/CompareAccounts";
import { FullPartialTransfer } from "pages/NewAccount/FullPartialTransfer/FullPartialTransfer";
import { ISADetails } from "pages/NewAccount/ISADetails/ISADetails";
import { ISATransferIn } from "pages/NewAccount/ISATransfer/ISATransferIn";
import { InterestOptions } from "pages/NewAccount/InterestOptions/InterestOptions";
import { LinkedAccount } from "pages/NewAccount/LinkedAccount/LinkedAccount";
import { LinkedAccountForm } from "pages/NewAccount/LinkedAccountForm/LinkedAccountForm";
import { NationalInsurance } from "pages/NewAccount/NationalInsurance/NationalInsurance";
import { NoticePeriod } from "pages/NewAccount/NoticePeriod/NoticePeriod";
import { SignPost } from "pages/NewAccount/Signpost/Signpost";
import { NewAccountSummary } from "pages/NewAccount/Summary/Summary";
import { TaxYearWaypoint } from "pages/NewAccount/TaxYearWaypoint/TaxYearWaypoint";
import { TransferAmount } from "pages/NewAccount/TransferAmount/TransferAmount";
import { ChooseProfile } from "pages/Profile/ChooseProfile";
import ResetPasswordSuccessful from "pages/ResetPasswordSuccessful";
import { AzureAppInsights, TelemetryProvider, decorateConsoleWithAppInsights } from "services/telemetry";

// Initialize telemetry early
AzureAppInsights.initialize();
decorateConsoleWithAppInsights(console);

const queryClient = new QueryClient();
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <ErrorBoundary>
            <Main />
          </ErrorBoundary>
        }>
        <Route index element={<Landing />} />
        <Route path={links.account.link} element={<Account />} />
        <Route path={links.closeAccount.link} element={<CloseAccount />} />
        <Route path={links.linkedAccountIssue.link} element={<LinkedAccountIssue />} />
        <Route path={links.myProfile.link} element={<MyProfile />} />
        <Route path={links.fundingInstructions.link} element={<FundingInstructions />} />
        <Route path={links.documents.link} element={<Documents />} />
        <Route path={links.signOut.link} element={<Logout />} />
      </Route>

      <Route
        element={
          <ErrorBoundary>
            <Info />
          </ErrorBoundary>
        }>
        <Route path={links.loggedOut.link} element={<LoggedOut />} />
        <Route path={links.resetPasswordCompleted.link} element={<ResetPasswordSuccessful />} />
        <Route path="*" element={<NotFound />} />
        {FEATURE_FLAGS.allowBusinessBanking && <Route path={links.profile.link} element={<ChooseProfile />} />}
      </Route>

      <Route
        path={links.maturityInstruction.link}
        element={
          <ErrorBoundary>
            <MaturityInstructionLayout />
          </ErrorBoundary>
        }>
        <Route path={links.selectAccount.link} element={<SelectNewAccount />} />
        <Route path={links.selectReinvestmentType.link} element={<SelectReinvestmentType />} />
        <Route path={links.chooseInterest.link} element={<SelectInterestType />} />
        <Route path={links.reviewSubmission.link} element={<ReviewInstruction />} />
        <Route path={links.submitInstruction.link} element={<ReviewInstruction isSubmitting />} />
      </Route>

      <Route
        path={links.newAccount.link}
        element={
          <ErrorBoundary>
            <NewAccountLayout />
          </ErrorBoundary>
        }>
        <Route path={links.compareAccounts.link} element={<CompareAccounts />} />
        <Route path={links.newAccountDetails.link} element={<AccountDetails />} />
        <Route path={links.newAccountType.link} element={<AccountType />} />
        <Route path={links.annualSavings.link} element={<AnnualSavingsGoal />} />
        <Route path={links.newAccountLinkedAccount.link} element={<LinkedAccount />} />
        <Route path={links.newAccountLinkedAccountForm.link} element={<LinkedAccountForm />} />
        <Route path={links.newAccountInterest.link} element={<InterestOptions />} />
        <Route path={links.newAccountSummary.link} element={<NewAccountSummary />} />
        <Route path={links.isaTransfer.link} element={<ISATransferIn />} />
        <Route path={links.signpost.link} element={<SignPost />} />
        <Route path={links.nationalInsurance.link} element={<NationalInsurance />} />
        {!FEATURE_FLAGS.hideIsaTransfers && (
          <Route>
            <Route path={links.isaDetails.link} element={<ISADetails />} />
            <Route path={links.taxYearWaypoint.link} element={<TaxYearWaypoint />} />
            <Route path={links.fullPartialTransfer.link} element={<FullPartialTransfer />} />
            <Route path={links.transferAmount.link} element={<TransferAmount />} />
            <Route path={links.notice.link} element={<NoticePeriod />} />
          </Route>
        )}
      </Route>

      <Route
        element={
          <ErrorBoundary>
            <MoveMoneyLayout />
          </ErrorBoundary>
        }>
        <Route path={links.moveMoney.link} element={<MoveMoney />} />
        <Route path={links.moveMoneyTo.link} element={<MoveMoneyTo />} />
        <Route path={links.moveMoneyFrom.link} element={<MoveMoneyFrom />} />
        <Route path={links.moveMoneyReview.link} element={<MoveMoneyReviewSubmit />} />
      </Route>

      <Route
        element={
          <ErrorBoundary>
            <InboxLayout />
          </ErrorBoundary>
        }>
        <Route path={links.inbox.link} element={<InboxAccountsPage />} />
        <Route path={links.inboxNew.link} element={<InboxAccountsPage />} />
        <Route path={links.inboxAccounts.link} element={<InboxThreadPage />} />
        <Route path={links.inboxThread.link} element={<InboxThreadPage />} />
      </Route>

      <Route
        element={
          <ErrorBoundary>
            <BankfastUserMigrationLayout />
          </ErrorBoundary>
        }>
        <Route path={links.bankfastUserMigrationJourneySteps.link} element={<BankfastUserMigrationWrapper />} />
        <Route path={links.bankfastUserMigrationJourneySuccess.link} element={<BankfastUserMigrationSuccess />} />
      </Route>

      <Route
        element={
          <ErrorBoundary>
            <BankfastUserMigrationLayout />
          </ErrorBoundary>
        }>
        <Route
          path={links.bankfastUserMigrationMobileJourneySteps.link}
          element={<BankfastUserMigrationWrapper flowId={BankfastUserMigrationFlow.MOBILE} />}
        />
        <Route path={links.bankfastUserMigrationJourneySuccess.link} element={<BankfastUserMigrationSuccess />} />
      </Route>
    </>
  )
);

export const App: FC = () => (
  <GlobalContextWrapper>
    <TelemetryProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ErrorBoundary>
          <DefaultWrapper>
            <RouterProvider router={router} />
          </DefaultWrapper>
        </ErrorBoundary>
      </QueryClientProvider>
    </TelemetryProvider>
  </GlobalContextWrapper>
);

export default App;
