import { useEffect } from "react";
import { IUseScrollToTop } from "hooks/useScrollToTop/interfaces";

const useScrollToTop = ({ condition = true, scrollRestoration = "manual" }: IUseScrollToTop = {}) => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = scrollRestoration;
    }
  }, [scrollRestoration]);

  useEffect(() => {
    if (condition) {
      try {
        window.scroll({ top: -50, left: 0 });
      } catch (e) {
        window.scrollTo(-50, 0);
      }
    }
  }, [condition]);
};

export default useScrollToTop;
