import { useQuery } from "@tanstack/react-query";
import { UseAvailableProductsProps } from "query/useMaturityInstructionQuery/useAvailableProducts/interfaces";
import { getAvailableProducts } from "services/data/api";
import { QueryKeys } from "services/data/types";

const useAvailableProducts = ({ accountId, enabled = true }: UseAvailableProductsProps) => {
  const {
    data: availableProductsData,
    isLoading: isAvailableProductsLoading,
    isError: isAvailableProductsError
  } = useQuery({
    queryKey: [QueryKeys.AVAILABLE_PRODUCTS, accountId],
    queryFn: () => getAvailableProducts(accountId),
    enabled
  });

  return { availableProductsData, isAvailableProductsLoading, isAvailableProductsError };
};

export default useAvailableProducts;
