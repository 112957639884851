import {
  Divider,
  Flex,
  Heading,
  Space,
  Tabs,
  TabsResponsive,
  TabsResponsiveItem,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { FormattedDate } from "components/FormattedDate";
import { Aside } from "components/MaturityInstruction/Aside";
import { AvailableAccountsCard } from "components/MaturityInstruction/AvailableAccountsCard/AvailableAccountsCard";
import { PageLayout } from "components/Page";
import { TermsExplained } from "components/TermsExplained/TermsExplained";
import { TogglableAlert } from "components/TogglableAlert";
import { CATEGORIES_ORDER } from "config/categoryOrder";
import { DEFAULT_MATURING_ACCOUNT_INTEREST_RATE } from "constants/reinvestments";
import { useMaturityInstructionContext } from "context/MaturityInstructionContext";
import useAccount from "hooks/useAccount";
import useAvailableProducts from "query/useMaturityInstructionQuery/useAvailableProducts";
import { ProductCategory } from "services/data/types";
import { isISAAccountCategory } from "utils";
import { sortAndCategoriseProducts } from "utils/formatAvailableProducts/sortAndCategoriseProducts";
import { getMaturityInstructionAccountCategoryStrings } from "utils/getMaturityInstructionAccountCategoryStrings";
import { stringReplace } from "utils/replace";
import styles from "./SelectNewAccount.module.scss";

export const SelectNewAccount = () => {
  const { accountId } = useMaturityInstructionContext();
  const { accountDetailData: maturingAccountData, isAccountDetailLoading } = useAccount({ accountId });
  const { availableProductsData, isAvailableProductsLoading } = useAvailableProducts({
    accountId
  });

  const { category: maturingAccountCategory } = maturingAccountData?.product || {};
  const { maturityDate, executionDate } = maturingAccountData?.maturity || {};

  const maturingAccountIsISA = maturingAccountCategory ? isISAAccountCategory(maturingAccountCategory) : undefined;
  const sortedAvailableProducts = sortAndCategoriseProducts(availableProductsData || [], maturingAccountIsISA);

  const loading = isAccountDetailLoading || isAvailableProductsLoading;

  const {
    subtitle,
    fixedRateBondAccName,
    cashISAAccName,
    description,
    whatHappensIfIDoNothing,
    selectAccount,
    accountsTabs,
    disclaimer
  } = strings.selectAccount;
  const { title: pageTitle } = strings.reinvestment;

  const { allAccounts } = accountsTabs;

  const subtitleWithAccountName = stringReplace(subtitle, {
    "{{ACC_NAME}}": maturingAccountCategory === ProductCategory.FIXED_RATE ? fixedRateBondAccName : cashISAAccName
  });

  const descriptionWithMaturityDate = stringReplace(description, {
    "{{DATE}}": <FormattedDate dateToFormat={maturityDate} />
  });

  const whatHappensIfIDoNothingBody = stringReplace(whatHappensIfIDoNothing.body, {
    "\n": <br />,
    "{{DATE}}": <FormattedDate dateToFormat={executionDate} />,
    "{{INTEREST_RATE}}": DEFAULT_MATURING_ACCOUNT_INTEREST_RATE.toFixed(2)
  });

  const productTabs = Object.keys(sortedAvailableProducts).sort(
    (a, b) => CATEGORIES_ORDER[b] - CATEGORIES_ORDER[a]
  ) as ProductCategory[];

  const allAccountsTabItem: TabsResponsiveItem = {
    title: allAccounts,
    value: "all-accounts",
    content: (
      <Flex direction="column" gap={4} className={styles.selectNewAccount__allAccountsHolder}>
        {productTabs.map(category => {
          const { title, tags } = getMaturityInstructionAccountCategoryStrings(category);
          return (
            <AvailableAccountsCard
              title={title}
              tags={tags}
              accounts={sortedAvailableProducts[category]}
              key={category}
            />
          );
        })}
      </Flex>
    )
  };

  const productTabsItems: TabsResponsiveItem[] = productTabs.map(category => {
    const { title, tags } = getMaturityInstructionAccountCategoryStrings(category);

    return {
      title,
      value: category,
      content: (
        <AvailableAccountsCard title={title} tags={tags} accounts={sortedAvailableProducts[category]} defaultShowAll />
      )
    };
  });

  return (
    <PageLayout title={pageTitle} loading={loading}>
      <PageLayout.Content>
        <Heading level={2}>{subtitleWithAccountName}</Heading>
        <Space gap={3} />
        <Text>{descriptionWithMaturityDate}</Text>
        <TogglableAlert>
          <TogglableAlert.Trigger variant="tertiary">{whatHappensIfIDoNothing.title}</TogglableAlert.Trigger>
          <TogglableAlert.Content showCloseIcon>
            <Text>{whatHappensIfIDoNothingBody}</Text>
          </TogglableAlert.Content>
        </TogglableAlert>
        <Divider />
        <Heading level={3}>{selectAccount}</Heading>
        <Space />
        <Tabs value="">
          <TabsResponsive
            defaultValue="all-accounts"
            items={[allAccountsTabItem, ...productTabsItems]}
            selectId="category-select"
            tabStyleTrigger="widthAligned"
            tabStyleContent="widthAligned"
            backgroundColorContent="transparent"
            backgroundColorTrigger="transparent"
          />
        </Tabs>
        <Space gap={7}>
          <Text color="grey">{disclaimer}</Text>
        </Space>

        <TermsExplained />
      </PageLayout.Content>
      <PageLayout.Aside>
        <Aside showMaturingAccount="detailed" maturingAccountId={accountId} showWithdrawFullBalance />
      </PageLayout.Aside>
    </PageLayout>
  );
};
